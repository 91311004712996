import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { useDispatch } from "react-redux";

import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import Error from "../../components/Error";

import { DocumentTextIcon, TrashIcon } from "@heroicons/react/outline";

import { isEmpty } from "../../lib/isEmpty";
import useFile from "../../hooks/useFile";
import { getBlob } from "../../actions/fileActions";
import { deleteAngebot } from "../../actions/adminActions";

export default function Angebote(props) {
  const { data, error, mutate } = useSWR("/api/contracts/admin", fetcher);
  const dispatch = useDispatch();
  const deleteClick = (id) => {
    dispatch(deleteAngebot(id, props.history));
    mutate();
  };

  const columns = [
    {
      Header: "Angebot",
      accessor: "Angebotsnummer",
    },
    {
      Header: "Art",
      accessor: "Vertragsart",
    },
    { Header: "Kreditornummer", accessor: "Kreditornummer" },
    { Header: "Pflanzer", accessor: "grower.name" },
    {
      Header: "Datum",
      accessor: "Angebotsdatum",
      Cell: (props) => props.value,
    },
    {
      Header: "Sorte",
      accessor: "hop.Beschreibung",
      Cell: (props) => props.value,
    },
    {
      Header: "Start",
      accessor: "Erstes Lieferjahr",
    },
    {
      Header: "Ende",
      accessor: "Letztes Lieferjahr",
      Cell: (props) => (props.value === 0 ? "-" : props.value),
    },
    {
      Header: "Status",
      accessor: "Status",
      Cell: (props) =>
        props.value === 2 ? (
          <span className="text-primary">Angenommen</span>
        ) : props.value === 1 ? (
          <span className="red">Abgelehnt</span>
        ) : (
          "Ausstehend"
        ),
    },
    {
      Header: "Vertrag",
      accessor: "EK Vertragsnummer",
      Cell: (props) => (isEmpty(props.value) ? "-" : props.value),
    },
    {
      accessor: "0",
      Cell: (props) =>
        !isEmpty(props.row.original["EK Vertragsnummer"]) && (
          <button
            className="btn-icon-small"
            data-tooltip="Vertrag ansehen"
            onClick={() =>
              dispatch(
                getBlob("contract", props.row.original["EK Vertragsnummer"])
              )
            }
          >
            <DocumentTextIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          </button>
        ),
    },

    {
      accessor: "1",
      Cell: (props) =>
        useFile(
          `/api/files/angebot/${props.row.original.Angebotsnummer}`,
          "Angebot anzeigen"
        ),
    },
    {
      accessor: "2",
      Cell: (props) => (
        <Modal
          icon={
            <TrashIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          }
          modalAction={() => deleteClick(props.row.original.Angebotsnummer)}
          modalText="Angebot wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <AdminLayout>
      <Table
        title="Angebote"
        data={data.data}
        columns={columns}
        search={true}
        csv={true}
      />
    </AdminLayout>
  );
}
