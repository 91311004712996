import useSWR from "swr";

import fetcher from "../lib/fetcher";
import queryString from "query-string";

import { InformationCircleIcon } from "@heroicons/react/outline";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import BarChart from "../components/Charts/BarChart";
import ChartMenu from "../components/Charts/ChartMenu";

import { getQualityValues } from "../lib/getQualityValues";
import { convertNumber } from "../lib/convertNumber";

import useHop from "../hooks/useHop";

export default function QualityChart(props) {
  const params = queryString.parse(props.location.search);

  const { data, error } = useSWR(
    `/api/quality/${params.type}/${params.sorte}`,
    fetcher
  );
  const quality = data;

  const values = getQualityValues(params.wert);
  const hop = useHop(params.sorte);

  const columns = [
    {
      Header: "Sorte",
      accessor: "hop.Beschreibung",
    },

    {
      Header: "Jahr",
      accessor: "year",
    },
    {
      Header: "Menge kg",
      accessor: "summe",
    },
    { Header: "Partien", accessor: "partien" },
    {
      Header: values.label1,
      accessor: values.value1,
    },
    {
      Header: values.label2,
      accessor: values.value2,
    },
    {
      Header: "Datengrundlage",
      accessor: values.case,
    },
    {
      accessor: "0",
      Cell: (props) => (
        <a
          data-tooltip="Details"
          href={
            props.cell.row.original.variety
              ? `/qualität/details?wert=${values.value1}&sorte=${props.cell.row.original.variety}&jahr=${props.row.original.year}`
              : `/qualität/details?wert=${values.value1}&sorte=${params.sorte}&jahr=${props.row.original.year}`
          }
        >
          <InformationCircleIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </a>
      ),
    },
  ];

  function formatDataForChart(values) {
    const series1 = [];
    const series2 = [];

    for (var i = 0; i < quality.length; i++) {
      series1.push(convertNumber(quality[i][values.value1]));
      series2.push(convertNumber(quality[i][values.value2]));
    }

    return {
      labels: quality.map((result) => result.year),
      datasets: [
        {
          label: values.label1,
          data: series1,
          backgroundColor: "#84bd00",
        },
        {
          label: values.label2,
          data: series2,
          backgroundColor: "#608a00",
        },
      ],
    };
  }

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout>
      <h1 className="font-display uppercase text-3xl ">
        Sorte: {hop && hop.Beschreibung}{" "}
      </h1>
      <h2 className="font-display uppercase text-2xl text-primary">
        {values.headline}
      </h2>
      <br />
      <ChartMenu sorte={params.sorte} wert={params.wert} />
      <div className="chart-table">
        <Table columns={columns} data={data} csv={true} pdf={true} />
      </div>
      <BarChart data={formatDataForChart(values)} />
    </Layout>
  );
}
