import axios from "axios";
import setAuthToken from "../lib/setAuthToken";
import jwt_decode from "jwt-decode";

export const getRegisterToken = (userData, history) => (dispatch) => {
  axios
    .post("/api/auth/signup", userData)
    .then((res) => history.push("/login"))
    .catch((res) =>
      dispatch({
        type: "GET_ERRORS",
        payload: res.data,
      })
    );
};

// Reset Password, redirect
export const registerUser = (token, userData, history) => (dispatch) => {
  axios
    .post(`/api/auth/register/${token}`, userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/auth/login", userData)
    .then((res) => {
      // Save to local storage
      const { token } = res.data;
      // Set token to local storage
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Reset auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(clearCurrentUser());
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: "SET_CURRENT_USER",
    payload: decoded,
  };
};

// Get current profile
export const getCurrentUser = () => (dispatch) => {
  dispatch(setUserLoading());
  axios
    .get("/api/auth")
    .then((res) =>
      dispatch({
        type: "GET_USER",
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: "GET_USER",
        payload: {},
      })
    );
};

// Clear Profile
export const clearCurrentUser = () => {
  return {
    type: "CLEAR_CURRENT_USER",
  };
};

// Profile loading
export const setUserLoading = () => {
  return {
    type: "LOADING",
  };
};

// Profile loading
export const clearErrors = () => {
  return {
    type: "CLEAR_ERRORS",
  };
};

// Forgot Password, send mail
export const forgotPassword = (userData, history) => (dispatch) => {
  axios
    .post("/api/auth/forgot", userData)
    .then((res) => history.push("/login"))
    .catch((res) =>
      dispatch({
        type: "GET_ERRORS",
        payload: res.data,
      })
    );
};

// Validate Token for Password Reset
export const checkToken = (route, token, history) => (dispatch) => {
  axios
    .get(`/api/auth/${route}/${token}`)
    .then((res) =>
      dispatch({
        type: "CHECK_TOKEN",
        key: token,
        payload: res.data,
      })
    )
    .catch((err) => history.push("/login"));
};

// Reset Password, redirect
export const resetPassword = (token, userData, history) => (dispatch) => {
  axios
    .post(`/api/auth/reset/${token}`, userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

// Reset Password, redirect
export const newPassword = (token, userData, history) => (dispatch) => {
  axios
    .post(`/api/auth/new-password/${token}`, userData)
    .then(
      (res) =>
        dispatch({ type: "CHANGE_FORCE_LOGIN", payload: res.data }) &&
        history.push("/dashboard")
    )
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};
