import axios from "axios";
import {getMimeTypeOfFile} from "../lib/getMimeTypeOfFile";

export const getDocument = (filename, key) => async() => {
  await axios({
    url: `/api/documents/file/${filename}?id=${key}`,
    method: "GET",
    responseType: "arraybuffer",
  }).then(async(res) => {
    const file = new Blob([res.data], { type: res.headers["content-type"] });
    const mimeType = await getMimeTypeOfFile(file);

    // JPG FILES
    if (mimeType === "image/jpeg") {
      file.name = `${filename}`;
      const windowReference = window.open();
      const fileReader = new FileReader();

      fileReader.fileName = filename;
      fileReader.readAsDataURL(file);

      fileReader.onload = (evt) => {
        windowReference.document.write('<iframe src="' + fileReader.result + '" style="width:100%; height:100%;" allowfullscreen></iframe>');
      };
    } else {
      const fileURL = URL.createObjectURL(file);

      // TIFF FILES
      if (mimeType === "image/tiff") {
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        // PDF FILES
        window.open(fileURL);
      }
    }
  })
};