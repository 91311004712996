export default function Zusatzvereinbarungen({ angebot, title }) {
  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      </div>
      <div className="border-t border-primary px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dd className="mt-1 text-sm text-gray-900">
              <ul className="px-6 list-disc">
                {angebot["Zusatzvereinbarung Zeile 1"] && (
                  <li>{angebot["Zusatzvereinbarung Zeile 1"]}</li>
                )}
                {angebot["Zusatzvereinbarung Zeile 2"] && (
                  <li>{angebot["Zusatzvereinbarung Zeile 2"]}</li>
                )}{" "}
                {angebot["Zusatzvereinbarung Zeile 3"] && (
                  <li>{angebot["Zusatzvereinbarung Zeile 3"]}</li>
                )}
                {!angebot["Zusatzvereinbarung Zeile 1"] &&
                  !angebot["Zusatzvereinbarung Zeile 2"] &&
                  !angebot["Zusatzvereinbarung Zeile 3"] &&
                  "Keine Zusatzvereinbarungen"}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
