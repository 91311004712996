import { useState } from "react";

import Authbar from "./Authbar";
import Sidebar from "./Sidebar";

import useAuth from "../../hooks/useAuth";
import { adminNavigation, salesNavigation } from "../../utils/navigation";
import { isAdmin } from "../../lib/isAdmin";

function AdminLayout({ title, children }) {
  const auth = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Authbar navigation={adminNavigation} />
      <div className="py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-full lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden sm:hidden lg:block lg:col-span-3 xl:col-span-2">
            <Sidebar
              navigation={
                isAdmin(auth.user.role) ? adminNavigation : salesNavigation
              }
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </div>
          <main className="lg:col-span-12 xl:col-span-9 m-2">
            <h1 className="sr-only">{title}</h1>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
