import { useSelector } from "react-redux";

// Authorization HOC
export const Authorization = (allowedRoles) => (WrappedComponent) => {
  return function WithAuthorization(props) {
    const { role } = useSelector((state) => state.auth.user);

    if (allowedRoles.includes(role)) {
      return <WrappedComponent {...props} />;
    } else {
      return (
        <section id="not-found">
          <div className="not__found container center">
            <h1 className="white">401 - Nicht autorisiert.</h1>
            <p className="white lead">
              Zurück zur{" "}
              <a className="white" href="/">
                Startseite
              </a>
              ?
            </p>
          </div>
        </section>
      );
    }
  };
};
