export default function Kaufpreisberechnung({ angebot, type, title }) {
  const raw = type === " kg Rohhopfen" ? true : false;
  const alphaStart = angebot["Alpha von"];
  const quality = angebot["Qualität"];

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      </div>
      <div className="border-t border-primary px-8 py-5 sm:px-6">
        <ol className="ml-8 list-decimal">
          {raw && (
            <>
              <li>
                Der Grundpreis gilt ohne Zu- und Abschläge für den
                Neutralbereich der Alphatabelle und / oder der Qualitätstabelle.
                Bei einer Über- oder Unterschreitung dieser Bereiche wird der in
                der / den jeweils maßgeblichen Tabelle(n) genannte Zu- bzw.
                Abschlag zusätzlich als Bestandteil des zu bildenden
                Gesamtkaufpreises vereinbart.
              </li>
              <li>
                Für die Ermittlung des maßgeblichen Gesamtkaufpreises
                (Grundpreis inklusive Zu- und Abschläge) wird
              </li>
            </>
          )}
          {raw && alphaStart && quality === 0 ? (
            <div className="relative p-2 cursor-pointer  focus:outline-none">
              <label>
                <input
                  className="text-primary"
                  name="alphapreis"
                  type="radio"
                  checked
                  readOnly
                />
                <span className="ml-4">
                  die Alphatabelle für Freihopfen mit den dortigen Zu- und
                  Abschlägen sowie zusätzlich die Qualitätstabelle für
                  deutschen Siegelhopfen ohne Geltung von Zu- und Abschlägen zu
                  Grunde gelegt.
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
          {raw && !alphaStart && quality === 2 ? (
            <div className="relative p-2 cursor-pointer  focus:outline-none">
              <label>
                <input
                  className="text-primary"
                  name="alphapreis"
                  type="radio"
                  checked
                  readOnly
                />
                <span className="ml-4">
                  ausschließlich die Qualitätstabelle für deutschen Siegelhopfen
                  (AVHLV, Anhang III) zu Grunde gelegt.
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
          {raw && alphaStart && quality === 1 ? (
            <div className="relative p-2 cursor-pointer  focus:outline-none">
              <label>
                <input
                  className="text-primary"
                  name="alphapreis"
                  type="radio"
                  checked
                  readOnly
                />
                <span className="ml-4">
                  die <b>Alphatabelle</b> für Freihopfen mit den dortigen Zu-
                  und Abschlägen sowie <b>zusätzlich die Qualitätstabelle</b>{" "}
                  für deutschen Siegelhopfen <b>mit</b> Geltung der dort
                  festgelegten Zu- und Abschläge in Höhe von jeweils 50% zu
                  Grunde gelegt.
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
          {raw && alphaStart && quality === 2 ? (
            <div className="relative p-2 cursor-pointer  focus:outline-none">
              <label>
                <input
                  className="text-primary"
                  name="alphapreis"
                  type="radio"
                  checked
                  readOnly
                />
                <span className="ml-4">
                  die <b>Alphatabelle</b> für Freihopfen mit den dortigen Zu-
                  und Abschlägen sowie <b>zusätzlich die Qualitätstabelle</b>{" "}
                  für deutschen Siegelhopfen <b>mit</b> Geltung der dort
                  festgelegten Zu- und Abschläge in Höhe von jeweils 100% zu
                  Grunde gelegt.
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
          {raw && !alphaStart && quality === 1 ? (
            <div className="relative p-2 cursor-pointer focus:outline-none">
              <label>
                <input
                  className="text-primary"
                  name="alphapreis"
                  type="radio"
                  checked
                  readOnly
                />
                <span className="ml-4">
                  die Qualitätstabelle mit Geltung der dort festgelegten Zu- und
                  Abschläge in Höhe von jeweils 50% zu Grunde gelegt.
                </span>
              </label>
            </div>
          ) : (
            ""
          )}

          {raw && !alphaStart && quality === 0 ? (
            <div className="relative p-2 cursor-pointer focus:outline-none">
              <label>
                <input
                  className="text-primary"
                  name="alphapreis"
                  type="radio"
                  checked
                  readOnly
                />
                <span className="ml-4">
                  die Qualitätstabelle ohne Geltung von Zu- und Abschlägen zu
                  Grunde gelegt.
                </span>
              </label>
            </div>
          ) : (
            ""
          )}
          {raw && (
            <>
              <li>
                Die Ermittlung des Alphawertes erfolgt zum Zeitpunkt der
                Übernahme nach der Methode Analytica EBC 7.4 durch das
                Untersuchungslabor HHV Au, Mainburgerstr. 26, 84072
                Au/Hallertau. Sofern eine Alphasäure Untersuchung im Rahmen der
                „Verbandsvereinbarung zum Hopfengeschäftsverkehr Deutscher
                Siegelhopfen“ in Auftrag gegeben wurde, dann wird
                der Alphawert nach der in der Vereinbarung beschriebenen
                Vorgehensweise ermittelt und zugrunde gelegt.
              </li>
              <li>
                Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
                dem Käufer übergebenen, ordnungsgemäß und qualitätsschonend
                getrockneten und in Rechteckballen (RB 60) verpackten Rohhopfen
                je Kilogramm zzgl. der zum Zeitpunkt der Leistungserbringung
                geltenden gesetzlichen Mehrwertsteuer.
              </li>
            </>
          )}
        </ol>
        {!raw ? (
          <ol className="ml-8 list-decimal">
            <li>
              Die Musterziehung und Alphafeststellung, sowie eine eventuelle
              Nachuntersuchung erfolgt nach den Bestimmungen der AVHLV Ziffer V.
              4. und 5.
            </li>
            <li>
              Die Ermittlung des Alphawertes erfolgt zum Zeitpunkt der Übernahme
              nach der Methode Analytica EBC 7.4 durch das Untersuchungslabor
              HHV Au, Mainburgerstr. 26, 84072 Au/Hallertau. Sofern eine
              Alphasäure Untersuchung im Rahmen der „Verbandsvereinbarung zum
              Hopfengeschäftsverkehr Deutscher Siegelhopfen“ in
              Auftrag gegeben wurde, dann wird der Alphawert nach der in der
              Vereinbarung beschriebenen Vorgehensweise ermittelt und zugrunde
              gelegt.
            </li>
            <li>
              Der Preis gilt für dem Käufer übergebenen, ordnungsgemäß und
              qualitätsschonend getrockneten und in Rechteckballen (RB 60)
              verpackten Rohhopfen je Kilogramm Alpha (EBC 7.4) zum Zeitpunkt
              der Übernahme zzgl. der zum Zeitpunkt der Leistungserbringung
              geltenden gesetzlichen Mehrwertsteuer.
            </li>
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird <b>ohne</b> Geltung der dort festgelegten Zu- und
              Abschläge zu Grunde gelegt.
            </li>
          </ol>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
