import { getHopType } from "./getHopType";

export function filterAngebote(angebote, year, hop, type, vertrag) {
  const filter = year || hop || type || vertrag;

  return filter
    ? angebote
        .filter((item) => (year ? item.Startjahr === year.value : item))
        .filter((item) => (hop ? item.hop.Beschreibung === hop : item))
        .filter((item) => (type ? getHopType(item) === type : item))
        .filter((item) => (vertrag ? item.Vertragsart === vertrag.value : item))
    : angebote;
}
