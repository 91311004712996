import { classNames } from "../../lib/classNames";

export default function ChartMenu({ sorte, wert }) {
  const tabs = [
    {
      name: "Alpha",
      href: `/qualität/chart?wert=alpha&sorte=${sorte}`,
      current: wert === "alpha" ? true : false,
    },
    {
      name: "Wasser",
      href: `/qualität/chart?wert=wasser&sorte=${sorte}`,
      current: wert === "wasser" ? true : false,
    },
    {
      name: "Stengel",
      href: `/qualität/chart?wert=stengel&sorte=${sorte}`,
      current: wert === "stengel" ? true : false,
    },
    {
      name: "Abfall",
      href: `/qualität/chart/?wert=abfall&sorte=${sorte}`,
      current: wert === "abfall" ? true : false,
    },
    {
      name: "Doldenblätter",
      href: `/qualität/chart?wert=dolden&sorte=${sorte}`,
      current: wert === "dolden" ? true : false,
    },

    {
      name: "Bonität",
      href: `/qualität/chart?wert=bonität&sorte=${sorte}`,
      current: wert === "bonität" ? true : false,
    },

    {
      name: "Saldo",
      href: `/qualität/chart?wert=saldo&sorte=${sorte}`,
      current: wert === "saldo" ? true : false,
    },
  ];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? "text-primary"
                  : "text-gray-600 hover:text-gray-800",
                "px-3 py-2 font-medium text-sm"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
