import { formatArea } from "../lib/formatArea";

const initalState = {};

export default function growerReducer(state = initalState, action) {
  switch (action.type) {
    case "CHANGE_AREA":
      return {
        growerArea: formatArea(action.payload.data.anbaugebiet),
        location: action.payload.data.zipCode + " " + action.payload.data.city,
      };
    case "CHANGE_FORCE_LOGIN":
      return {
        ...state,
        forceLogin: 0,
      };

    case "CLEAR_CURRENT_USER":
      return "";

    default:
      return state;
  }
}
