import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";

import MittelwerteForm from "../../components/Forms/MittelwerteForm";
import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import Error from "../../components/Error";

import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { date } from "../../lib/date";
import { deleteAlpha } from "../../actions/adminActions";

export default function Mittelwerte(props) {
  const { data, error, mutate } = useSWR("/api/alphas", fetcher);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const deleteClick = (id) => {
    dispatch(deleteAlpha(id, props.history));
    mutate();
  };

  const columns = [
    {
      Header: "Erntejahr",
      accessor: "cropYear",
    },
    {
      Header: "Sorte",
      accessor: "hop.Beschreibung",
    },
    {
      Header: "Anbaugebiet",
      accessor: "area.Beschreibung",
    },
    {
      Header: "Alpha (%)",
      accessor: "alphaContent",
    },
    {
      Header: "Wasser (%)",
      accessor: "humidity",
    },
    {
      Header: "Blatt/Stengel (%)",
      accessor: "leafStemPercent",
    },
    {
      Header: "Abfall (%)",
      accessor: "wastePercent",
    },
    {
      Header: "Doldenblätter (%)",
      accessor: "percentLeaves",
    },
    {
      Header: "Saldo",
      accessor: "balance",
    },
    {
      Header: "Bonität",
      accessor: "rating",
    },
    {
      Header: "Letzte Änderung",
      accessor: "latestChangeDateTime",
      Cell: (props) => date(props.value),
    },
    {
      accessor: "averageAlphaId",
      Cell: (props) => (
        <Link to={`/admin/mittelwerte/${props.value}`}>
          <PencilIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </Link>
      ),
    },
    {
      accessor: "delete",
      Cell: (props) => (
        <Modal
          icon={
            <TrashIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          }
          modalAction={() =>
            deleteClick(props.cell.row.original.averageAlphaId)
          }
          modalText="Mittelwert wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout>
      {edit ? (
        <MittelwerteForm defaultValues={""} edit={edit} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Neuer Mittelwert"
          search={true}
          title="Mittelwerte"
          data={data.data}
          columns={columns}
        />
      )}
    </AdminLayout>
  );
}
