import React from "react";

export default function Kaufpreisberechnung({ angebot, raw, alpha, data }) {
  const alphaStart = angebot["Alpha von"];
  const alphaEnde = angebot["Alpha bis"];
  const quality = angebot["Qualität"];

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {raw
            ? "IV. Kaufpreisberechnung"
            : "IV. Ermittlung des Alphawertes und Kaufpreisberechnung"}
        </h3>
      </div>
      <div className="border-t border-primary px-8 py-5 sm:px-6">
        {/* ROHHOPFEN // Variante 1 // Alphatabelle ja // ja, volle Quali */}
        {raw && alphaStart && quality === 2 ? (
          <ul className="ml-8 list-disc">
            <li>
              Der Grundpreis gilt ohne Zu- und Abschläge für einen
              Alphasäurengehalt von {alphaStart} % bis {alphaEnde} %
              (Neutralbereich).
            </li>
            {data.alphapreis === "false" ? (
              <label className="ml-4 mt-4 mb-4">
                Für jedes über dem Neutralbereich liegende Zehntel Alpha wird
                ein Alphazuschlag von je 0,03 €/kg, max. 0,30 €/kg bezahlt. Für
                jedes unter dem Neutralbereich liegende Zehntel Alpha wird ein
                Alphaabschlag von je 0,03 €/kg, max. 0,30 €/kg abgezogen.
              </label>
            ) : (
              <label className="ml-4 mt-4 mb-4">
                Über dem Neutralbereich wird ein Zuschlag von 10 % vom
                Grundpreis, max. 0,30 €/kg bezahlt. Unter dem Neutralbereich
                werden 10 % vom Grundpreis, max. 0,30 €/kg abgezogen.
              </label>
            )}
            <li>
              Die Ermittlung des Alphawertes erfolgt nach der Methode Analytica
              EBC 7.4 im Untersuchungslabor HHV Au, Mainburgerstr. 26, 84072
              Au/Hallertau
            </li>
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird
            </li>
            <label className="ml-4 mt-4 mb-4">
              mit Geltung der dort festgelegten Zu- und Abschläge zu Grunde
              gelegt.
            </label>
            <li>
              Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
              den dem Käufer zum Zeitpunkt des Gefahrübergangs übergebenen,
              ordnungsgemäß und qualitätsschonend getrockneten und verpackten
              Rohhopfen je Kilogramm zzgl. der zum Zeitpunkt der
              Leistungserbringung geltenden gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
        {/*  Variante 2 // Alphatabelle ja // ja, 50% Quali */}
        {alphaStart && quality === 1 ? (
          <ul className="ml-8 list-disc">
            <li>
              Der Grundpreis gilt ohne Zu- und Abschläge für einen
              Alphasäurengehalt von {alphaStart} % bis {alphaEnde} %
              (Neutralbereich).
            </li>
            {data.alphapreis === "false" ? (
              <label className="ml-4 mt-4 mb-4">
                Für jedes über dem Neutralbereich liegende Zehntel Alpha wird
                ein Alphazuschlag von je 0,03 €/kg, max. 0,30 €/kg bezahlt. Für
                jedes unter dem Neutralbereich liegende Zehntel Alpha wird ein
                Alphaabschlag von je 0,03 €/kg, max. 0,30 €/kg abgezogen.
              </label>
            ) : (
              <label className="ml-4 mt-4 mb-4">
                Über dem Neutralbereich wird ein Zuschlag von 10 % vom
                Grundpreis, max. 0,30 €/kg bezahlt. Unter dem Neutralbereich
                werden 10 % vom Grundpreis, max. 0,30 €/kg abgezogen.
              </label>
            )}
            <li>
              Die Ermittlung des Alphawertes erfolgt nach der Methode Analytica
              EBC 7.4 im Untersuchungslabor HHV Au, Mainburgerstr. 26, 84072
              Au/Hallertau
            </li>
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird
            </li>
            <label className="ml-4 mt-4 mb-4">
              mit Geltung der dort festgelegten Zu- und Abschläge in Höhe von
              jeweils 50% zu Grunde gelegt.
            </label>
            <li>
              Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
              den dem Käufer zum Zeitpunkt des Gefahrübergangs übergebenen,
              ordnungsgemäß und qualitätsschonend getrockneten und verpackten
              Rohhopfen je Kilogramm zzgl. der zum Zeitpunkt der
              Leistungserbringung geltenden gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
        {/* // Variante 3 // Alphatabelle ja // ohne Quali */}
        {alphaStart && quality === 0 ? (
          <ul className="ml-8 list-disc">
            <li>
              Der Grundpreis gilt ohne Zu- und Abschläge für einen
              Alphasäurengehalt von {alphaStart} % bis {alphaEnde} %
              (Neutralbereich).
            </li>

            {data.alphapreis === "false" ? (
              <label className="ml-4 mt-4 mb-4">
                Für jedes über dem Neutralbereich liegende Zehntel Alpha wird
                ein Alphazuschlag von je 0,03 €/kg, max. 0,30 €/kg bezahlt. Für
                jedes unter dem Neutralbereich liegende Zehntel Alpha wird ein
                Alphaabschlag von je 0,03 €/kg, max. 0,30 €/kg abgezogen.
              </label>
            ) : (
              <label className="ml-4 mt-4 mb-4">
                Über dem Neutralbereich wird ein Zuschlag von 10 % vom
                Grundpreis, max. 0,30 €/kg bezahlt. Unter dem Neutralbereich
                werden 10 % vom Grundpreis, max. 0,30 €/kg abgezogen.
              </label>
            )}
            <li>
              Die Ermittlung des Alphawertes erfolgt nach der Methode Analytica
              EBC 7.4 im Untersuchungslabor HHV Au, Mainburgerstr. 26, 84072
              Au/Hallertau
            </li>
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird
            </li>
            <label className="ml-4 mt-4 mb-4">
              ohne Geltung von Zu- und Abschlägen zu Grunde gelegt.
            </label>
            <li>
              Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
              den dem Käufer zum Zeitpunkt des Gefahrübergangs übergebenen,
              ordnungsgemäß und qualitätsschonend getrockneten und verpackten
              Rohhopfen je Kilogramm zzgl. der zum Zeitpunkt der
              Leistungserbringung geltenden gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
        {/* Variante 4 // Alphatabelle nein // ja volle Quali */}
        {!alphaStart && quality === 2 ? (
          <ul className="ml-8 list-disc">
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird
            </li>
            <label className="ml-4 mt-4 mb-4">
              mit Geltung der dort festgelegten Zu- und Abschläge zu Grunde
              gelegt.
            </label>
            <li>
              Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
              den dem Käufer zum Zeitpunkt des Gefahrübergangs übergebenen,
              ordnungsgemäß und qualitätsschonend getrockneten und verpackten
              Rohhopfen je Kilogramm zzgl. der zum Zeitpunkt der
              Leistungserbringung geltenden gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
        {/* // Variante 5 // Alphatabelle nein // ja 50% Quali */}
        {!alphaStart && quality === 1 ? (
          <ul className="ml-8 list-disc">
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird
            </li>
            <label className="ml-4 mt-4 mb-4">
              mit Geltung der dort festgelegten Zu- und Abschläge in Höhe von
              jeweils 50% zu Grunde gelegt.
            </label>
            <li>
              Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
              den dem Käufer zum Zeitpunkt des Gefahrübergangs übergebenen,
              ordnungsgemäß und qualitätsschonend getrockneten und verpackten
              Rohhopfen je Kilogramm zzgl. der zum Zeitpunkt der
              Leistungserbringung geltenden gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
        {/* // Variante 6
      // Alphatabelle nein
      // ja ohne */}
        {raw && !alphaStart && quality === 0 ? (
          <ul className="ml-8 list-disc">
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird
            </li>
            <label className="ml-4 mt-4 mb-4">
              ohne Geltung von Zu- und Abschlägen zu Grunde gelegt.
            </label>
            <li>
              Der Grundpreis sowie die jeweiligen Zu- und Abschläge gelten für
              den dem Käufer zum Zeitpunkt des Gefahrübergangs übergebenen,
              ordnungsgemäß und qualitätsschonend getrockneten und verpackten
              Rohhopfen je Kilogramm zzgl. der zum Zeitpunkt der
              Leistungserbringung geltenden gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
        {/* // ALPHA */}
        {!raw ? (
          <ul className="ml-8 list-disc">
            <li>
              Die Musterziehung und Alphafeststellung, sowie eine eventuelle
              Nachuntersuchung erfolgt nach den Bestimmungen der AVHLV Ziffer V.
              4. und 5.
            </li>
            <li>
              Die Ermittlung des Alphawertes erfolgt nach der Methode Analytica
              EBC 7.4 im Untersuchungslabor HHV Au, Mainburgerstr. 26, 84072
              Au/Hallertau
            </li>
            <li>
              Die Qualitätstabelle für deutschen Siegelhopfen (AVHLV, Anhang
              III) wird ohne Geltung der dort festgelegten Zu- und Abschläge zu
              Grunde gelegt.
            </li>
            <li>
              Der Preis gilt für den dem Käufer zum Zeitpunkt des
              Gefahrübergangs übergebenen, ordnungsgemäß und qualitätsschonend
              getrockneten und verpackten Rohhopfen je Kilogramm Alpha (EBC 7.4)
              zzgl. der zum Zeitpunkt der Leistungserbringung geltenden
              gesetzlichen Mehrwertsteuer.
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  );
}
