import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import Vorvertrag from "../components/Vorvertrag/Check";
import Freihopfen from "../components/Freihopfen/Check";
import Poolhopfen from "../components/Poolhopfen/Check";

import Success from "../components/Angebote/Success";
import AngebotLayout from "../components/Layout/AngebotLayout";

export default function Checkout() {
  const [success, setSuccess] = useState(false);

  const form = useSelector((state) => state.form);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    (form.angebot !== undefined) &&
    <AngebotLayout>
      {success && <Success open={success} setOpen={setSuccess} />}
      {!form && "Kein Angebot ausgewählt"}
      {form.angebot.Vertragsart === 0 && (
        <Vorvertrag items={form} setSuccess={setSuccess} />
      )}
      {form.angebot.Vertragsart === 1 && (
        <Freihopfen items={form} setSuccess={setSuccess} />
      )}
      {form.angebot.Vertragsart === 3 && (
        <Poolhopfen items={form} setSuccess={setSuccess} />
      )}
    </AngebotLayout>
  )
}
