import {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {DocumentDuplicateIcon} from "@heroicons/react/outline";
import {DocumentButton} from "./DocumentButton";
import {formatDocumentPrefix} from "../../lib/formatDocumentPrefix";
import {isEmpty} from "../../lib/isEmpty";
import filterDocumentsForSmbDuplicates from "../../lib/filterDocumentsForSmbDuplicates";

export function DocumentAction({ documents }) {
  const filteredArray = filterDocumentsForSmbDuplicates(documents);

  return (
    filteredArray.length > 1 ?
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="btn-batch-data" data-tooltip="Dokumente anzeigen" data-tooltip-placement="left">
          <DocumentDuplicateIcon className="block h-6 w-6 hover:text-primary " aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="w-64 absolute top-2 right-8 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <ul className="py-1 list-none">
              {filteredArray.map((document, idx) => <li key={idx}>
                  <DocumentButton type={document.prefix} id={document.key} document={document.file} text={formatDocumentPrefix(document.prefix, document.key, document.body)} />
                </li>
              )}
            </ul>
          </Menu.Items>
        </Transition>
      </Menu> : !isEmpty(filteredArray)
        ? <DocumentButton type={filteredArray[0].prefix} id={filteredArray[0].key} document={filteredArray[0].file} tooltip={formatDocumentPrefix(filteredArray[0].prefix, filteredArray[0].key, filteredArray[0].body)} />
        : null
  )
}
