import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { useDispatch } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";

import AdminLayout from "../../components/Layout/AdminLayout";
import UserForm from "../../components/Forms/UserForm";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import Modal from "../../components/Modal";

import {
  UserCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { impersonateUser, deleteKonto } from "../../actions/adminActions";
import { capitalize } from "../../lib/capitalize";
import { date } from "../../lib/date";

export default function Konten(props) {
  const { data, error, mutate } = useSWR("/api/konto", fetcher);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const deleteClick = (id) => {
    dispatch(deleteKonto(id, props.history));
    mutate();
  };

  const changeGrower = (growerId) => {
    dispatch(impersonateUser(growerId, props.history));
  };

  const columns = [
    {
      Header: "Typ",
      accessor: "role",
      Cell: (props) => capitalize(props.value),
    },
    {
      Header: "Vorname",
      accessor: "firstName",
    },
    {
      Header: "Name",
      accessor: "lastName",
    },
    {
      Header: "E-Mail Adresse",
      accessor: "email",
    },
    {
      Header: "Pflanzer",
      accessor: "grower.name",
    },
    {
      Header: "BLE-Nr.",
      accessor: "grower.growerNumber",
    },
    {
      Header: "Betriebsnummer",
      accessor: "grower.balisnummer",
    },
    {
      Header: "Letzter Login",
      accessor: "lastLogin",
      Cell: (props) => (props.value !== null ? date(props.value) : "-"),
    },
    {
      accessor: "0",
      Cell: (props) => (
        <button
          onClick={() => changeGrower(props.cell.row.original.grower.growerId)}
          data-tooltip="Pflanzer wechseln"
        >
          <UserCircleIcon className="block h-6 w-6" aria-hidden="true" />
        </button>
      ),
    },
    {
      accessor: "1",
      Cell: (props) => (
        <Link to={`/admin/konto/${props.cell.row.original.id}`}>
          {" "}
          <PencilIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </Link>
      ),
    },
    {
      accessor: "delete",
      Cell: (props) => (
        <Modal
          icon={
            <TrashIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          }
          modalAction={() => deleteClick(props.cell.row.original.id)}
          modalText="Benutzer wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout>
      {edit ? (
        <UserForm defaultValues={""} edit={edit} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Benutzer hinzufügen"
          title="VERWALTUNG BENUTZER"
          data={data.data}
          columns={columns}
          search={true}
          csv={true}
        />
      )}
    </AdminLayout>
  );
}
