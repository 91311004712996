import useSWR from "swr";
import fetcher from "../lib/fetcher";
import { useDispatch } from "react-redux";

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import Table from "../components/Table/Table";
import Error from "../components/Error";

import { LinkIcon, CloudIcon } from "@heroicons/react/outline";

import { getFile } from "../actions/fileActions";
import { date } from "../lib/date";

export default function Neuigkeiten() {
  const { data, error } = useSWR("/api/infos/news", fetcher);
  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Datum",
      accessor: "date",
      Cell: (props) => date(props.value),
    },
    {
      Header: "Betreff",
      accessor: "message",
      Cell: (props) => <p className="max-w-lg">{props.value}</p>,
    },
    {
      Header: function showHeader() {
        return <span className="sr-only">PDF anzeigen</span>;
      },
      accessor: "fileId",
      Cell: (props) =>
        props.value !== 0 ? (
          <button
            onClick={() => dispatch(getFile(props.value))}
            data-tooltip="PDF anzeigen"
          >
            <CloudIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          </button>
        ) : props.row.original.link ? (
          <a href={props.row.original.link} target="_blank" rel="noreferrer">
            <LinkIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          </a>
        ) : (
          ""
        ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout>
      <Table title="Neuigkeiten" data={data} columns={columns} />
    </Layout>
  );
}
