import axios from "axios";

// ADD ALPHA
export const addAlpha = (values, history) => async (dispatch) => {
  const res = await axios.post("/api/alphas", values);
  dispatch({ type: "GET_ADMIN_ALPHAS", payload: res.data });
  history.push("/admin/mittelwerte");
};

// UPDATE ALPHA
export const updateAlpha = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/alphas/alpha/${id}`, values);
  dispatch({ type: "GET_ADMIN_ALPHAS", payload: res.data });
  history.push("/admin/mittelwerte");
};

export const deleteAlpha = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/alphas/alpha/${id}`);
  dispatch({ type: "GET_ADMIN_ALPHAS", payload: res.data });
  history.push("/admin/mittelwerte");
};

export const deleteAngebot = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/contracts/${id}`);
  dispatch({ type: "GET_ADMIN_OFFERS", payload: res.data });
  history.push("/admin/angebote");
};

// UPDATE SALESMAN BY ID
export const updateSystem = (values, history) => async (dispatch) => {
  const res = await axios.patch("/api/admin/system", values);
  dispatch({ type: "GET_ADMIN_SYSTEM", payload: res.data });
  history.push("/admin/system");
};

// UPDATE FORM
export const systemUpload = (values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/admin/system/upload`, values);
  dispatch({ type: "GET_ADMIN_SYSTEM", payload: res.data });
};

// UPDATE LINK
export const addEstimate = (values, history) => async (dispatch) => {
  const res = await axios.post(`/api/estimates`, values);
  dispatch({ type: "GET_ADMIN_ESTIMATES", payload: res.data });
  history.push("/admin/schätzungen");
};

// GET ALL ESTIMATES
export const updateEstimate = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/estimates/edit/${id}`, values);
  dispatch({ type: "GET_ADMIN_ESTIMATES", payload: res.data });
  history.push("/admin/schätzungen");
};

// UPDATE SALESMAN BY ID
export const addSalesman = (values, history) => async (dispatch) => {
  const res = await axios.post(`/api/salesman/`, values);
  dispatch({ type: "GET_ADMIN_LINKS", payload: res.data });
  history.push("/admin/einkäufer");
};

// UPDATE SALESMAN BY ID
export const updateSalesman = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/salesman/${id}`, values);
  dispatch({ type: "GET_ADMIN_LINKS", payload: res.data });
  history.push("/admin/einkäufer");
};

// DELETE LINK
export const deleteSalesman = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/salesman/${id}`);
  dispatch({ type: "GET_ADMIN_SALESMEN", payload: res.data });
  history.push("/admin/einkäufer");
};

// UPDATE SALESMAN BY ID
export const updateMail = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/mails/${id}`, values);
  dispatch({ type: "GET_ADMIN_EMAILS", payload: res.data });
  history.push("/admin/email");
};

// DELETE LINK
export const deleteMail = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/mail/${id}`);
  dispatch({ type: "GET_ADMIN_EMAILS", payload: res.data });
  history.push("/admin/email");
};

// ADD LINK
export const updateLink = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/links/${id}`, values);
  dispatch({ type: "GET_ADMIN_LINKS", payload: res.data });
  history.push("/admin/links");
};

// UPDATE LINK
export const addLink = (values, history) => async (dispatch) => {
  const res = await axios.post(`/api/links`, values);
  history.push("/admin/links");
  dispatch({ type: "GET_ADMIN_LINKS", payload: res.data });
};

// DELETE LINK
export const deleteLink = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/links/${id}`);
  dispatch({ type: "GET_ADMIN_LINKS", payload: res.data });
  history.push("/admin/links");
};

// ADD NEWSITEM
export const addNews = (values, history) => async (dispatch) => {
  const res = await axios.post(`/api/news`, values);
  history.push("/admin/neuigkeiten");
  dispatch({ type: "GET_ADMIN_NEWS", payload: res.data });
};

// UPDATE NEWS ITEM
export const updateNews = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/news/${id}`, values);
  dispatch({ type: "GET_ADMIN_NEWS", payload: res.data });
  history.push("/admin/neuigkeiten");
};

// DELETE NEWS ITEM
export const deleteNews = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/news/${id}`);
  dispatch({ type: "GET_ADMIN_NEWS", payload: res.data });
  history.push("/admin/neuigkeiten");
};

export const addForm = (values, history) => async (dispatch) => {
  const res = await axios.post(`/api/forms`, values);
  dispatch({ type: "GET_ADMIN_FORMS", payload: res.data });
  history.push("/admin/formulare");
};

// UPDATE FORM
export const updateForm = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/forms/${id}`, values);
  dispatch({ type: "GET_ADMIN_FORMS", payload: res.data });
  history.push("/admin/formulare");
};

// DELETE LINK
export const deleteForm = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/forms/${id}`);
  dispatch({ type: "GET_ADMIN_FORMS", payload: res.data });
  history.push("/admin/formulare");
};

// DELETE LINK
export const deleteKonto = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/konto/${id}`);
  dispatch({ type: "GET_ADMIN_KONTEN", payload: res.data });
  history.push("/admin/konten");
};

// GET ALL Sorten
export const sendNewsletter = (values, history) => async (dispatch) => {
  axios
    .post("/api/newsletter", values)
    .then((res) => history.push("/dashboard"))
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

// Impersonate User
export const impersonateUser = (value, history) => async (dispatch) => {
  axios
    .post(`/api/admin/impersonate/pflanzer/${value}`)
    .then(
      (res) =>
        dispatch({ type: "CHANGE_AREA", payload: res.data }) &&
        history.push("/dashboard")
    )
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};
