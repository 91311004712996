import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

export default function SelectInput({ name, label, options, defaultValue }) {
  const { control } = useFormContext();

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <Controller
        control={control}
        defaultValue={options.map((c) => c.value)}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <ReactSelect
            placeholder="Auswählen..."
            inputRef={ref}
            defaultValue={options.find((c) => c.value === defaultValue)}
            onChange={(val) => onChange(val.value)}
            options={options}
          />
        )}
      />
    </div>
  );
}
