import { useState } from "react";
import axios from "axios";

import { CloudIcon, ClockIcon } from "@heroicons/react/outline";

export default function useFile(url, title) {
  const [data] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onViewClick = () => {
    if (!data) {
      setLoading(true);
      axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Accept: "application/pdf",
        },
        responseType: "arraybuffer",
      })
        .then((res) => {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    }
  };

  return !loading && !error ? (
    <button onClick={() => onViewClick()} data-tooltip={title}>
      <CloudIcon
        className="block h-6 w-6 hover:text-primary"
        aria-hidden="true"
      />
    </button>
  ) : (
    <ClockIcon
      className="block h-6 w-6 text-grey-600 hover:text-primary"
      aria-hidden="true"
    />
  );
}
