import { Link } from "react-router-dom";

import { Fragment, useEffect } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { UserCircleIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { logoutUser, clearCurrentUser } from "../../actions/authActions";
import { getSecondGrower } from "../../actions/kontoActions";

import { classNames } from "../../lib/classNames";
import { isAdmin } from "../../lib/isAdmin";
import { isSalesman } from "../../lib/isSalesman";

import logo from "../../img/hop.png";

export default function Authbar({ navigation }) {
  const secondGrower = useSelector((state) => state.accounts.secondGrower);
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated && auth.user.role === "user") {
      dispatch(getSecondGrower(auth.user.growerId));
    }
  }, [auth, dispatch]);

  const user = {
    name: `${auth.user.firstname} ${auth.user.lastname}`,
    email: auth.user.email,
  };

  const userNavigation = isAdmin(auth.user.role)
    ? [
        { name: "Admin", href: "/admin/angebote" },
        { name: "Pflanzer wechseln", href: "/pflanzer" },
        { name: "Konto", href: "/konto" },
      ]
    : isSalesman(auth.user.role)
    ? [
        { name: "Pflanzer wechseln", href: "/pflanzer" },
        { name: "Konto", href: "/konto" },
      ]
    : [{ name: "Konto", href: "/konto" }];

  const onLogoutClick = (e) => {
    e.preventDefault();
    dispatch(clearCurrentUser());
    dispatch(logoutUser());
  };

  return (
    <Popover
      as="header"
      className={({ open }) =>
        classNames(
          open ? "fixed inset-0 z-40 overflow-y-auto" : "",
          "bg-white border-b border-primary shadow-sm lg:static lg:overflow-y-visible"
        )
      }
    >
      {({ open }) => (
        <>
          <div className="px-6 py-8 max-w-7xl mx-auto sm:px-6 lg:px-8 ">
            <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
              <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/dashboard">
                    <img
                      className="block h-8 w-auto"
                      src={logo}
                      alt="Hopsteiner"
                      width="50"
                    />
                  </Link>
                  <div className="font-display uppercase text-2xl pt-1  px-4">
                    <Link to="/dashboard">Pflanzerportal</Link>
                  </div>
                </div>
              </div>
              <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                <div className="flex items-center  md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0"></div>
              </div>

              <div className="flex items-center md:absolute md:right-0 md:inset-y-0 xl:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>

              <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                <p>{user.name}</p>

                {/* Profile dropdown */}
                <Menu as="div" className="flex-shrink-0 relative ml-5">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                          <span className="sr-only">Open user menu</span>
                          <UserCircleIcon
                            className="h-6 w-6 hover:text-primary"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          {auth.isAuthenticated &&
                            auth.user.role === "user" &&
                            secondGrower.length > 1 && (
                              <a
                                href="/betrieb"
                                className="block py-2 px-4 text-sm text-gray-700"
                              >
                                Betrieb auswählen
                              </a>
                            )}
                          <button
                            className="block py-2 px-4 text-sm text-gray-700"
                            onClick={onLogoutClick}
                          >
                            Abmelden
                          </button>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>

          <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="z-10 max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "hover:bg-gray-50",
                    "block rounded-md py-2 px-3 text-base font-medium"
                  )}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                <div className="flex-shrink-0">
                  <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                {userNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                ))}
                {auth.isAuthenticated &&
                  auth.user.role === "user" &&
                  secondGrower.length > 1 && (
                    <a
                      href="/betrieb"
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      Betrieb auswählen
                    </a>
                  )}
                <button
                  className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  onClick={onLogoutClick}
                >
                  Abmelden
                </button>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
