import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Input from "../../components/Input/Input";
import FileInput from "../../components/Input/FileInput";
import AdminLayout from "../../components/Layout/AdminLayout";

import { sendNewsletter } from "../../actions/adminActions";

export default function Newsletter() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const methods = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("file", data.file[0]);
    formData.append("betreff", data.betreff);
    formData.append("body", value);

    dispatch(sendNewsletter(formData, history));
  };

  return (
    <FormProvider {...methods}>
      <AdminLayout>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 m-2 mb-4">
            <div className="px-4 py-5 sm:px-6">
              <h1 className="font-display uppercase text-3xl">
                Newsletter verschicken
              </h1>
            </div>
            <div className="px-4 py-5 sm:p-6 z-0">
              <Input type="text" placeholder="Betreff" name="betreff" />
              <div className="py-2" />
              <ReactQuill theme="snow" value={value} onChange={setValue} />
              <div className="py-2" />
              <FileInput name="file" accept="image/*, application/pdf" />
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <a
                href="/admin/angebote"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Zurück
              </a>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Newsletter versenden
              </button>
            </div>
          </div>
        </form>
      </AdminLayout>
    </FormProvider>
  );
}
