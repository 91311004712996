import axios from "axios";

// GET CURRENT USER ACCOUNT
export const getAccount = () => async (dispatch) => {
  const res = await axios.get("/api/accounts/user");
  dispatch({ type: "GET_ACCOUNT", payload: res.data });
};

export const updateAccount = (values, touched) => async (dispatch) => {
  const data = { values: values, touched: touched };

  axios
    .patch(`/api/accounts/user`, data)
    .then((res) => dispatch(getAccount()))
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

export const addGrowerEstimate = (values, year) => async (dispatch) => {
  await axios.post(`/api/estimates/grower/${year}`, values);
};
