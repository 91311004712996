import {getDocument} from "../../actions/documentActions";
import {BeakerIcon, CurrencyEuroIcon, DocumentTextIcon} from "@heroicons/react/outline";
import {useDispatch} from "react-redux";
import arrayContains from "../../lib/arrayContains";

export function DocumentButton({ document, id, tooltip, text, type, showIcon }) {
  const dispatch = useDispatch();
  const contractTypes = ["EKV", "EVK", "LAV", "EVU", "EVD"]

  return (
    <button
      onClick={() => dispatch(getDocument(document, id))}
      className={`btn-icon btn-batch-data ${text ? "inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 hover:text-primary focus:outline-none" : ''} ${showIcon === false && "text-primary"}`}
      data-tooltip={tooltip}
    >
      {showIcon !== false &&
        (type === 'ERE'
            ? <CurrencyEuroIcon className="block h-5 w-5 mr-2 hover:text-primary" aria-hidden="true" />
            : arrayContains(contractTypes, type)
              ? <DocumentTextIcon className="block h-5 w-5 mr-2 hover:text-primary" aria-hidden="true" />
              : <BeakerIcon className="block h-5 w-5 mr-2 hover:text-primary" aria-hidden="true" />
        )
      }
      {text && text}
    </button>
  )
}

