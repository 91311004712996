import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { getRegisterToken } from "../actions/authActions";
import hop from "../img/hop.png";

export default function Register() {
  const auth = useSelector((state) => state.auth);
  const error = useSelector((state) => state.errors);
  const { register, handleSubmit, formState: errors } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push("/");
    }
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push("/");
    }
  }, [auth.isAuthenticated, history]);

  const onSubmit = (data) => dispatch(getRegisterToken(data));

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          src={hop}
          className="mx-auto h-12 w-auto"
          alt="Hopsteiner Pflanzerportal"
        />
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Registrieren
        </h1>
        <p className="mt-4 text-sm text-gray-600">
          Bitte nur zur Erstanmeldung mit den an Sie per Post übersandten Daten
          benutzen. Falls Sie Hilfe bei der Erstanmeldung benötigen, wenden Sie
          sich bitte an{" "}
          <a
            className="hover:text-primary"
            href="mailto:pflanzerportal@hopsteiner.de"
          >
            pflanzerportal@hopsteiner.de
          </a>
          .
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Betriebsnummer (die letzten 10 Stellen Ihrer Betriebsnummer,
                ohne Länderkürzel)
              </label>
              <div className="mt-1">
                <input
                  {...register("betriebsnummer", { required: true })}
                  id="betriebsnummer"
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="email-password">
                {error && error.betriebsnummer}
                {errors.betriebsnummer && "Bitte ausfüllen!"}
              </p>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-Mail Adresse
              </label>
              <div className="mt-1">
                <input
                  {...register("email", { required: true })}
                  id="email"
                  type="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="email-password">
                {error.email}
                {errors.email && "Bitte ausfüllen!"}
              </p>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Temporäres Passwort
              </label>
              <div className="mt-1">
                <input
                  {...register("password", { required: true })}
                  id="password"
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="email-password">
                {error.password}
                {errors.password && "Bitte ausfüllen!"}
              </p>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Registrieren
              </button>
              <div className="mt-2 w-full flex justify-center py-2 px-4 rounded-md text-sm font-medium text-primary bg-white hover:bg-white hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <Link to="/login">Zurück zum Login</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
