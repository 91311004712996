import logo from "../../img/hopsteiner_logo.svg";
import map from "../../img/footer-map.png";
/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Hopsteiner-Deutschland-129184937221860/",
      icon: "fab fa-facebook",
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/hopsteiner_europe/",
      icon: "fab fa-instagram",
    },
    {
      name: "Twitter",
      href: "https://twitter.com/TeamHopsteiner",
      icon: "fab fa-twitter",
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCePEQW7a4E09lGxs-zAVIVA",
      icon: "fab fa-youtube",
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-4 xl:gap-8">
          <div className="space-y-10 xl:col-span-1">
            <img width="200" src={logo} alt="Hopsteiner" />
            <p className="text-gray-500 text-base">
              <b>Simon H. Steiner, Hopfen, GmbH</b>
              <br /> Auhofstrasse 18 <br />
              D-84048 Mainburg, Germany
              <br />
              <br />
              <b>Email:</b>{" "}
              <a
                className="hover:text-primary"
                href="mailto:pflanzerportal@hopsteiner.de"
              >
                pflanzerportal(at)hopsteiner.de
              </a>
              <br />
              <b>Telefon:</b>{" "}
              <a
                className="hover:text-primary"
                href="tel:+49 (0) 8751 8605 101"
              >
                +49 (0) 8751 8605 101
              </a>
              <br /> <b>Fax:</b>{" "}
              <a
                className="hover:text-primary"
                href="fax:+49 (0) 8751 8605 710"
              >
                +49 (0) 8751 8605 710
              </a>
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  rel="noreferrer"
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                  target="_blank"
                >
                  <span className="sr-only">{item.name}</span>
                  <i
                    className={`${item.icon} h-8 w-8 hover:text-primary`}
                    aria-hidden="true"
                  ></i>
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2">
            <img src={map} width="350" alt="Hopsteiner Karte" />
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-left">
            <a
              href="https://www.hopsteiner.com/de/impressum/"
              rel="noopener noreferrer"
              className="hover:text-primary"
              target="_blank"
            >
              Impressum
            </a>
            {" | "}
            <a
              href="https://www.hopsteiner.com/de/datenschutzerklaerung/"
              rel="noopener noreferrer"
              className="hover:text-primary"
              target="_blank"
            >
              Datenschutz
            </a>
            {" | "}
            <a
              href="https://www.hopsteiner.com/de/kontakt/"
              rel="noopener noreferrer"
              className="hover:text-primary"
              target="_blank"
            >
              Kontakt
            </a>
            {" - "}
            Alle auf dieser Internetseite verwendeten Bilder und Grafiken sind
            urheberrechtlich geschütztes Eigentum von Hopsteiner®
          </p>
        </div>
      </div>
    </footer>
  );
}
