export default function Freihopfen({ register, errors, art }) {
  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {art} (Schätzmenge)
        </h3>
      </div>
      <div className="border-t border-primary " />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  {art}menge Kilogramm Rohhopfen
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">ca.</span>
                  </div>
                  <input
                    name="estimate"
                    ref={register({ required: true })}
                    type="number"
                    className="focus:ring-primary focus:border-primary block w-full pl-12 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0,00"
                    step="0.01"
                    aria-describedby="price-currency"
                    onBlur={(e) => {
                      if (e.target.value) {
                        e.target.value = Number(e.target.value).toFixed(2);
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                  />

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      kg
                    </span>
                  </div>
                </div>

                {errors && errors.estimate && (
                  <p className="mt-2 text-sm text-red-600" id="mengen-error">
                    Bitte ausfüllen!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
