import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import ProfileForm from "../components/Forms/ProfileForm";

export default function Profile() {
  const { data, error } = useSWR("/api/accounts/user", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout title="Benutzerkonto">
      <ProfileForm account={data} />
    </Layout>
  );
}
