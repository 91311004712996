import {
  HomeIcon,
  TrendingUpIcon,
  UserGroupIcon,
  LinkIcon,
  FolderDownloadIcon,
  InboxInIcon,
  NewspaperIcon,
  DocumentTextIcon,
  BeakerIcon,
  ShoppingCartIcon,
  ClipboardListIcon,
  MailIcon,
  CashIcon,
  CogIcon,
  CloudIcon,
  ChatIcon,
} from "@heroicons/react/outline";

export const userNavigation = [
  { name: "Startseite", href: "/dashboard", icon: HomeIcon },
  { name: "Mein Betrieb", href: "/konto", icon: CogIcon },
  {
    name: "Angebote abgeben",
    href: "/angebote",
    icon: ShoppingCartIcon,
  },
  {
    name: "Ihre Angebote",
    href: "/meine-angebote",
    icon: ClipboardListIcon,
  },
  {
    name: "Ihre Verträge",
    href: "/verträge",
    icon: DocumentTextIcon,
  },
  {
    name: "Ihre Partiedaten",
    href: "/partiedaten",
    icon: UserGroupIcon,
  },
  {
    name: "Ihre Schätzungen",
    href: "/schätzungen",
    icon: TrendingUpIcon,
  },
  {
    name: "Qualitätsauswertung",
    href: "/qualität",
    icon: BeakerIcon,
  },
  {
    name: "Sonstige Rechnungen und Gutschriften",
    href: "/abrechnungen",
    icon: CashIcon,
  },
  {
    name: "Nachrichten",
    href: "/nachrichten",
    icon: InboxInIcon,
  },
  {
    name: "Neuigkeiten",
    href: "/neuigkeiten",
    icon: NewspaperIcon,
  },
  {
    name: "Downloads",
    href: "/downloads",
    icon: FolderDownloadIcon,
  },
  {
    name: "Links",
    href: "/links",
    icon: LinkIcon,
  },
  {
    name: "Kontakt",
    href: "/kontakt-einkauf",
    icon: MailIcon,
  },
];

export const adminNavigation = [
  { name: "Startseite", href: "/dashboard", icon: HomeIcon },
  {
    name: "Alle Angebote",
    href: "/admin/angebote",
    icon: ClipboardListIcon,
  },
  {
    name: "Durchschnittswerte",
    href: "/admin/mittelwerte",
    icon: BeakerIcon,
  },
  {
    name: "Schätzungen",
    href: "/admin/schätzungen",
    icon: TrendingUpIcon,
  },
  {
    name: "Benutzerkonten",
    href: "/admin/konten",
    icon: UserGroupIcon,
  },
  {
    name: "System E-Mails",
    href: "/admin/email",
    icon: MailIcon,
  },
  {
    name: "Einkäufer",
    href: "/admin/einkauf",
    icon: CashIcon,
  },
  {
    name: "Neuigkeiten",
    href: "/admin/neuigkeiten",
    icon: NewspaperIcon,
  },
  {
    name: "Formulare",
    href: "/admin/formulare",
    icon: TrendingUpIcon,
  },
  {
    name: "Links",
    href: "/admin/links",
    icon: LinkIcon,
  },
  {
    name: "System",
    href: "/admin/system",
    icon: CogIcon,
  },
  {
    name: "Upload",
    href: "/admin/upload",
    icon: CloudIcon,
  },
  {
    name: "Newsletter",
    href: "/admin/newsletter",
    icon: ChatIcon,
  },
];

export const salesNavigation = [
  { name: "Startseite", href: "/dashboard", icon: HomeIcon },
];

export const wetterlinks = [
  {
    name: "Deutscher Wetterdienst",
    href:
      "https://www.dwd.de/DE/fachnutzer/landwirtschaft/landwirtschaft_node.html",
  },
  {
    name: "Elbe Saale / Thüringen",
    href:
      "https://www.wetter-th.de/Agrarmeteorologie-TH/Wetterdaten/Stationen-Alphabetisch/TAM008",
  },
  {
    name: "Elbe Saale / Sachsen",
    href:
      "https://www.landwirtschaft.sachsen.de/Wetter09/asp/inhalt.asp?seite=uebersicht",
  },
  {
    name: "Elbe Saale / Sachsen-Anhalt",
    href:
      "https://llg.sachsen-anhalt.de/themen/agraroekologie-und-umwelt/agrarmeteorologie/",
  },
  {
    name: "Hallertau",
    href:
      "https://www.wetter-by.de/Agrarmeteorologie-BY/Wetterdaten/Alphabetisch/BAM040",
  },
  {
    name: "Tettnang",
    href:
      "https://www.wetter-bw.de/Agrarmeteorologie-BW/Wetterdaten/Stationen-Alphabetisch/BWAM070",
  },
  {
    name: "Spalt",
    href:
      "https://www.wetter-by.de/Agrarmeteorologie-BY/Wetterdaten/Alphabetisch/BAM055",
  },
];
