import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducers";

const initalState = {};
const middleware = [thunk];

const persistConfig = {
  key: "form",
  storage: storage,
  whitelist: ["form", "grower"], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

let store;

if (process.env.NODE_ENV === "production") {
  store = createStore(
    pReducer,
    initalState,
    compose(applyMiddleware(...middleware))
  );
} else {
  store = createStore(
    pReducer,
    initalState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
}

const persistor = persistStore(store);
export { persistor, store };
