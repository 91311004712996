import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

import { PencilIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Betreff",
    accessor: "subject",
  },
  {
    Header: "Nachricht",
    accessor: "message",
    Cell: (props) => props.value.substr(0, 50) + "...",
  },

  {
    accessor: "sysEmailId",
    Cell: (props) => (
      <Link className="table-control" to={`/admin/email/${props.value}`}>
        <PencilIcon
          className="block h-6 w-6 hover:text-primary"
          aria-hidden="true"
        />
      </Link>
    ),
  },
];

export default function Email({ email }) {
  const { data, error } = useSWR("/api/mails", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <AdminLayout title="Email">
      <Table
        title="System E-Mails"
        buttonLabel="E-Mail hinzufügen"
        data={data.data}
        columns={columns}
      />
    </AdminLayout>
  );
}
