import { checkSum } from "./checkSum";
import { getPriceList } from "./getPriceList";

export function getHopType(offer) {
  const list = getPriceList(offer)

  const raw = checkSum(list, `Preis kg`);
  const alpha = checkSum(list, `Preis kg Alpha`);

  return raw ? " kg Rohhopfen" : alpha ? " kg Alpha" : null;
}
