import axios from "axios";

export const getFile = (id) => async (dispatch) => {
  await axios({
    url: `/api/files/file/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Accept: "application/pdf",
    },
    responseType: "arraybuffer",
  })
    .then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getBlob = (route, id) => async (dispatch) => {
  await axios({
    url: `/api/files/blob/${route}/${id}`,
    method: "GET",
    responseType: "arraybuffer",
  }).then((res) => {
    const file = new Blob([res.data], { type: "application/pdf" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  });
};
