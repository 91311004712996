import {store} from "../store";
import {clearCurrentUser, logoutUser} from "../actions/authActions";


export default function checkForExpiredToken(decodedToken) {
  // Check for expired token
  const currentTime = Date.now() / 1000;

  if (decodedToken.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentUser());
    // Redirect to login
    window.location.href = "/login";
  }
}