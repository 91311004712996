export function formatContract(contract) {
  switch (contract) {
    case 0:
      return "Vorvertrag";
    case 1:
      return "Freihopfen";
    case 3:
      return "Pool-Hopfen";

    default:
      return "Vorvertrag";
  }
}
