export function formatDocumentPrefix(string, number, body) {
  switch (string) {
    case 'EKV':
      return `Vertrag ${number}`
    case 'EVK':
      return `Vertragskorrektur ${number}`
    case 'EVU':
      return `EVU ${number}`
    case 'ERE':
      return `Gutschrift ${number}`
    case 'SMB':
      return 'Spritzmittel'
    case 'PAA':
      return 'Alpha'
    case 'PAB':
      return 'Agrolab'
    case 'EVD':
      return `EVD ${body}`
    default:
      return string;
  }
}
