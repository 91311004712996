import { useState } from "react";
import Switch from "../Input/Switch";

import { PlusIcon, MinusIcon } from "@heroicons/react/solid";

import { formatKilo } from "../../lib/formatKilo";
import { calcSum } from "../../lib/calcSum";
import { createArrayWithNumbers } from "../../lib/createArrayWithNumbers";

export default function FreihopfenMenge({
  register,
  errors,
  art,
  watch,
  type,
  lieferung,
  control,
}) {
  const [size, setSize] = useState(watch().amount ? watch().amount.length : 1);
  const überlieferung = watch().überlieferung;
  const summe = watch().amount ? watch().amount : 0;
  const ballenCheck = watch().weight && watch().weight.some((w) => w === true);
  const total = calcSum(summe, lieferung, überlieferung);

  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {art} (Menge bekannt)
        </h3>
      </div>
      <div className="border-t border-primary" />
      <div className="px-4 py-5 sm:px-6">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <span className="py-2 relative z-0 inline-flex ">
                <b>Überlieferung:</b>
                <p className="ml-2">
                  {lieferung ? formatKilo(lieferung) : "Keine Überlieferung"}
                </p>
                {lieferung > 0 ? (
                  <span className="-ml-px relative inline-flex items-center px-4 py-1 text-sm font-medium text-gray-700">
                    <Switch
                      name="überlieferung"
                      option="Überlieferung hinzufügen"
                      control={control}
                    />
                  </span>
                ) : null}
              </span>

              <div className="mt-4 overflow-hidden ">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th />
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Rohhopfenmenge kg (netto)
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Auf Steiner abgewogen
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {ballenCheck ? "1. Ballennummer" : ""}
                      </th>
                      <th scope="col" />
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {createArrayWithNumbers(size).map((number) => (
                      <tr key={number}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Partie # {number + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="col-span-2 sm:col-span-2">
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                name={`amount[${number}]`}
                                ref={register({
                                  required: "Bitte ausfüllen",
                                })}
                                type="number"
                                className="focus:ring-primary focus:border-primary block w-full pl-6 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0,00"
                                step="0.01"
                                aria-describedby="price-currency"
                                onWheel={(e) => e.target.blur()}
                                onBlur={(e) => {
                                  if (e.target.value) {
                                    e.target.value = Number(
                                      e.target.value
                                    ).toFixed(2);
                                  }
                                }}
                              />

                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <span
                                  className="text-gray-500 sm:text-sm"
                                  id="price-currency"
                                >
                                  kg
                                </span>
                              </div>
                            </div>
                          </div>
                          {errors.amount && errors.amount[number] && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="mengen-error"
                            >
                              Bitte ausfüllen!
                            </p>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Switch
                            name={`weight[${number}]`}
                            option="Nein"
                            option2="Ja"
                            control={control}
                          />
                        </td>
                        {watch().weight && watch().weight[number] && (
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <input
                              name={`partie[${number}]`}
                              ref={register({
                                required: "Bitte ausfüllen",

                                minLength: {
                                  value: 6,
                                  message: "Bitte min. 6 Stellen angeben",
                                },
                                maxLength: {
                                  value: 7,
                                  message: "Bitte max. 7 Stellen angeben",
                                },
                              })}
                              type="number"
                              className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.partie && errors.partie[number] && (
                              <p
                                className="mt-2 text-sm text-red-600"
                                id="mengen-error"
                              >
                                {errors.partie[number].message}
                              </p>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                  <span className="font-bold uppercase">Gesamt:</span>{" "}
                  {formatKilo(total)} kg
                </p>

                <td className="uppercase px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                      onClick={() => setSize(size < 9 ? size + 1 : 9)}
                      type="button"
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                    >
                      <span className="sr-only">Hinzufügen</span>
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      onClick={() => setSize(size > 1 ? size - 1 : 1)}
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                    >
                      <span className="sr-only">Entfernen</span>
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </span>
                </td>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
