export default function Kaufpreisberechnung({ angebot, type, title }) {
  const raw = type === " kg Rohhopfen" ? true : false;
  const alphaStart = angebot["Alpha von"];
  const quality = angebot["Qualität"];

  return raw ? (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      </div>
      <div className="border-t border-primary px-8 py-5 sm:px-6">
        {raw && (
          <p>
            Für die Ermittlung des maßgeblichen Gesamtkaufpreises
            (Anzahlungspreis und Nachzahlungspreis inklusive Zu- und Abschläge)
            wird
          </p>
        )}
        {raw && alphaStart && quality === 0 ? (
          <div className="relative p-2 cursor-pointer  focus:outline-none">
            <label>
              <input
                className="text-primary"
                name="alphapreis"
                type="radio"
                checked
                readOnly
              />
              <span className="ml-4">
                die Alphatabelle für Freihopfen mit den dortigen Zu- und
                Abschlägen sowie zusätzlich die Qualitätstabelle für deutschen
                Siegelhopfen ohne Geltung von Zu- und Abschlägen zu Grunde
                gelegt.
              </span>
            </label>
          </div>
        ) : (
          ""
        )}
        {raw && !alphaStart && quality === 2 ? (
          <div className="relative p-2 cursor-pointer  focus:outline-none">
            <label>
              <input
                className="text-primary"
                name="alphapreis"
                type="radio"
                checked
                readOnly
              />
              <span className="ml-4">
                ausschließlich die Qualitätstabelle für deutschen Siegelhopfen
                (AVHLV, Anhang III) zu Grunde gelegt.
              </span>
            </label>
          </div>
        ) : (
          ""
        )}
        {raw && alphaStart && quality === 1 ? (
          <div className="relative p-2 cursor-pointer  focus:outline-none">
            <label>
              <input
                className="text-primary"
                name="alphapreis"
                type="radio"
                checked
                readOnly
              />
              <span className="ml-4">
                die <b>Alphatabelle</b> für Freihopfen mit den dortigen Zu- und
                Abschlägen sowie <b>zusätzlich die Qualitätstabelle</b> für
                deutschen Siegelhopfen <b>mit</b> Geltung der dort festgelegten
                Zu- und Abschläge in Höhe von jeweils 50% zu Grunde gelegt.
              </span>
            </label>
          </div>
        ) : (
          ""
        )}
        {raw && alphaStart && quality === 2 ? (
          <div className="relative p-2 cursor-pointer  focus:outline-none">
            <label>
              <input
                className="text-primary"
                name="alphapreis"
                type="radio"
                checked
                readOnly
              />
              <span className="ml-4">
                die <b>Alphatabelle</b> für Freihopfen mit den dortigen Zu- und
                Abschlägen sowie <b>zusätzlich die Qualitätstabelle</b> für
                deutschen Siegelhopfen <b>mit</b> Geltung der dort festgelegten
                Zu- und Abschläge in Höhe von jeweils 100% zu Grunde gelegt.
              </span>
            </label>
          </div>
        ) : (
          ""
        )}
        {raw && !alphaStart && quality === 1 ? (
          <div className="relative p-2 cursor-pointer  focus:outline-none">
            <label>
              <input
                className="text-primary"
                name="alphapreis"
                type="radio"
                checked
                readOnly
              />
              <span className="ml-4">
                die Qualitätstabelle mit Geltung der dort festgelegten Zu- und
                Abschläge in Höhe von jeweils 50% zu Grunde gelegt.
              </span>
            </label>
          </div>
        ) : (
          ""
        )}

        {raw && !alphaStart && quality === 0 ? (
          <div className="relative p-2 cursor-pointer  focus:outline-none">
            <label>
              <input
                className="text-primary"
                name="alphapreis"
                type="radio"
                checked
                readOnly
              />
              <span className="ml-4">
                die Qualitätstabelle ohne Geltung von Zu- und Abschlägen zu
                Grunde gelegt.
              </span>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    ""
  );
}
