import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import EstimateModal from "../components/EstimateModal";

import useSystem from "../hooks/useSystem";

function Area() {
  const { data, error } = useSWR("/api/estimates/area", fetcher);

  const areaColumns = [
    {
      Header: "Erntejahr",
      accessor: "Cropyear",
    },
    {
      Header: "Gesamtfläche (ha)",
      accessor: "Total Area (ha)",
    },
    {
      Header: "Änderung zum Vorjahr (ha)",
      accessor: "Variance to previous year (ha)",
    },
    {
      Header: "Änderung zum Vorjahr (%)",
      accessor: "Variance to previous year (_)",
    },
    {
      Header: "Ihre geschätzte Gesamtfläche (ha)",
      accessor: "growerEstimate.totalArea",
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Table
      title="Flächenschätzung"
      columns={areaColumns}
      data={data.data}
      search={false}
      csv={true}
    />
  );
}

function Crop() {
  const { data, error } = useSWR("/api/estimates/crop", fetcher);

  const cropColumns = [
    {
      Header: "Erntejahr",
      accessor: "Cropyear",
    },
    {
      Header: "Gesamtfläche (ha)",
      accessor: "Gesamtfläche (ha)",
    },
    {
      Header: "Gesamtertrag (Ztr.)",
      accessor: "Total Yield (Ztr_)",
    },
    {
      Header: "Ertrag (Ztr./ha)",
      accessor: "Yield (Ztr__ha)",
    },
    {
      Header: "Änderung zum Vorjahr (Ztr.)",
      accessor: "Variance to prev_ year (Ztr_)",
    },
    {
      Header: "Änderung zum Vorjahr (%)",
      accessor: "Variance to previous year (_)",
    },
    {
      Header: "Ihr geschätzter Gesamtertrag (Ztr.)",
      accessor: "growerEstimate.totalYield",
    },
  ];

  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;
  return (
    <Table
      title="Ernteschätzung"
      columns={cropColumns}
      data={data.data}
      search={false}
      csv={true}
    />
  );
}

export default function Estimates({ area, crop }) {
  const system = useSystem();

  return (
    <Layout>
      {system.allowGrowerCropEstimate || system.allowGrowerAreaEstimate ? (
        <div className="px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2" />
            <div className="ml-4 mt-2 flex-shrink-0">
              <EstimateModal />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Area />
      <br />
      <Crop />
    </Layout>
  );
}
