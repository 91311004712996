import useSWR from "swr";
import fetcher from "../lib/fetcher";
import {useDispatch} from "react-redux";

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import Table from "../components/Table/Table";
import Error from "../components/Error";

import {CloudIcon} from "@heroicons/react/outline";

import {getBlob} from "../actions/fileActions";
import {date} from "../lib/date";
import {DocumentAction} from "../components/Documents/DocumentAction";

export default function Messages() {
  const { data, error } = useSWR("/api/infos/messages", fetcher);
  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Datum",
      accessor: "Datum",
      Cell: (props) =>
        date(props.value)
    },
    {
      Header: "Betreff",
      accessor: "Beschreibung",
      Cell: (props) => props.row.original.prefix === 'SMG' ? 'Schadensmeldung ' + props.value : props.value
    },
    {
      Header: "Dokument öffnen",
      accessor: "SourceCounter",
      Cell: (props) => props.row.original.file
        ? <DocumentAction documents={[props.row.original]} />
        : (
          <button
            className="btn-icon"
            onClick={() => dispatch(getBlob("document", props.value))}
            data-tooltip="PDF anzeigen"
          >
            <CloudIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          </button>
        )
    },
  ];


  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout>
      <Table title="Nachrichten"
             data={data.sort((a, b) => {return new Date(b.Datum) - new Date(a.Datum) })}
             options={{ 'pdf': false }}
             columns={columns} />
    </Layout>
  );
}
