import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import {date} from '../lib/date'
import {DocumentAction} from "../components/Documents/DocumentAction";

export default function Invoices() {
  const { data, error } = useSWR("/api/invoices/stock-and-interest", fetcher);


  const columns = [
    {
      Header: "Rechnungs- / Gutschriftnummer",
      accessor: "No_",
    },
    {
      Header: "Datum",
      accessor: "Posting Date",
      Cell: (props) => date(props.value),
    },
    {
      accessor: "0",
      Cell: (props) => <DocumentAction documents={[props.row.original.documents]} />
    }
  ]

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <Layout>
      <Table title="Sonstige Rechnungen und Gutschriften" search={true} data={data.data} columns={columns} />
    </Layout>
  );
}
