import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, clearErrors } from "../actions/authActions";

import header from "../img/hop_summer.jpg";
import hop from "../img/hop.png";

export default function Auth(props) {
  const { register, handleSubmit } = useForm();
  const auth = useSelector((state) => state.auth);
  const error = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const onSubmit = (data) => dispatch(loginUser(data));

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      props.history.push("/dashboard");
    }
  }, [auth.isAuthenticated, props.history]);

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <div className="h-12 w-auto">
              <img width="50" src={hop} alt="Hopsteiner Pflanzerportal" />
            </div>
            <h2 className="font-display uppercase mt-6 text-3xl font-extrabold text-gray-900">
              Anmeldung
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Zum ersten Mal hier?{" "}
              <Link
                className="font-medium text-primary hover:text-secondary"
                to="/register"
              >
                Registrieren Sie sich hier!
              </Link>
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label
                    htmlFor="loginEmail"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-Mail Adresse
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      autoComplete="username"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                      {...register("email")}
                    />
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {error.email && error.email}
                    </p>
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="inputPassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Passwort
                  </label>
                  <div className="mt-1">
                    <input
                      id="inputPassword"
                      type="password"
                      autoComplete="current-password"
                      {...register("password", { required: true })}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="email-password"
                    >
                      {error.password && error.password}
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-sm font-medium text-primary hover:text-secondary">
                    <Link to="/forgot">Passwort vergessen?</Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Anmelden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={header}
          alt=""
        />
      </div>
    </div>
  );
}
