import useSWR from "swr";
import fetcher from "../lib/fetcher";

export default function useLieferung(sorte) {
  const { data, error } = useSWR(
    `/api/contracts/ueberlieferung/${sorte}`,
    fetcher
  );

  if (error) return "An error has occurred.";
  if (!data) return 0;
  return data;
}
