import useSWR from "swr";
import fetcher from "../lib/fetcher";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import useAuth from "../hooks/useAuth";
import { clearOffer } from "../actions/formActions";
import { filterAngebote } from "../lib/filterAngebote";

import ShopLayout from "../components/Layout/ShopLayout";
import FilterSidebar from "../components/Filter/FilterSidebar";
import HopCard from "../components/Shop/HopCard";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import useSystem from "../hooks/useSystem";

export default function Shop() {
  const { user } = useAuth();

  const { data, error } = useSWR("/api/contracts/offers", fetcher);

  const dispatch = useDispatch();
  const system = useSystem();

  const [vertrag, setVertrag] = useState();
  const [year, setYear] = useState();
  const [hop, setHop] = useState();
  const [type, setType] = useState();

  const filteredAngebote = filterAngebote(data, year, hop, type, vertrag);

  useEffect(() => {
    dispatch(clearOffer());
  }, [dispatch]);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <ShopLayout
      sidebar={
        <FilterSidebar
          angebote={filteredAngebote}
          year={year}
          setYear={setYear}
          vertrag={vertrag}
          setVertrag={setVertrag}
          hop={hop}
          setHop={setHop}
          type={type}
          setType={setType}
          system={system}
        />
      }
    >
      <h1 className="uppercase font-display text-3xl mt-2 mb-2">
        Ihre Vertragsangebote
      </h1>
      <ul className="mt-8 space-y-3">
        {filteredAngebote.map((angebot, idx) => (
          <HopCard
            key={idx}
            cropYear={system.cropYear}
            angebot={angebot}
            button={true}
            user={user}
          />
        ))}
      </ul>
    </ShopLayout>
  );
}
