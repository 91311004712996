import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import Spinner from "../../components/Spinner";
import SystemForm from "../../components/Forms/SystemForm";
import AdminLayout from "../../components/Layout/AdminLayout";
import Error from "../../components/Error";

export default function System() {
  const { data, error } = useSWR("/api/admin/system", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    companyName: data.data.companyName,
    address1: data.data.address1,
    city: data.data.city,
    state: data.data.state,
    zipCode: data.data.zipCode,
    countryCode: data.data.countryCode,
    phone: data.data.phone,
    fax: data.data.fax,
    email: data.data.email,
    secondOfficeDescription: data.data.secondOfficeDescription,
    secondOffice1: data.data.secondOffice1,
    secondOffice2: data.data.secondOffice2,
    secondOffice3: data.data.secondOffice3,
    secondOffice4: data.data.secondOffice4,
    secondOffice5: data.data.secondOffice5,
    secondOffice6: data.data.secondOffice6,
    cropYear: data.data.cropYear,
    cropYearForEstimates: data.data.cropYearForEstimates,
    growerChangeEmails: data.data.growerChangeEmails,
    allowGrowerAreaEstimate: data.data.allowGrowerAreaEstimate,
    allowGrowerCropEstimate: data.data.allowGrowerCropEstimate,
    sendGDNoticeToGrower: data.data.sendGDNoticeToGrower,
    sendGDNoticeToAccount: data.data.sendGDNoticeToAccount,
  };

  return (
    <AdminLayout>
      <SystemForm defaultValues={defaultValues} />
    </AdminLayout>
  );
}
