import useSWR from "swr";
import fetcher from "../lib/fetcher";
import {useState} from "react";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import useCropYear from "../hooks/useCropYear";
import {DocumentAction} from "../components/Documents/DocumentAction";
import {DocumentButton} from "../components/Documents/DocumentButton";
import checkForCurrentYear from "../lib/checkForCurrentYear";
import {isEmpty} from "../lib/isEmpty";

export default function Contracts() {
  const { data, error } = useSWR("/api/accounts/contracts", fetcher);
  const [contracts, setContracts] = useState();

  const year = useCropYear();

  const columns = [
    {
      Header: "Vertrags-Nr.",
      accessor: "Vertragsnr_",
    },
    {
      Header: "Vertragsart",
      accessor: "Kontrakt-Art",
    },
    {
      Header: "Pool-Hopfen",
      accessor: "Pool-Hopfen",
    },
    {
      Header: "Erntejahr",
      accessor: "Jahr",
    },
    {
      Header: "Sorte",
      accessor: "Hop",
    },
    {
      Header: "Vertragsmenge",
      accessor: "Menge",
    },
    {
      Header: "Einheit",
      accessor: "Mengeneinheit",
    },
    {
      Header: "Preis",
      accessor: "Preis",
    },
    {
      Header: "Spritznorm",
      accessor: "SpritzNorm",
      Cell: (props) => !isEmpty(props.value) && checkForCurrentYear(props.row.original.Jahr)
        ? <DocumentButton id="sprayNorm" document="SYS" text={props.value} tooltip="Spritznormen anzeigen" showIcon={false}></DocumentButton>
        : !isEmpty(props.value) ? props.value : ''
    },
    {
      accessor: "0",
      Cell: (props) => <DocumentAction documents={props.row.original.documents} />
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <Layout>
      <Table
        title="Verträge"
        data={contracts ? contracts : data.filter((contract) => contract.Jahr === year)}
        columns={columns}
        pdf={true}
        csv={true}
        search={true}
        tabs={
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button
              type="button"
              onClick={() => setContracts(data.filter((contract) => contract.Jahr === year))}
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
            >
              Aktuelle Ernte
            </button>
            <button
              type="button"
              onClick={() => setContracts(data.filter((contract) => contract.Jahr < year))}
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
            >
              Vergangenheit
            </button>
            <button
              type="button"
              onClick={() => setContracts(data.filter((contract) => contract.Jahr > year))}
              className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
            >
              Zukunft
            </button>
          </span>
        }
      />
    </Layout>
  );
}
