import { useForm } from "react-hook-form-old";
import { useDispatch } from "react-redux";
import { updateAccount } from "../../actions/accountActions";

export default function ProfileForm({ account }) {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(updateAccount(data, touched));
  };

  const { register, handleSubmit,  formState } = useForm({
    defaultValues: {
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      name: account.grower.name,
      name2: account.grower.name2,
      mobil: account.grower.mobil,
      address: account.grower.address,
      address2: account.grower.address2,
      zipCode: account.grower.zipCode,
      city: account.grower.city,
      phoneNumber: account.grower.phoneNumber,
      faxNumber: account.grower.faxNumber,
      mobileNumber: account.grower.mobileNumber,
      growerEmail: account.grower.email,
    },
  });

  const { touched } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Benutzerkonto
              </h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>
            <div className="mt-6 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Vorname
                  </label>
                  <input
                    ref={register({ required: true })}
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nachname
                  </label>
                  <input
                    type="text"
                    ref={register({ required: true })}
                    name="lastName"
                    id="lastName"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-Mail
                  </label>
                  <input
                    type="email"
                    name="email"
                    ref={register({ required: true })}
                    id="email"
                    required
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Aktuelles Passwort
                  </label>
                  <input
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    ref={register({ required: true })}
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
              <p className="mt-8 block text-sm font-medium text-gray-700">
                Sie müssen Ihr aktuelles Passwort eingeben, wenn Sie etwas
                <br /> auf dieser Seite ändern möchten.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Betriebsdaten
              </h3>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    name="name"
                    ref={register()}
                    type="text"
                    id="name"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name2
                  </label>
                  <input
                    name="name2"
                    ref={register()}
                    type="text"
                    id="name2"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adresse
                  </label>
                  <input
                    type="text"
                    name="address"
                    ref={register()}
                    id="address"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="address2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adresszusatz
                  </label>
                  <input
                    type="text"
                    name="address2"
                    ref={register()}
                    id="address2"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-2 mb-3">
                  <label
                    htmlFor="zipCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    PLZ
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    ref={register()}
                    id="zipCode"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-4 mb-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Stadt
                  </label>
                  <input
                    type="text"
                    name="city"
                    ref={register()}
                    id="city"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Telefon
                  </label>
                  <input
                    type="text"
                    ref={register()}
                    name="phoneNumber"
                    id="phoneNumber"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="faxNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fax
                  </label>
                  <input
                    type="text"
                    ref={register()}
                    name="faxNumber"
                    id="faxNumber"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="mobileNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobil
                  </label>
                  <input
                    type="text"
                    ref={register()}
                    name="mobileNumber"
                    id="mobileNumber"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="growerEmail"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-Mail
                  </label>
                  <input
                    type="text"
                    name="growerEmail"
                    ref={register()}
                    id="growerEmail"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Stammdaten
              </h3>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="anbaugebiet"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Anbaugebiet
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.anbaugebiet}
                    id="anbaugebiet"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="growerNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    BLE-Nr.
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.growerNumber}
                    id="growerNumber"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="balisnummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Betriebsnummer
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.balisnummer}
                    id="balisnummer"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="steuernummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Steuernummer
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.steuernummer}
                    id="steuernummer"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="zertifizierterBetrieb"
                    className="block text-sm font-medium text-gray-700"
                  >
                    QM-Hopfen
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.zertifizierterBetrieb ? "Ja" : "Nein"}
                    id="zertifizierterBetrieb"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="nachhaltigerBetrieb"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nachhaltiger Betrieb
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.nachhaltigerBetrieb ? "Ja" : "Nein"}
                    id="nachhaltigerBetrieb"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="vatRule"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mehrwertsteuersatz
                  </label>

                  <input
                    type="text"
                    defaultValue={account.grower.vatGroup ? account.grower.vatGroup.replace('Landwirte ', '').replace('Inland ', '') : '-'}
                    id="vatRule"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm bg-gray-200 border-gray-300 rounded-md"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Passwort ändern
              </h3>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <p className="mb-8 block text-sm font-medium text-gray-700">
                Lassen Sie bitte die Passwortfelder leer, wenn Sie das Passwort
                nicht ändern wollen!
              </p>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <label
                    htmlFor="secondOfficeDescription"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Passwort
                  </label>

                  <input
                    type="text"
                    name="new_password"
                    ref={register()}
                    id="secondOfficeDescription"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <label
                    htmlFor="secondOffice1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Passwort bestätigen
                  </label>

                  <input
                    type="text"
                    name="new_password2"
                    ref={register()}
                    id="secondOffice3"
                    className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <a
            href="/dashboard"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Abbrechen
          </a>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Speichern
          </button>
        </div>
      </div>
    </form>
  );
}
