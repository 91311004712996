/* This example requires Tailwind CSS v2.0+ */
import axios from "axios";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useAuth from "../../hooks/useAuth";

export default function Terms() {
  const auth = useAuth();
  const methods = useForm();
  const [open, setOpen] = useState(true);
  const [terms, setTerms] = useState(sessionStorage.getItem("terms") || false);

  useEffect(() => {
    setTerms(sessionStorage.getItem("terms") || false);
  }, []);

  const cancelButtonRef = useRef(null);

  const onSubmit = (values) => {
    if (true) {
      sessionStorage.setItem("terms", true);
    }

    axios.patch("/api/accounts/terms", values);
    setOpen(false);
  };

  return (
    auth.user.terms !== 1 &&
    terms === false && (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-6 inline-block align-bottom bg-white min-h-screen min-w-screen rounded-lg px-4 pt-5 pb-4 text-left overflow-x-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-justify sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg uppercase font-display leading-6 font-medium text-gray-900"
                    >
                      Vertragsangebotsbestimmungen
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-sm text-gray-500">
                        I. Über diese Plattform können Sie uns Ihr
                        Vertragsangebot zum Abschluss eines
                        Hopfenliefervertrages online zusenden.{" "}
                        <b>
                          Bitte beachten Sie, dass Ihnen diese Möglichkeit nur
                          zur Verfügung steht, wenn Sie dabei als Unternehmer
                          und nicht als Verbraucher handeln.
                        </b>{" "}
                        Unternehmer ist jede natürliche oder juristische Person
                        oder rechtsfähige Personengesellschaft, die beim
                        Abschluss des Vertrags in Ausübung ihrer gewerblichen
                        oder selbstständigen beruflichen Tätigkeit handelt. Sie
                        handeln dagegen als Verbraucher, wenn die Angebotsabgabe
                        zu Zwecken dient, die überwiegend weder Ihrer
                        gewerblichen noch ihrer selbstständigen beruflichen
                        Tätigkeit zugerechnet werden kann.
                      </p>
                      <br />
                      <p className="text-sm text-gray-500">
                        II. Die von uns zur Verfügung gestellten
                        Auswahlmöglichkeiten stellen kein rechtlich bindendes
                        Angebot zum Abschluss eines Liefervertrages dar. Sie
                        können die zur Verfügung gestellten Vertragsvarianten
                        auswählen und die relevanten Daten in der vorgegebenen
                        Reihenfolge eingeben bzw. auswählen. Nach Eingabe bzw.
                        Auswahl aller relevanter Daten erhalten Sie nochmals
                        eine Übersicht Ihres Vertragsangebots und können die
                        Angaben durch Anklicken des Kästchens „Ihr Angebot
                        korrigieren“ korrigieren.
                        <br />
                        Das Angebot können Sie zudem im PDF-Format abrufen und
                        speichern. Dies gilt auch für die Allgemeinen
                        Vereinbarungen zum Hopfenlieferungsvertrag (AVHLV),
                        deren Geltung Sie durch Anklicken des entsprechenden
                        Kästchens vor Absendung Ihres Angebots zustimmen und die
                        Sie ebenfalls abrufen und speichern können.
                        <br />
                        Durch Anklicken des Buttons „Ihr Vertragsangebot
                        verbindlich absenden“ geben Sie ein verbindliches
                        Angebot zum Abschluss des Liefervertrages ab. Sie sind
                        an das Angebot für die Dauer von 10 Werktagen nach
                        Eingang bei uns gebunden. Unter „Ihre Angebote“ können
                        Sie den Status Ihres jeweiligen Angebots einsehen. Das
                        Angebot wird gespeichert.
                      </p>
                      <br />
                      <p className="text-sm text-gray-500">
                        III. Wir werden den Zugang Ihres Angebots unverzüglich
                        per E-Mail bestätigen. In einer solchen E-Mail liegt
                        noch keine verbindliche Annahme des Angebots. Der
                        Vertrag kommt zustande, wenn und sobald wir Ihr Angebot
                        ausdrücklich per E-Mail annehmen und der Vertrag im
                        Portal unter der Rubrik „Ihre Angebote“ mit Status
                        „Angenommen“ und in der Rubrik „Ihre Verträge“ mit
                        entsprechender Vertragsnummer zum Abruf/Download
                        erscheint.
                      </p>
                      <br />
                      <p className="text-sm text-gray-500">
                        IV. Für das Zustandekommen sowie die Durchführung des
                        Vertrages über den Kauf und die Lieferung von Hopfen
                        zwischen dem Verkäufer und Käufer ist gemäß den Ziffern
                        X. und XI. der jeweils gültigen AVHLV die Textform (§
                        126b BGB) ausreichend. Ergänzungen und Änderungen des
                        Vertrages bedürfen ebenfalls der Textform (§ 126b BGB).
                      </p>
                      <br />
                      <p className="text-sm text-gray-500">
                        V. Sofern der Vertrag zustande kommt erhalten Sie über
                        die Dauer des Vertrages im jeweiligen Lieferjahr einen{" "}
                        <b className="green">
                          <u>
                            „Portal Abschlussbonus“ in Höhe von EUR 0,01 je
                            Kilogramm Liefermenge
                          </u>
                        </b>
                        , auf die tatsächlich angelieferte Menge im Vertrag. Die
                        Gutschrift erfolgt mit der Abrechnung der Liefermenge.
                      </p>
                      <br />
                      <em>letzte Aktualisierung: 11.02.2022</em>
                    </div>
                  </div>
                </div>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="mt-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            aria-describedby="terms"
                            {...methods.register("terms")}
                            type="checkbox"
                            className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <span
                            id="offers-description"
                            className="text-gray-500"
                          >
                            Bei erneutem Anklicken nicht mehr anzeigen. Obige
                            Hinweise können Sie bei Vertragsangebotsabgabe
                            weiterhin aufrufen und einsehen.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Verstanden
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  );
}
