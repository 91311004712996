import { useState } from "react";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import LinkForm from "../../components/Forms/LinkForm";
import Modal from "../../components/Modal";
import Error from "../../components/Error";

import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import { deleteLink } from "../../actions/adminActions";

export default function Links(props) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const { data, error } = useSWR("/api/links", fetcher);

  const deleteClick = (id) => dispatch(deleteLink(id, props.history));

  const columns = [
    {
      Header: "Sortierung",
      accessor: "sort",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Beschreibung",
      accessor: "description",
    },
    {
      Header: "URL",
      accessor: "url",
      Cell: (props) => (
        <span className="hover:text-primary">
          <a target="_blank" rel="noopener noreferrer" href={props.value}>
            {props.value.substr(0, 50) + "..."}
          </a>
        </span>
      ),
    },
    {
      accessor: "0",
      Cell: (props) => (
        <span className="hover:text-primary">
          <Link to={`/admin/links/${props.cell.row.original.linkId}`}>
            <PencilIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          </Link>
        </span>
      ),
    },
    {
      accessor: "1",
      Cell: (props) => (
        <Modal
          icon={
            <TrashIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          }
          modalAction={() => deleteClick(props.cell.row.original.linkId)}
          modalText="Link wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout title="Links">
      {edit ? (
        <LinkForm defaultVaues={""} edit={edit} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Link hinzufügen"
          title="Links"
          data={data.data}
          columns={columns}
          search={true}
        />
      )}
    </AdminLayout>
  );
}
