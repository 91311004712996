import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import NewsForm from "../../components/Forms/NewsForm";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

import { formatDate } from '../../lib/formatDate';

export default function NewsEdit(props) {
  const { data, error, mutate } = useSWR(`/api/news/${props.match.params.id}`, fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    date: formatDate(data.data.date),
    message: data.data.message,
    url: data.data.url,
    show: data.data.show,
    link: data.data.link,
    file: data.data.file,
    fileId: data.data.fileId,
  };

  return (
    <AdminLayout>
      <NewsForm {...props} defaultValues={defaultValues} mutate={mutate} />
    </AdminLayout>
  );
}
