import Authbar from "./Authbar";
import Terms from "../Angebote/Terms";
import { userNavigation } from "../../utils/navigation";

function ShopLayout({ title, children, sidebar }) {
  return (
    <div className="min-h-screen bg-gray-100">
      <Authbar navigation={userNavigation} />
      <Terms />
      <div className="m-2 py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-full lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:block lg:col-span-3 xl:col-span-2">{sidebar}</div>
          <main className="lg:col-span-12 xl:col-span-9">
            <h1 className="sr-only">{title}</h1>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default ShopLayout;
