import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import { Link } from "react-router-dom";

export default function Quality() {
  const { data, error } = useSWR("/api/quality", fetcher);

  const columns = [
    {
      Header: "Sorte",
      accessor: "hop.Beschreibung",
      Cell: function bold(props) {
        return <b>{props.value}</b>;
      },
    },
    {
      accessor: "alpha",
      Cell: function getAlpha(props) {
        return (
          <Link
            data-tooltip="Alpha"
            to={`/qualität/chart?wert=alpha&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-font hover:text-primary"></i>
          </Link>
        );
      },
    },
    {
      accessor: "wasser",
      Cell: function getWasser(props) {
        return (
          <Link
            data-tooltip="Wasser"
            to={`/qualität/chart?wert=wasser&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-water hover:text-primary"></i>
          </Link>
        );
      },
    },
    {
      accessor: "leaves",
      Cell: function getStengel(props) {
        return (
          <Link
            className="px-2"
            data-tooltip="Blatt / Stengel"
            to={`/qualität/chart?wert=stengel&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-seedling hover:text-primary"></i>
          </Link>
        );
      },
    },
    {
      accessor: "waste",
      Cell: function getAbfall(props) {
        return (
          <Link
            data-tooltip="Abfall"
            to={`/qualität/chart?wert=abfall&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-trash hover:text-primary"></i>
          </Link>
        );
      },
    },
    {
      accessor: "dolden",
      Cell: function getDolden(props) {
        return (
          <Link
            data-tooltip="Doldenblätter"
            to={`/qualität/chart?wert=dolden&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-leaf hover:text-primary"></i>
          </Link>
        );
      },
    },
    {
      accessor: "rating",
      Cell: function getRating(props) {
        return (
          <Link
            data-tooltip="Bonität"
            to={`/qualität/chart?wert=bonität&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-euro-sign hover:text-primary"></i>
          </Link>
        );
      },
    },
    {
      accessor: "saldo",
      Cell: function getSaldo(props) {
        return (
          <Link
            data-tooltip="Saldo"
            to={`/qualität/chart?wert=saldo&sorte=${props.row.original.Sorte}`}
          >
            <i className="fas fa-balance-scale hover:text-primary"></i>
          </Link>
        );
      },
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout title="Verträge">
      <Table title="Qualitätsauswertung" data={data.data} columns={columns} />
    </Layout>
  );
}
