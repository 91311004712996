import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Layout from "../components/Layout/Layout";
import HopImage from "../components/Shop/HopImage";
import Spinner from "../components/Spinner";
import Table from "../components/Table/Table";
import Error from "../components/Error";

import { isEmpty } from "../lib/isEmpty";
import useFile from "../hooks/useFile";

const columns = [
  {
    Header: "",
    accessor: "hop",
    Cell: function hopImage(props) {
      return (
        <HopImage
          alt={props.value.Beschreibung}
          src={props.value.Beschreibung}
          width="40px"
        />
      );
    },
  },
  {
    Header: "Angebot",
    accessor: "Angebotsnummer",
  },
  {
    Header: "Art",
    accessor: "Vertragsart",
  },

  {
    Header: "Datum",
    accessor: "Angebotsdatum",
  },
  {
    Header: "Sorte",
    accessor: "hop.Beschreibung",
  },
  {
    Header: "Start",
    accessor: "Erstes Lieferjahr",
  },
  {
    Header: "Ende",
    accessor: "Letztes Lieferjahr",
    Cell: (props) => (props.value === 0 ? "-" : props.value),
  },
  {
    Header: "Status",
    accessor: "Status",
    Cell: (props) =>
      props.value === 2 ? (
        <span className="text-primary">Angenommen</span>
      ) : props.value === 1 ? (
        <span className="red">Abgelehnt</span>
      ) : (
        "Ausstehend"
      ),
  },
  {
    Header: "Vertrag",
    accessor: "EK Vertragsnummer",
    Cell: (props) => (isEmpty(props.value) ? "-" : props.value),
  },
  {
    accessor: "0",
    Cell: (props) =>
      useFile(
        `/api/files/angebot/${props.row.original.Angebotsnummer}`,
        "Angebot anzeigen"
      ),
  },
];

export default function MeineAngebote({ contracts }) {
  const { data, error } = useSWR("/api/contracts", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <Layout>
      <Table title="Ihre Angebote" data={data} columns={columns} />
    </Layout>
  );
}
