import useSWR from "swr";
import fetcher from "../lib/fetcher";

export default function useHop(sorte) {
  const { data, error } = useSWR(`/api/contracts/hop/${sorte}`, fetcher);

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  const hop = data.data;

  return hop;
}
