const initalState = {};

export default function errorReducer(state = initalState, action) {
  switch (action.type) {
    case "GET_ERRORS":
      return action.payload;

    case "CLEAR_ERRORS":
      return {};

    default:
      return state;
  }
}
