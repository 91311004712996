import { sum } from "./sum";

export function calcSum(summe, lieferung, überlieferung) {
  const addLieferung = überlieferung ? lieferung : 0;
  const addSum = summe ? summe : 0;

  const finalSum = addSum.length !== undefined ? sum(addSum) : addSum;

  const result = Number(finalSum) + Number(addLieferung);
  return result;
}
