import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import team from "../img/Einkäufer2021.jpg";
import logo from "../img/Hopsteiner-Logo.png";

export default function Kontakt() {
  const { data, error } = useSWR("/api/infos/kontakt", fetcher);
  const kontakt = data;

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const locations = [
    {
      city: "EINKAUFSBÜRO MAINBURG",
      name: kontakt.companyName,
      address: kontakt.address1 + ", " + kontakt.zipCode + " " + kontakt.city,
      phone: kontakt.phone,
      fax: kontakt.fax,
      mail: kontakt.mail,
    },
    {
      city: "EINKAUFSBÜRO TETTNANG",
      name: kontakt.companyName,
      name2: kontakt.secondOffice1,
      address: kontakt.secondOffice2 + ", " + kontakt.secondOffice3,
      phone: kontakt.secondOffice4,
      fax: kontakt.secondOffice5,
      mail: kontakt.secondOffice6,
    },
  ];

  const people = [
    {
      name: "Pascal Piroué",
      role: "(Geschäftsführer)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/Piroue.jpg",
    },
    {
      name: "Martin Schöttl-Pichlmaier",
      role: "(Prokurist, Einkaufsleiter)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/21327.jpg",
    },
    {
      name: "Florian Fritsch",
      role: "(stellv. Einkaufsleiter, Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/21328.jpg",
    },
    {
      name: "Michael Bauer",
      role: "(Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/92342.jpg",
    },
    {
      name: "Maria Berger",
      role: "(Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/92336.jpg",
    },
    {
      name: "Siegfried Gebendorfer",
      role: "(Einkauf Hallertau und Elbe Saale)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/21329.jpg",
    },
    {
      name: "Johanna Fischbacher",
      role: "(Einkauf Hallertau, Lagerlogistik)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/49333.jpg",
    },
    {
      name: "Maximilian Stadler",
      role: "(Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/104900.jpg",
    },
    {
      name: "Georg Seitz",
      role: "(Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/114177.jpg",
    },

    {
      name: "Petra Körndl",
      role: "(Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/21324.jpg",
    },
    {
      name: "Johannes Spielberger",
      role: "(Einkauf Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/21326.jpg",
    },
    {
      name: "Andreas Joos",
      role: "(Einkauf Tettnang)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/92340.jpg",
    },
    {
      name: "Franz-Josef Heilig",
      role: "(Einkauf Tettnang)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/92339.jpg",
    },
    {
      name: "Albert Krojer",
      role: "(Musterlogistik)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/Krojer.jpg",
    },
    {
      name: "Cornelia Kollmannsberger",
      role: "(Sachbearbeitung Elbe Saale, Hallertau und Tettnang)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/Kollmannsberger.jpg",
    },
    {
      name: "Anita Schauer",
      role: "(Sachbearbeitung Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/Schauer.jpg",
    },
    {
      name: "Ursula Zeilmaier",
      role: "(Sachbearbeitung Hallertau)",
      imageUrl: process.env.REACT_APP_API + "/public/salesman/Zeilmaier.jpg",
    },
    {
      name: "",
      role: "",
      imageUrl: logo,
    },
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      <main>
        <div className="relative bg-primary">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={team}
                alt="Hopsteiner Einkaufs Team"
              />
            </div>
          </div>
          <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:col-start-2 lg:pl-8">
              <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-1 sm:gap-x-6 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  {locations.map((location) => (
                    <div key={location.city}>
                      <h3 className="text-lg font-medium text-white">
                        {location.city}
                      </h3>
                      <div className="mt-2 text-base text-white space-y-1">
                        <b>{location.name}</b>
                        <br />
                        <i>{location.name2}</i>
                        <p>{location.address}</p>
                        <br />
                        <p>
                          <b>Telefon: </b>
                          {location.phone}
                        </p>
                        <p>
                          <b>Fax: </b>
                          {location.fax}
                        </p>
                        <p>
                          <b>E-Mail: </b>
                          <a
                            className="hover:text-secondary"
                            href={`mailto:${kontakt.email}`}
                          >
                            {kontakt.email}
                          </a>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Side-by-side grid */}

        <div className="bg-white">
          <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12">
              <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                <h2 className="font-display uppercase text-primary text-3xl font-extrabold tracking-tight sm:text-4xl">
                  Einkaufsteam
                </h2>
              </div>
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
                {people.map((person) => (
                  <li key={person.name}>
                    <div
                      className={person.name ? "space-y-4" : "space-y-4 mt-32"}
                    >
                      <div className="aspect-w-3 aspect-h-2">
                        <img
                          className={
                            person.name
                              ? "object-cover shadow-lg rounded-lg"
                              : ""
                          }
                          src={person.imageUrl}
                          alt=""
                        />
                      </div>

                      <div className="space-y-2">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{person.name}</h3>
                          <p className="text-primary">{person.role}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
