import React from "react";
import {useExportData} from "react-table-plugins";

import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {lato} from "../../css/lato.js";

import {useTable, useSortBy, useGlobalFilter, useExpanded} from "react-table";

import GlobalFilter from "./TableFilter";


function getExportFileBlob({ columns, data, fileType }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns
    .map((c) => c.exportValue)
    // FILTER FOR EMPTY / BUTTON ROWS
    .filter((h) => h !== "0" && h !== "1" && h !== "2");
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "Pflanzerportal Export");
    XLSX.writeFile(wb, `Pflanzerportal Export.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns
    .map((column) => column.exportValue)
    .filter((h) => h !== "0" && h !== "1" && h !== "2");

    const doc = new JsPDF("l");

    doc.addFileToVFS("Lato.ttf", lato);
    doc.addFont("Lato.ttf", "Lato", "");

    doc.autoTable({
      head: [headerNames],
      body: data,
      theme: "plain",
      margin: { top: 20 },
      styles: {
        font: "Lato",
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 8,
      },
    });
    doc.save(`Pflanzerportal Export.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

export default function Table({ columns, data, csv, pdf, search, button, buttonLabel, title, footer, tabs, options, renderRowSubComponent }, ...props) {
  // Use the state and functions returned from useTable to build your UI
  const { rows, state, getTableProps, getTableBodyProps, headerGroups, prepareRow, preGlobalFilteredRows, setGlobalFilter, visibleColumns, exportData } =
    useTable(
      {
        columns,
        data,
        getExportFileBlob,
        initialState:
          {
            hiddenColumns: columns.map(column => {
              if (column.show === false) return column.accessor || column.id;
            }),
          }
      },
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      useExpanded, // We can useExpanded to track the expanded state
      useExportData
    );

  const parsedOptions = options || {};

  // Render the UI for your table
  return (
    <div className="relative pb-5 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        <h1 className="font-display border-b border-primary text-2xl mb-10 uppercase leading-6 font-bold text-gray-900">
          {title}
        </h1>
        <div className="mt-3 flex md:mt-0 md:absolute md:top-3 md:right-0">
          {(search || parsedOptions.search) && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          {(csv || parsedOptions.csv) && (
            <button
              onClick={() => {
                exportData("xlsx", true);
              }}
              data-tooltip="Export als Excel"
              type="button"
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
            >
              CSV
            </button>
          )}
          {(pdf || parsedOptions.pdf) && (
            <button
              type="button"
              className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
              data-tooltip="Export als PDF"
              onClick={() => {
                exportData("pdf", true);
              }}
            >
              PDF
            </button>
          )}

          {buttonLabel && (
            <button
              type="button"
              className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
              onClick={button}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
      {tabs && tabs}

      <div className="mt-2 flex flex-col">
        <div className="my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-200"
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                {headerGroups.map((headerGroup, idx) => (
                  <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, idx) => (
                      <th
                        key={idx}
                        className="p-3 text-left text-xs font-medium text-gray-500 uppercase"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: "Sortieren" })
                        )}
                      >
                        {column.render("Header")}
                        <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                {rows.map((row, i) => {
                  prepareRow(row);

                  return (
                    // Use a React.Fragment here so the table markup is still valid
                    <React.Fragment key={i}>
                      <tr>
                        {row.cells.map((cell, idx) => {
                          return (
                            <td
                              key={idx}
                              className="py-2 pl-1 pr-1 text-sm text-gray-500 sm:pl-6"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? (
                        <tr class="bg-gray-50 py-2">
                          <td colSpan={visibleColumns.length}>
                            {renderRowSubComponent({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                </tbody>
                {footer}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
