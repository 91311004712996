import useSWR from "swr";
import fetcher from "../lib/fetcher";

import {useState, useCallback} from "react";
import {PlusIcon, MinusIcon} from "@heroicons/react/solid";


import useCropYear from "../hooks/useCropYear";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import {DocumentAction} from "../components/Documents/DocumentAction";

import Error from "../components/Error";

export default function BatchData() {
  const { data, error } = useSWR("/api/accounts/partiedaten", fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
  });

  const year = useCropYear();
  const [batchData, setBatchData] = useState();

  function aggregateDocumentArray(data) {
    const documents = [];

    if (data.invoices.length > 1) {
      data.invoices.map((invoice) => {
        return documents.push(invoice.documents);
      })
    } else {
      if (data.invoices[0]) {
        documents.push(data.invoices[0].documents);
      }
    }

    documents.push(...data.spraysheet)
    return documents;
  }

  const columns = [
      {
        Header: "Erntejahr",
        accessor: "Jahr",
      },
      {
        Header: "Sorte",
        accessor: "hop.Beschreibung",
      },
      {
        Header: "Geliefert (kg)",
        accessor: "Gewicht in KG",
      },
      {
        Header: "Geliefert (Kg Alpha)",
        accessor: "Geliefert (Kg Alpha)",
      },

      {
        Header: "Verpackung",
        accessor: "Verpackung",
      },
      {
        Header: "Alpha (%)",
        accessor: "@-Säure in _",
      },
      {
        Header: "Ballen von",
        accessor: "Ballennummer von",
      },
      {
        Header: "Ballen bis",
        accessor: "Ballennummer bis",
      },
      {
        Header: "Anzahl Ballen",
        accessor: "Ballenzahl",
      },
      {
        Header: "Wasser (%)",
        accessor: "Wasserwert in _",
        show: false
      },
      {
        Header: "Blatt/Stengel (%)",
        accessor: "Blatt-_Stengelanteil in _",
        show: false
      },
      {
        Header: "Abfall (%)",
        accessor: "Hopfenabfall in _",
        show: false
      },
      {
        Header: "Doldenblätter (%)",
        accessor: "Doldenblätter in _",
        show: false
      },
      {
        Header: "Bonität",
        accessor: "Gesamtbefall",
        show: false
      },
      {
        Header: "Saldo",
        accessor: "Saldo in _ (2003)",
        show: true
      },
      {
        // Make an expander cell
        Header: "Analytik anzeigen", // No header
        id: 'expander', // It needs an ID
        Cell: ({ row, props }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <MinusIcon className="block h-5 w-5 mr-2 hover:text-primary" aria-hidden="true" /> : <PlusIcon className="block h-5 w-5 mr-2 hover:text-primary" aria-hidden="true" />}
          </span>
        ),
      },
      {
        Header: "Dokumente",
        Cell:
          ((props) => {
            const documentArray = aggregateDocumentArray(props.row.original);
            return <DocumentAction documents={documentArray} />
          })
      }
    ]
  ;

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      const { values } = row;

      return (
        <table>
          <tbody>
          <tr>
            {["Wasser (%)", "Blatt/Stengel (%)", "Abfall (%)", "Doldenblätter (%)", "Bonität", "Saldo"].map((th, idx) =>
              <td key={idx} className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">{th}</td>
            )}
          </tr>
          <tr>
            {[values["Wasserwert in _"], values["Blatt-_Stengelanteil in _"], values["Hopfenabfall in _"], values["Doldenblätter in _"], values["Gesamtbefall"], values["Saldo in _ (2003)"]]
            .map((td, idx) =>
              <td key={idx} className="py-2 pl-1 pr-1 text-sm sm:pl-6 text-gray-500">{td}</td>
            )}
          </tr>
          </tbody>
        </table>
      )
    },
    []
  )

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <Layout>
      <Table
        title="Partiedaten und Gutschriften"
        data={batchData ? batchData : data.filter((contract) => contract.Jahr === year)}
        columns={columns}
        options={{ 'pdf': true, 'csv': true, 'search': true }}
        renderRowSubComponent={renderRowSubComponent}
        tabs={
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button
              type="button"
              onClick={() => setBatchData(data.filter((contract) => contract.Jahr === year))}
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
            >
              Aktuelle Ernte
            </button>
            <button
              type="button"
              onClick={() => setBatchData(data.filter((contract) => contract.Jahr < year))}
              className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
            >
              Vergangenheit
            </button>
          </span>
        }
      />
    </Layout>
  );
}
