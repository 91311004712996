export const saveOffer = (items) => ({
  type: "SAVE_OFFER",
  items,
});

export const clearOffer = (items) => ({
  type: "CLEAR_OFFER",
  items,
});

export default function formReducer(state = {}, action) {
  switch (action.type) {
    case "SAVE_OFFER":
      return { ...action.items };
    case "CLEAR_OFFER":
      return {};

    default:
      return state;
  }
}
