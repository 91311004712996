import useSWR from "swr";
import fetcher from "../lib/fetcher";
import { useDispatch } from "react-redux";

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import Table from "../components/Table/Table";
import Error from "../components/Error";

import { CloudIcon } from "@heroicons/react/outline";

import { getFile } from "../actions/fileActions";
import { date } from "../lib/date";

export default function Verträge() {
  const dispatch = useDispatch();
  const { data, error } = useSWR("/api/infos/forms", fetcher);

  const columns = [
    {
      Header: "Datum",
      accessor: "files.dateUploaded",
      Cell: (props) => date(props.value),
    },
    {
      Header: "Beschreibung",
      accessor: "description",
    },
    {
      accessor: "fileId",
      Cell: (props) => (
        <button
          className="btn-icon"
          onClick={() => dispatch(getFile(props.value))}
          data-tooltip="PDF anzeigen"
        >
          <CloudIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </button>
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <Layout>
      <Table
        title="Downloads"
        data={data.sort((a, b) => {
          return b.formId - a.formId;
        })}
        columns={columns}
      />
    </Layout>
  );
}
