import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { useState } from "react";
import { Link } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/outline";

import SchätzungenForm from "../../components/Forms/SchätzungenForm";
import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

const columns = [
  {
    Header: "Pflanzer",
    accessor: "grower.name",
  },
  {
    Header: "BLE-Nr.",
    accessor: "grower.growerNumber",
  },
  {
    Header: "Betriebsnummer",
    accessor: "grower.balisnummer",
  },
  {
    Header: "Anbaugebiet",
    accessor: "growingArea",
  },
  {
    Header: "Erntejahr",
    accessor: "cropYear",
  },
  {
    Header: "Gesamtfläche (ha)",
    accessor: "totalArea",
  },
  {
    Header: "Gesamtertrag (Ztr.)",
    accessor: "totalYield",
  },
  {
    accessor: "0",
    Cell: (props) => (
      <Link
        to={`/admin/schätzungen/${props.cell.row.original.growerEstimateId}`}
      >
        <PencilIcon
          className="block h-6 w-6 hover:text-primary"
          aria-hidden="true"
        />
      </Link>
    ),
  },
];

export default function Schätzungen({ estimate, grower }) {
  const [edit, setEdit] = useState(false);
  const { data, error } = useSWR("/api/estimates", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout title="Schätzungen">
      {edit ? (
        <SchätzungenForm defaultValues={""} edit={edit} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Schätzung hinzufügen"
          title="Schätzungen"
          data={data.data}
          columns={columns}
          search={true}
          csv={true}
        />
      )}
    </AdminLayout>
  );
}
