import { useState } from 'react';
import { SearchIcon } from "@heroicons/react/solid";
import { useAsyncDebounce } from "react-table";

export default function GlobalFilter({globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="mt-3 sm:mt-0 sm:ml-4">
      <label htmlFor="search_candidate" className="sr-only">
        Search
      </label>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            type="text"
            name="search_candidate"
            id="search_candidate"
            className="focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300"
            placeholder="Suchen..."
          />
          <input
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            type="text"
            name="search_candidate"
            id="search_candidate_mobil"
            className="hidden focus:ring-primary focus:border-primary w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
            placeholder="Suchen..."
          />
        </div>
      </div>
    </div>
  )
}