import spinner from "../img/spinner.gif";

export default function Spinner() {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <img
          src={spinner}
          width="50"
          height="50"
          alt="Loading..."
          className="spinner mx-auto d-block"
        />
      </div>
    </div>
  );
}
