import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { useDispatch } from "react-redux";
import { impersonateUser } from "../../actions/adminActions";

import { UserCircleIcon } from "@heroicons/react/outline";

import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

export default function Grower(props) {
  const dispatch = useDispatch();

  const changeGrower = (growerId) => {
    dispatch(impersonateUser(growerId, props.history));
  };

  const { data, error } = useSWR("/api/admin/salesman/grower", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "BLE-No.",
      accessor: "growerNumber",
    },
    {
      Header: "Anbaugebiet",
      accessor: "anbaugebiet",
    },

    {
      accessor: "growerId",
      Cell: (props) => (
        <button
          className="table-control"
          onClick={() => changeGrower(props.value)}
          data-tooltip="Benutzer wechseln"
        >
          <UserCircleIcon
            className="h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </button>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Table
        title="Pflanzer auswählen"
        data={data.data}
        columns={columns}
        search={true}
      />
    </AdminLayout>
  );
}
