import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { useState } from "react";
import { deleteNews } from "../../actions/adminActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  PencilIcon,
  CheckIcon,
  XIcon,
  TrashIcon,
} from "@heroicons/react/outline";

import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import NewsForm from "../../components/Forms/NewsForm";
import Error from "../../components/Error";

import { date } from "../../lib/date";

export default function Neuigkeiten(props) {
  const [edit, setEdit] = useState(false);
  const { data, error } = useSWR("/api/news", fetcher);
  const dispatch = useDispatch();
  const deleteClick = (id) => dispatch(deleteNews(id, props.history));

  const columns = [
    {
      Header: "Datum",
      accessor: "date",
      Cell: (props) => date(props.value),
    },
    {
      Header: "Name",
      accessor: "message",
    },
    {
      Header: "Newsticker",
      accessor: "show",
      Cell: (props) =>
        props.value ? (
          <CheckIcon
            className="block h-6 w-6 text-primary"
            aria-hidden="true"
          />
        ) : (
          <XIcon className="block h-6 w-6 text-red-500" aria-hidden="true" />
        ),
    },

    {
      accessor: "newsId",
      Cell: (props) => (
        <Link to={`/admin/neuigkeiten/${props.value}`}>
          <PencilIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </Link>
      ),
    },
    {
      accessor: "delete",
      Cell: (props) => (
        <Modal
          icon={
            <TrashIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          }
          modalAction={() => deleteClick(props.cell.row.original.newsId)}
          modalText="Neuigkeit wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout>
      {edit ? (
        <NewsForm defaultVaues={""} edit={edit} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Neuigkeit hinzufügen"
          title="Neuigkeiten"
          data={data.data}
          columns={columns}
          search={true}
        />
      )}
    </AdminLayout>
  );
}
