export function getQualityDetails(param) {
  let columns = {};
  switch (param) {
    case "alpha":
      return (columns = {
        headline: "Alpha-Gehalt (%)",
        label1: "Alpha % ",
        value1: "@-Säure in _",
        label2: "% Alpha Durchschnitt Anbaugebiet",
        value2: "quality.alphaContent",
      });
    case "wasser":
      return (columns = {
        headline: "Wassergehalt (%)",
        label1: "Wasser (%)",
        value1: "Wasserwert in _",
        label2: "% Wasser Durchschnitt Anbaugebiet",
        value2: "quality.humidity",
      });
    case "stengel":
      return (columns = {
        headline: "Blatt- / Stengelanteil (%)",
        label1: "Blatt / Stengel (%)",
        value1: "Blatt-_Stengelanteil in _",
        label2: "% Blatt / Stengel Durchschnitt Anbaugebiet",
        value2: "quality.leafStemPercent",
      });
    case "abfall":
      return (columns = {
        headline: "Abfallanteil (%)",
        label1: "Abfall (%)",
        value1: "Hopfenabfall in _",
        label2: "% Abfall Durchschnitt Anbaugebiet",
        value2: "quality.wastePercent",
      });
    case "dolden":
      return (columns = {
        headline: "Doldenblattanteil (%)",
        label1: "Doldenblätter (%)",
        value1: "Doldenblätter in _",
        label2: "% Doldenblätter Durchschnitt Anbaugebiet",
        value2: "quality.percentLeaves",
      });
    case "bonität":
      return (columns = {
        headline: "Bonität (%)",
        label1: "Bonität (%)",
        value1: "Gesamtbefall",
        label2: "Bonität Durchschnitt Anbaugebiet",
        value2: "quality.rating",
      });
    case "saldo":
      return (columns = {
        headline: "Saldo (%)",
        label1: "Saldo",
        value1: "Saldo in _ (2003)",
        label2: "Saldo Durchschnitt Anbaugebiet",
        value2: "quality.balance",
      });
    default:
      return columns;
  }
}
