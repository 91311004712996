import { sum } from "../../lib/sum";
import { getCol } from "../../lib/getCol";
import { showRange } from "../../lib/showRange";
import { getRange } from "../../lib/getRange";
import { createArrayWithNumbers } from "../../lib/createArrayWithNumbers";
import { handleWarning } from "../../lib/handleWarning";

export default function Liefermenge({
  register,
  watch,
  setValue,
  errors,
  angebot,
  startUvm,
  type,
}) {
  const menge = watch("menge");
  const ha = watch("ha");
  const beginn = watch("beginn");
  const ende = watch("ende");
  const liefermenge = watch("liefermenge");

  const defaultMenge = liefermenge ? liefermenge[0] : null;

  const latest = angebot["Beginn spätestens"];

  const test = getRange(beginn, ende).findIndex(
    (element) => element === latest
  );

  const duration = ende - beginn + 1;
  const length = duration < 0 ? 1 : duration;
  const hektar = ha ? ha : [0];
  const uvm = startUvm;

  let arr = new Array(Number(length) || 0).fill(uvm);

  const years = showRange(beginn, duration - 1);

  function handleHektarChange(e) {
    if (e.target.value) {
      for (var i = 0; i < ha.length; i++) {
        setValue(`ha[${i}]`, e.target.value);
      }
    }
  }

  function handleMengenChange(e) {
    if (e.target.value) {
      for (var i = 0; i < liefermenge.length; i++) {
        setValue(`liefermenge[${i}]`, Number(e.target.value));
      }
    }
  }

  function hektarInput() {
    return arr.map((input, idx) => (
      <td key={idx}>
        <input
          className="mt-5 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          type="number"
          step="0.01"
          name={`ha[${idx}]`}
          onChange={idx === 0 ? handleHektarChange : null}
          onWheel={(e) => e.target.blur()}
          placeholder="0,00"
          ref={register({
            required: true,
            validate: (value) => value > 0,
          })}
        />
        <br />
        {errors.ha && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors.ha[idx]?.type === "required" && "Bitte ausfüllen"}
            {errors.ha[idx]?.type === "validate" && "Bitte ausfüllen"}
          </p>
        )}
      </td>
    ));
  }

  function liefermengenInput(number, max) {
    return arr.map((input, idx) => {
      return (
        <td key={idx}>
          <input
            className="mt-5 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            type="number"
            name={`liefermenge[${idx}]`}
            defaultValue={defaultMenge}
            placeholder="0"
            step="0.01"
            ref={register({
              required: true,
              max: liefermenge && liefermenge[idx],
              min: 1,
            })}
            onChange={idx === 0 ? handleMengenChange : null}
            onWheel={(e) => e.target.blur()}
          />
          <br />
          {errors.liefermenge && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {errors.liefermenge[idx]?.type === "required" &&
                "Bitte ausfüllen"}
              {errors.liefermenge[idx]?.type === "min" && "Bitte ausfüllen"}
              {errors.liefermenge[idx]?.type === "max" &&
                test > -1 &&
                test < idx &&
                true &&
                `Mengensteigerungen nach ${latest} unzulässig`}
            </p>
          )}
          <br />
          <span className="text-sm text-yellow-500">
            {liefermenge &&
              Number(liefermenge[idx]) > Number(max[idx]).toFixed(0) &&
              `Max. Liefermenge überschritten.`}
          </span>
        </td>
      );
    });
  }

  function showUVM() {
    for (let i = 0; i < length; i++) {
      return arr.map((input, idx) => {
        return (
          <td key={idx}>
            {Number(uvm)
              .toFixed(0)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
          </td>
        );
      });
    }
  }

  function getUVM() {
    for (let i = 0; i < length; i++) {
      return arr.map((input, idx) => {
        let result = uvm * (hektar[i] ? Number(hektar[idx]) : 0);

        return (
          <td key={idx}>
            {Number(isNaN(result) ? 0 : result)
              .toFixed(0)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
          </td>
        );
      });
    }
  }

  function getTotal(menge) {
    for (let i = 0; i < length; i++) {
      return arr.map((input, idx) => {
        let liefermenge = menge ? sum(getCol(menge, idx)) : 0;
        let result = uvm * (hektar[i] ? Number(hektar[idx]) : 0) - liefermenge;
        return (
          <td key={idx}>
            {Number(isNaN(result) ? 0 : result)
              .toFixed(0)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
          </td>
        );
      });
    }
  }

  function showTotal(menge) {
    for (let i = 0; i < length; i++) {
      return arr.map((input, idx) => {
        let liefermenge = menge ? sum(getCol(menge, idx)) : 0;
        let result = uvm * (hektar[i] ? Number(hektar[idx]) : 0) - liefermenge;
        return result;
      });
    }
  }

  return (
    <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Leistungsfähigkeit des Verkäufers{" "}
        </h3>
      </div>
      <div className="border-t border-primary px-4 py-5 sm:px-6">
        <table className="mt-6 min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th scope="col">
                <span className="sr-only">Verpflichtung</span>
              </th>
              {years.map((year, idx) => (
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  key={idx}
                >
                  {year}
                </th>
              ))}
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {createArrayWithNumbers("1").map((number, idx) => {
              return (
                <tr key={idx}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold uppercase text-gray-900">
                    Altfläche ha
                  </td>
                  {hektarInput(number)}
                </tr>
              );
            })}
            {createArrayWithNumbers("1").map((number, idx) => {
              return (
                <tr key={idx}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold uppercase text-gray-900">
                    UVM pro ha ({type.substring(1)})
                  </td>
                  {showUVM()}
                </tr>
              );
            })}
            {createArrayWithNumbers("1").map((number, idx) => {
              return (
                <tr key={idx}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold uppercase text-gray-900">
                    UVM gesamt ({type.substring(1)})
                  </td>
                  {getUVM()}
                </tr>
              );
            })}
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-bold uppercase text-gray-900">
                max. Liefermenge ({type.substring(1)})
              </td>
              {getTotal(menge)}
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-bold uppercase text-gray-900">
                Ihre gewünschte Vertragsmenge
              </td>

              {createArrayWithNumbers("1").map((number, idx) => {
                return liefermengenInput(number, showTotal(menge));
              })}
            </tr>
          </tbody>
        </table>
        {handleWarning(liefermenge, showTotal(menge))}
      </div>
    </div>
  );
}
