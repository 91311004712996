import axios from "axios";

// get second profile if there is a second grower for this account
export const getSecondGrower = (id) => async (dispatch) => {
  const res = await axios.get(`/api/accounts/grower/${id}`);
  dispatch({ type: "GET_SECOND_GROWER", payload: res.data });
};

// Impersonate User
export const changeGrower = (value, history) => async (dispatch) => {
  axios
    .post(`/api/accounts/grower/${value}`)
    .then(
      (res) =>
        dispatch({ type: "CHANGE_AREA", payload: res.data }) &&
        history.push("/dashboard")
    )
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

// ADD A NEW USER
export const addKonto = (values, history) => async (dispatch) => {
  axios
    .post(`/api/konto/`, values)
    .then((res) => dispatch(getKonten()))
    .catch((err) =>
      dispatch({
        type: "GET_ERRORS",
        payload: err.data,
      })
    );
};

// GET ALL ALPHAS
export const updateKonto = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/konto/${id}`, values);
  dispatch({ type: "GET_ADMIN_KONTO", payload: res.data });
  history.push("/admin/konten");
};

// GET ALL KONTOS
export const getKonten = (api, action) => async (dispatch) => {
  const res = await axios.get("/api/konto");
  dispatch({ type: "GET_ADMIN_KONTEN", payload: res.data });
};
