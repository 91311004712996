import useSWR from "swr";
import fetcher from "../lib/fetcher";
import { useDispatch } from "react-redux";

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import Table from "../components/Table/Table";
import { UserCircleIcon } from "@heroicons/react/outline";
import Error from "../components/Error";

import { changeGrower } from "../actions/kontoActions";
import useAuth from "../hooks/useAuth";

export default function Grower(props) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { data, error } = useSWR(
    `/api/accounts/grower/${auth.user.growerId}`,
    fetcher
  );

  const changeBusiness = (growerId) => {
    dispatch(changeGrower(growerId, props.history));
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "BLE-No.",
      accessor: "growerNumber",
    },
    {
      Header: "Anbaugebiet",
      accessor: "anbaugebiet",
    },

    {
      accessor: "growerId",
      Cell: (props) => (
        <button
          className="table-control"
          onClick={() => changeBusiness(props.value)}
          data-tooltip="Betrieb wechseln"
        >
          <UserCircleIcon
            className="h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </button>
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout>
      <Table
        title="Betrieb auswählen"
        data={data.data}
        columns={columns}
        search={true}
      />
    </Layout>
  );
}
