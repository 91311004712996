import { useFormContext } from "react-hook-form";

export default function TextArea({ label, name, type, placeholder }) {
  const { register } = useFormContext(); // retrieve all hook methods

  return (
    <div className="mt-2">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>

      <textarea
        {...register(name)}
        type={type ? type : "text"}
        className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder={placeholder}
      />
    </div>
  );
}
