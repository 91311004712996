import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { useState } from "react";

import { CheckIcon, XIcon, PencilIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import EinkaufForm from "../../components/Forms/EinkaufForm";
import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

const columns = [
  {
    Header: "ID",
    accessor: "salesmanId",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Telefon",
    accessor: "phoneNumber",
  },
  {
    Header: "Fax",
    accessor: "faxNumber",
  },
  {
    Header: "Mobil",
    accessor: "cellNumber",
  },
  {
    Header: "E-Mail Adresse",
    accessor: "email",
  },

  {
    Header: "Einkäufer",
    accessor: "isPurchaser",
    Cell: (props) =>
      props.value ? (
        <CheckIcon className="block h-6 w-6 text-primary" aria-hidden="true" />
      ) : (
        <XIcon className="block h-6 w-6 text-red-500" aria-hidden="true" />
      ),
  },
  {
    Header: "Account",
    accessor: "account.lastName",
  },
  {
    accessor: "edit",
    Cell: (props) => (
      <Link to={`/admin/einkauf/${props.cell.row.original.salesmanId}`}>
        <PencilIcon
          className="block h-6 w-6 hover:text-primary"
          aria-hidden="true"
        />
      </Link>
    ),
  },
];

export default function Einkauf({ salesman, konten }) {
  const [edit, setEdit] = useState(false);
  const { data, error } = useSWR("/api/salesman", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout>
      {edit ? (
        <EinkaufForm defaultValues={""} konten={konten} edit={edit} />
      ) : (
        <Table
          title="Einkauf"
          button={() => setEdit(true)}
          buttonLabel="Einkäufer hinzufügen"
          data={data.data}
          columns={columns}
          search={true}
        />
      )}
    </AdminLayout>
  );
}
