export function getQualityValues(param) {
  let columns = {};
  switch (param) {
    case "alpha":
      return (columns = {
        headline: "Alpha-Gehalt (%)",
        label1: "% Alpha Durchschnitt eigener Betrieb",
        value1: "alpha",
        label2: "% Alpha Durchschnitt Anbaugebiet",
        value2: "alphaContent",
        case: "alphaContentSourceId",
      });
    case "wasser":
      return (columns = {
        headline: "Wassergehalt (%)",
        label1: "% Wasser Durchschnitt eigener Betrieb",
        value1: "wasser",
        label2: "% Wasser Durchschnitt Anbaugebiet",
        value2: "humidity",
        case: "humiditySourceId",
      });
    case "stengel":
      return (columns = {
        headline: "Blatt- / Stengelanteil (%)",
        label1: "% Blatt / Stengel Durchschnitt eigener Betrieb",
        value1: "stengel",
        label2: "% Blatt / Stengel Durchschnitt Anbaugebiet",
        value2: "leafStemPercent",
        case: "leafStemPercentSourceId",
      });
    case "abfall":
      return (columns = {
        headline: "Abfallanteil (%)",
        label1: "% Abfall Durchschnitt eigener Betrieb",
        value1: "abfall",
        label2: "% Abfall Durchschnitt Anbaugebiet",
        value2: "wastePercent",
        case: "wastePercentSourceId",
      });
    case "dolden":
      return (columns = {
        headline: "Doldenblattanteil (%)",
        label1: "% Doldenblätter Durchschnitt eigener Betrieb",
        value1: "dolden",
        label2: "% Doldenblätter Durchschnitt Anbaugebiet",
        value2: "percentLeaves",
        case: "percentLeavesSourceId",
      });
    case "bonität":
      return (columns = {
        headline: "Bonität (%)",
        label1: "Bonität Durchschnitt eigener Betrieb",
        value1: "bonität",
        label2: "Bonität Durchschnitt Anbaugebiet",
        value2: "rating",
        case: "ratingSourceId",
      });
    case "saldo":
      return (columns = {
        headline: "Saldo (%)",
        label1: "Saldo Durchschnitt eigener Betrieb",
        value1: "saldo",
        label2: "Saldo Durchschnitt Anbaugebiet",
        value2: "balance",
        case: "balanceSourceId",
      });

    default:
      return columns;
  }
}
