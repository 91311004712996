import { checkSum } from "./checkSum";
import { isEmpty } from "./isEmpty";

export function getPrices(angebot, duration) {
  // Filter Liste nach Variante und Sorte
  const liste = angebot.preisliste.filter(
    (preis) =>
      preis.Variante === angebot.Variante && preis.Sorte === angebot.Sorte
  );

  const raw = checkSum(liste, `Preis kg`);

  const fixpreis = liste.filter((preis) => {
    return preis.Erntejahr === 0;
  });

  let prices = [];

  for (let i = 0; i < duration.length; i++) {
    let index = i;
    let result = liste.filter((preis) => preis.Erntejahr === duration[index]);

    const fixpreisROH = fixpreis[0] ? fixpreis[0]["Preis kg"] : 0;
    const fixpreisAlpha = fixpreis[0] ? fixpreis[0]["Preis kg Alpha"] : 0;

    isEmpty(result)
      ? prices.push(raw ? fixpreisROH : fixpreisAlpha)
      : prices.push(raw ? result[0]["Preis kg"] : result[0]["Preis kg Alpha"]);
  }

  return prices;
}
