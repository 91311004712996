export function formatArea(code) {
  switch (code) {
    case "Tettnang":
      return "DET";
    case "Hallertau":
      return "DEH";
    case "Spalt":
      return "DES";
    case "Elbe / Saale":
      return "DEE";
    default:
      return "";
  }
}
