export default function Subnavigation({ subnavigation, subtitle }) {
  return (
    subnavigation && (
      <div className="pt-10 pb-10">
        {subtitle && (
          <p
            className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
            id="subnavigation-headline"
          >
            {subtitle}
          </p>
        )}
        <div
          className="mt-3 space-y-2"
          aria-labelledby="subnavigation-headline"
        >
          {subnavigation.map((sublink) => (
            <a
              key={sublink.name}
              href={sublink.href}
              target="_blank"
              rel="noreferrer"
              className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
            >
              <span className="truncate">{sublink.name}</span>
            </a>
          ))}
        </div>
      </div>
    )
  );
}
