import useSWR, { mutate } from "swr";
import fetcher from "../../lib/fetcher";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import Input from "../Input/Input";
import Select from "../Input/Select";
import Spinner from "../Spinner";

import { addEstimate, updateEstimate } from "../../actions/adminActions";

export default function SchätzungenForm(props) {
  const { defaultValues, edit } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { data, error } = useSWR("/api/admin/grower", fetcher);
  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;

  const growerOptions = data.data.map((grower) => ({
    value: grower.growerId,
    label: grower.growerNumber + " - " + grower.name,
  }));

  const onSubmit = (data) => {
    edit
      ? dispatch(addEstimate(data, history))
      : dispatch(updateEstimate(props.match.params.id, data, history)) &&
        mutate(`/api/estimates/edit/${props.match.params.id}`);
  };

  const areaOptions = [
    { value: "DT", label: "Tettnang" },
    { value: "DH", label: "Hallertau" },
    { value: "DS", label: "Spalt" },
    { value: "DE", label: "Elbe / Saale" },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Schätzung hinzufügen" : "Schätzung bearbeiten"}
          </h3>
        </div>
        <div className="bg-white mb-3 px-4 py-5 border-b border-gray-200 sm:px-6">
          <Select
            label="Pflanzer"
            name="growerId"
            defaultValue={defaultValues.growerId}
            options={growerOptions}
          />
          <div className="mt-2">
            <Select
              label="Anbaugebiet"
              name="growingArea"
              defaultValue={defaultValues.growingArea}
              options={areaOptions}
            />
          </div>
          <Input label="Erntejahr" name="cropYear" />
          <Input label="Gesamtfläche (ha)" name="totalArea" />
          <Input label="Gesamtertrag (Ztr.)" name="totalYield" />
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/schätzungen"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
