import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet-defaulticon-compatibility";

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";

const style = {
  width: "100%",
  height: "600px",
  margin: "0 auto",
};

function Map({ coord, setCoord }) {
  // create map
  const mapRef = useRef(null);
  useEffect(() => {
    mapRef.current = L.map("map", {
      center: [coord.lat, coord.lon],
      zoom: 10,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
    // eslint-disable-next-line
  }, []);

  // add marker
  const markerRef = useRef(null);
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLatLng(coord);
    } else {
      markerRef.current = L.marker(coord).addTo(mapRef.current);
    }
  }, [coord]);

  useEffect(() => {
    const map = mapRef.current;

    if (!map) return;

    const control = new GeoSearchControl({
      searchLabel: "Adresse eingeben...",
      position: "topright",
      style: "bar",
      provider: new OpenStreetMapProvider({
        params: {
          "accept-language": "de",
          email: "mail@davebonhagen.com", // auth for large number of requests
        },
      }),
    });

    control.addTo(map);

    map.on("geosearch/showlocation", function (data) {
      setCoord({ lat: data.location.y, lon: data.location.x });
    });
    // eslint-disable-next-line
  }, []);

  return <div id="map" style={style} />;
}

export default Map;
