import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

export default function NotificationComponent(props) {
  const notification = useSelector((state) => state.notifications);

  function generateNotification(notification) {
    switch (notification.type) {
      case "SUCCESS":
        return toast.success(notification.message);
      case "INFO":
        return toast.info(notification.message);
      case "ERROR":
        return toast.error(notification.message);
      case "WARNING":
        return toast.warning(notification.message);
      default:
        return null;
    }
  }

  useEffect(() => {
    Object.keys(notification).map((notificationId) => {
      return generateNotification(notification[notificationId]);
    });
  }, [notification]);

  return <ToastContainer position={toast.POSITION.TOP_RIGHT} />;
}
