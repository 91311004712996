import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import UserForm from "../../components/Forms/UserForm";
import AdminLayout from "../../components/Layout/AdminLayout";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

export default function KontoEdit(props) {
  const { data, error } = useSWR(
    `/api/konto/${props.match.params.id}`,
    fetcher
  );

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const konto = data;

  const defaultValues = {
    id: konto.id,
    firstName: konto.firstName,
    lastName: konto.lastName,
    email: konto.email,
    phone: konto.phone,
    growerId: konto.growerId,
    role: konto.role,
  };

  return (
    <AdminLayout>
      <UserForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
