import {HomeIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";

import {formatContract} from "../../lib/formatContract";
import {getHopType} from "../../lib/getHopType";
import {sumFilter} from "../../lib/sumFilter";
import {formatAVHLV} from "../../lib/formatAVHLV";

import Filter from "./Filter";
import FilterBadge from "./FilterBadge";

import useSystem from "../../hooks/useSystem";
import {getDocument} from "../../actions/documentActions";
import {useDispatch} from "react-redux";

export default function FilterSidebar(props) {
  const {
    angebote,
    year,
    setYear,
    vertrag,
    setVertrag,
    type,
    setType,
    hop,
    setHop,
  } = props;

  const contracts = [...new Set(angebote.map((item) => item.Vertragsart))];
  const sorten = [...new Set(angebote.map((item) => item.hop.Beschreibung))];
  const startJahr = [...new Set(angebote.map((item) => item.Startjahr))];
  const art = [...new Set(angebote.map((item) => getHopType(item)))];
  const system = useSystem();
  const dispatch = useDispatch();

  const shopLinks = [
    { name: "Vertragsangebotsbestimmungen", id: 'offer' },
    { name: formatAVHLV(system.AVHLV), id: 'AVHLV' },
    { name: "Datenschutzerklärung", id: 'privacy' },
  ];

  return (
    <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
      <div className="pb-4 space-y-1">
        <Link
          className="group flex items-center px-8 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50"
          to="/dashboard"
        >
          <HomeIcon className="flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
          Startseite
        </Link>
      </div>
      <div className="p-4 space-y-1">
        {vertrag && (
          <FilterBadge
            type="vertrag"
            filter={vertrag}
            setFilter={() => setVertrag()}
          />
        )}
        {year && <FilterBadge filter={year} setFilter={() => setYear()} />}
        {hop && <FilterBadge filter={hop} setFilter={() => setHop()} />}
        {type && <FilterBadge filter={type} setFilter={() => setType()} />}

        <Filter title="Vertragsart">
          {contracts.map((contract, idx) => {
            return (
              <li
                key={idx}
                className="cursor-pointer group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                onClick={() =>
                  setVertrag({
                    label: formatContract(contract),
                    value: contract,
                  })
                }
              >
                {formatContract(contract)}
                {" (" + angebote.filter((angebot) => angebot.Vertragsart === contract).length + ")"}
              </li>
            );
          })}
        </Filter>
        <Filter title="Lieferart">
          {art.map((art, idx) => {
            return (
              <li
                key={idx}
                className="cursor-pointer group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                onClick={() => setType(art)}
              >
                {art}{" "}
                {"(" +
                  angebote.filter((angebot) => getHopType(angebot) === art)
                    .length +
                  ")"}
              </li>
            );
          })}
        </Filter>
        <Filter title="Vertragsbeginn">
          {startJahr.sort().map((jahr, idx) => {
            const label = jahr === 0 ? system.cropYear : jahr;
            return (
              <li
                key={idx}
                className="cursor-pointer group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                onClick={() => setYear({ label: label, value: jahr })}
              >
                {jahr === 0
                  ? "Erntejahr " +
                  system.cropYear +
                  " (" +
                  angebote.filter((angebot) => angebot.Startjahr === 0)
                    .length +
                  ")"
                  : "Ab " + jahr + " " + sumFilter(angebote, jahr, "Startjahr")}
              </li>
            );
          })}
        </Filter>
        <Filter title="Sorte">
          {sorten.map((sorte, idx) => {
            return (
              <li
                key={idx}
                className="cursor-pointer group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                onClick={() => setHop(sorte)}
              >
                {sorte}{" "}
                {"(" +
                  angebote.filter(
                    (angebot) => angebot.hop.Beschreibung === sorte
                  ).length +
                  ")"}
              </li>
            );
          })}
        </Filter>
      </div>
      <div className="mt-3 space-y-2">
        <div className="pt-10 pb-10">
          {shopLinks.map((link, idx) => (
            <button
              key={idx}
              className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
              onClick={() => dispatch(getDocument('SYS', link.id))}
            >
              {link.name}
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
}
