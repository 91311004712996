import Card from "../Shop/Card";

export default function Bezahlung({
  register,
  errors,
  type,
  check,
  raw,
  data,
}) {
  return (
    <Card
      title={
        check ? "V. Bezahlung des Kaufpreises" : "Bezahlung des Kaufpreises"
      }
    >
      {type === " kg Rohhopfen" || raw ? (
        <p>
          Vom Käufer wird der Gesamtkaufpreis (= Grundpreis +/- Zu- und
          Abschläge netto) jeweils nach Gefahrübergang (Ziffer IV. 4. AVHLV)
          bezahlt. Die Abrechnung und Fälligkeit bestimmt sich alternativ nach
          den nachstehenden vom Verkäufer zu wählenden Bedingungen:
        </p>
      ) : (
        <p>
          Vom Käufer wird der Gesamtkaufpreis jeweils nach Gefahrübergang
          (Ziffer IV. 4. AVHLV) bezahlt. Die Abrechnung und Fälligkeit bestimmt
          sich alternativ nach den nachstehenden vom Verkäufer zu wählenden
          Bedingungen:
        </p>
      )}
      <br />

      <div className="relative p-2 cursor-pointer focus:outline-none">
        <label>
          {check ? (
            <input
              className="text-primary"
              name="bezahlung"
              type="radio"
              value={true}
              checked={data.bezahlung ? true : false}
              readOnly={true}
            />
          ) : (
            <input
              className="text-primary"
              name="bezahlung"
              type="radio"
              value={true}
              ref={register({ required: true })}
            />
          )}
          <span className="ml-4">
            <b>
              Abrechnung und Auszahlung des Gesamtkaufpreises innerhalb 60 Tagen
              nach Gefahrübergang
            </b>
            <br /> Der Käufer nimmt spätestens binnen 60 Tagen nach
            Gefahrübergang die Gesamtabrechnung und Auszahlung des
            Gesamtkaufpreises an den Verkäufer vor.
          </span>
        </label>
      </div>
      <div className="relative p-2 cursor-pointer focus:outline-none">
        <label>
          {check ? (
            <input
              className="text-primary"
              name="bezahlung"
              type="radio"
              value={false}
              checked={data.bezahlung === "false" ? true : false}
              readOnly={true}
            />
          ) : (
            <input
              className="text-primary"
              name="bezahlung"
              type="radio"
              value={false}
              ref={register({ required: true })}
            />
          )}

          <span className="ml-4">
            <b>
              Abrechnung und Auszahlung des Gesamtkaufpreises innerhalb 30 Tagen
              nach Gefahrübergang
            </b>

            <ol>
              <li className="p-2">
                a) Der Käufer nimmt spätestens binnen 30 Tagen nach
                Gefahrübergang die Gesamtabrechnung und Auszahlung des
                Gesamtkaufpreises an den Verkäufer vor.
              </li>
              <li className="p-2">
                b) Der Verkäufer stellt dem Käufer einen Betrag in Höhe von 40 %
                des Gesamtkaufpreises zur Verfügung, welchen der Käufer
                spätestens bis zum 28.2. des der Ernte folgenden Kalenderjahres
                an den Verkäufer zurückzahlt.
              </li>
              <li className="p-2">
                c) Der Käufer wird dem Verkäufer den unter V. b) genannten
                Betrag bis zur vollständigen Rückzahlung mit 2 %-Punkten über
                dem Basiszinssatz ab der Fälligkeit der Gesamtkaufpreiszahlung
                nach Ziffer V. a) verzinsen. Eine Änderung des Basiszinssatzes
                während der Zeit der Verzinsung bleibt dabei unberücksichtigt.
              </li>
              <li className="p-2">
                d) Der Käufer behält den unter V.b) genannten Betrag in Höhe von
                40 % des Gesamtkaufpreises im Rahmen der Auszahlung des
                Kaufpreises nach Ziffer V.a) ein und zahlt den Betrag zuzüglich
                Zinsen entsprechend Ziffer V.c) bis spätestens 28.2. des der
                Ernte folgenden Kalenderjahres an den Verkäufer zurück.
              </li>
            </ol>
          </span>
          <p className="mt-2  text-red-600" id="alphapreis-error">
            {errors &&
              errors.bezahlung &&
              "Bitte eine Variante für die Bezahlung des Kaufpreises wählen"}
          </p>
        </label>
      </div>
    </Card>
  );
}
