import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../actions/authActions";

import hop from "../img/hop.png";

export default function Forgot(props) {
  const { register, handleSubmit, errors } = useForm();
  const error = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const onSubmit = (data) => dispatch(forgotPassword(data, props.history));

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          src={hop}
          className="mx-auto h-12 w-auto"
          alt="Hopsteiner Pflanzerportal"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Passwort zurücksetzen
        </h2>
        <p className="mt-4 text-sm text-gray-600">
          Bitte geben Sie Ihre E-Mail Adresse ein und klicken Sie auf den Button
          &quot;Zurücksetzen&quot;. Sie erhalten dann ein E-Mail mit einem Link,
          der es Ihnen erlaubt, Ihr Passwort zurückzusetzen.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-Mail Adresse
              </label>
              <div className="mt-1">
                <input
                  {...register("email", { required: "Bitte E-Mail angeben" })}
                  type="email"
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="mengen-error">
                  {errors  && errors.email.message}
                  {error && error.email }
                </p>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Zurücksetzen
              </button>
              <div className="mt-2 w-full flex justify-center py-2 px-4  rounded-md text-sm font-medium text-primary bg-white hover:text-secondary bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
                <Link to="/login">Zurück zum Login</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
