import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import Input from "../Input/Input";
import Textarea from "../Input/TextArea";
import SwitchInput from "../Input/SwitchInput";
import FileInput from "../Input/FileInput";

import { addNews, updateNews } from "../../actions/adminActions";

export default function NewsForm({ defaultValues, edit, match, mutate }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (data.file) {
      formData.append("file", data.file[0]);
    }

    formData.append("date", data.date);
    formData.append("message", data.message);
    formData.append("link", data.link);
    formData.append("show", data.show);

    edit
      ? dispatch(addNews(formData, history))
      : dispatch(updateNews(match.params.id, formData, history));

    mutate();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Neuigkeit hinzufügen" : "Neuigkeit bearbeiten"}
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <Input type="date" label="Datum" name="date" />
          <Input type="text" label="Link" name="link" />
          <Textarea name="message" label="Nachricht" />
          <div className="py-4">
            <FileInput
              file={defaultValues && defaultValues.file}
              name="file"
              accept="image/*, application/pdf"
            />
          </div>
          <div className="mt-4">
            <SwitchInput name="show" option="Im Newsticker anzeigen" />
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/neuigkeiten"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
