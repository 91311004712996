import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import { useState } from "react";
import { deleteForm } from "../../actions/adminActions";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import AdminLayout from "../../components/Layout/AdminLayout";
import FormularForm from "../../components/Forms/FormularForm";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import Error from "../../components/Error";

import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import { date } from "../../lib/date";

export default function Formulare(props) {
  const [edit, setEdit] = useState(false);
  const { data, error, mutate } = useSWR("/api/forms", fetcher);
  const dispatch = useDispatch();
  const deleteClick = (id) => {
    dispatch(deleteForm(id, props.history));
    mutate();
  };

  const columns = [
    {
      Header: "Beschreibung",
      accessor: "description",
    },
    {
      Header: "Hochgeladen am",
      accessor: "files.dateUploaded",
      Cell: (props) => date(props.value),
    },
    {
      accessor: "formId",
      Cell: (props) => (
        <Link className="table-control" to={`/admin/formulare/${props.value}`}>
          <PencilIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </Link>
      ),
    },
    {
      accessor: "delete",
      Cell: (props) => (
        <Modal
          icon={
            <TrashIcon
              className="block h-6 w-6 hover:text-primary"
              aria-hidden="true"
            />
          }
          modalAction={() => deleteClick(props.cell.row.original.formId)}
          modalText="Formular wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <AdminLayout>
      {edit ? (
        <FormularForm defaultValues={""} edit={edit} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Formular hinzufügen"
          title="Formulare"
          data={data.data}
          columns={columns}
        />
      )}
    </AdminLayout>
  );
}
