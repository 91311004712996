import {useState} from "react";
import {PlusIcon, MinusIcon} from "@heroicons/react/solid";

import {calcAmount} from "../../lib/calcAmount";
import {showRange} from "../../lib/showRange";
import {createArrayWithNumbers} from "../../lib/createArrayWithNumbers";
import {lieferungenInput} from "../../lib/lieferungenInput";

import {companies} from "../../utils/companies";

export default function Liefermenge({ register, watch, errors, startUvm, type }) {
  const verpflichtung = watch("verpflichtung");
  const firma = watch("firma");
  const menge = watch("menge");
  const beginn = watch("beginn");
  const ende = watch("ende");
  const duration = ende - beginn + 1;
  const length = duration < 0 ? 1 : duration;
  const uvm = startUvm;
  const [size, setSize] = useState(menge ? menge.length : 1);

  const years = showRange(beginn, duration - 1);

  let arr = new Array(Number(length) || 0).fill(uvm);

  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Sonstige Lieferverpflichtungen der Vertragssorte in {type}
        </h3>
      </div>
      <div className="border-t border-primary " />
      <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
        <label>
          <input
            className="text-primary"
            defaultChecked={true}
            name="verpflichtung"
            type="radio"
            value="false"
            ref={register({ required: true })}
          />
          <span className="ml-4 font-medium">
            Keine sonstigen Lieferverpflichtungen
          </span>
        </label>
      </div>
      <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
        <label>
          <input
            className="text-primary"
            name="verpflichtung"
            type="radio"
            value="true"
            ref={register({ required: true })}
          />
          <span className="ml-4 font-medium">
            Sonstige Lieferverpflichtungen
          </span>
        </label>
      </div>
      {verpflichtung === "true" ? (
        <table className="mt-6 min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
          <tr>
            <th scope="col">
              <span className="sr-only">Verpflichtung</span>
            </th>
            {years.map((year, idx) => (
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                key={idx}
              >
                {year}
              </th>
            ))}
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {createArrayWithNumbers(size).map((number) => {
            return (
              <tr key={number}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-bold uppercase text-gray-900">
                  Verpflichtung #{number + 1}
                </td>
                {lieferungenInput(arr, register, number, errors)}
                <td>
                  <select
                    className="focus:ring-primary focus:border-primary block w-full  sm:text-sm border-gray-300 rounded-md"
                    ref={register({ required: true })}
                    name={`firma[${number}]`}
                    placeholder="Firma"
                  >
                    {companies.map((company, idx) => (
                      <option key={idx} value={company.value}>
                        {company.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {firma && firma[number] === "Andere Firma" && (
                    <div>
                      <input
                        type="text"
                        className="focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        ref={register({ required: true })}
                        name={`other[${number}]`}
                        placeholder="Andere Firma"
                      />
                      {errors.other && errors.other[number] && (
                        <p className="mt-2 text-sm text-red-600">
                          {errors.other[number]?.type === "required" &&
                            "Bitte ausfüllen"}
                        </p>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
              <span className="font-bold uppercase">Gesamt:</span>
            </td>
            {calcAmount(arr, menge, size)}
          </tr>
          <tr>
            <td className="uppercase px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    onClick={() => setSize(size < 3 ? size + 1 : 3)}
                    type="button"
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                  >
                    <span className="sr-only">Hinzufügen</span>
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={() => setSize(size > 1 ? size - 1 : 1)}
                    type="button"
                    className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                  >
                    <span className="sr-only">Entfernen</span>
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  );
}
