import { useEffect } from "react";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import SchätzungenForm from "../../components/Forms/SchätzungenForm";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

import { formatArea } from "../../lib/formatArea";
import { formatEstimate } from "../../lib/formatEstimate";

export default function EditEstimate(props) {
  const { data, error, mutate } = useSWR(
    `/api/estimates/edit/${props.match.params.id}`,
    fetcher
  );

  useEffect(() => {
    mutate();
  }, [data, mutate]);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const estimate = data;

  const defaultValues = {
    growerId: estimate.data.growerId,
    cropYear: estimate.data.cropYear,
    totalArea: formatEstimate(estimate.data.totalArea),
    totalYield: estimate.data.totalYield,
    growingArea: formatArea(estimate.data.growingArea),
  };

  return (
    <AdminLayout>
      <SchätzungenForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
