// Convert to German currency
export function currency(number) {
  return (
    number
      .toFixed(2)
      .toString()
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
  );
}
