import { useForm, FormProvider } from "react-hook-form";
import useSWR from "swr";
import { useDispatch } from "react-redux";

import fetcher from "../../lib/fetcher";

import Input from "../Input/Input";
import Select from "../Input/Select";
import Spinner from "../Spinner";

import { addKonto, updateKonto } from "../../actions/kontoActions";

export default function KontoForm({ defaultValues, edit, history, match }) {
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const { data, error } = useSWR("/api/admin/grower", fetcher);
  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;

  const growerOptions = data.data.map((grower) => ({
    value: grower.growerId,
    label: grower.name,
  }));

  const onSubmit = (data) => {
    edit
      ? dispatch(addKonto(data, history))
      : dispatch(updateKonto(match.params.id, data, history));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Konto hinzufügen" : "Konto bearbeiten"}
          </h3>
        </div>
        <div className="bg-white mb-3 px-4 py-5 border-b border-gray-200 sm:px-6">
          <Input label="Vorname" name="firstName" />
          <Input label="Nachname" name="lastName" />
          <Input label="Passwort" name="newPassword" />
          <Input type="email" label="E-Mail" name="email" />
          <div className="mt-4">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  aria-describedby="forceLogin"
                  {...methods.register("forceLogin")}
                  type="checkbox"
                  className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <span id="offers-description" className="text-gray-500">
                  Passwort ändern beim nächsten Login?
                </span>
              </div>
            </div>
          </div>
          <br />
          <h3 className="mb-6 border-b border-primary uppercase font-display">
            Pflanzer
          </h3>
          <Select
            label="Pflanzer"
            name="growerId"
            defaultValue={defaultValues.growerId}
            options={growerOptions}
          />
          <div className="pt-3" />

          <Select
            label="Zugriff"
            name="role"
            defaultValue={defaultValues.role}
            options={[
              { value: "user", label: "Benutzer" },
              { value: "admin", label: "Admin" },
              { value: "salesman", label: "Einkauf" },
            ]}
          />
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/konten"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
