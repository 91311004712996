import { isEmpty } from "../lib/isEmpty";

const initalState = {
  isAuthenticated: false,
  user: {},
};

export default function authReducer(state = initalState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };

    case "CLEAR_CURRENT_USER":
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
}
