export default function HopImage({ src }) {
  const path = src ? "/public/hops/" + src.replace(/\s+/g, "") + ".png" : null;
  const url = (process.env.REACT_APP_API || 'https://api.pflanzerportal.hopsteiner.de') + path;

  return (
    <picture>
      <source type="image/jpg" srcSet={url} />
      <img className="h-18 w-16 rounded-full" alt={src} src={url} />
    </picture>
  );
}
