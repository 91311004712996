const initalState = {
  angebote: [],
  angebot: {},
  contracts: [],
  hop: {},
  uvm: [],
  loading: false,
};

export default function shopReducer(state = initalState, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_ANGEBOTE":
      return {
        ...state,
        angebote: action.payload.data,
        loading: false,
      };
    case "GET_ANGEBOT":
      return {
        ...state,
        angebot: action.payload.data,
        loading: false,
      };

    case "GET_OFFERS":
      return {
        ...state,
        contracts: action.payload.data,
        loading: false,
      };

    case "GET_HOP":
      return {
        ...state,
        hop: action.payload.data,
        loading: false,
      };

    case "GET_UVM":
      return {
        ...state,
        uvm: action.payload.data,
        loading: false,
      };

    default:
      return state;
  }
}
