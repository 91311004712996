/* This example requires Tailwind CSS v2.0+ */
import { useFormContext, Controller } from "react-hook-form";
import { Switch } from "@headlessui/react";

import { classNames } from "../../lib/classNames";

export default function SwitchInput({ name, option, option2, required }) {
  const methods = useFormContext();

  return (
    <div>
      <Controller
        control={methods.control}
        name={name}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <Switch.Group as="div" className="flex items-center">
            <Switch.Label as="span" className="mr-3">
              <span className="text-sm font-medium uppercase text-gray-400">
                {option2}
              </span>
            </Switch.Label>
            <Switch
              checked={value}
              onChange={onChange}
              className={classNames(
                value ? "bg-primary" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  value ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium uppercase text-gray-400">
                {option}
              </span>
            </Switch.Label>
          </Switch.Group>
        )}
      />
      {methods.errors && "Bitte ausfüllen"}
    </div>
  );
}
