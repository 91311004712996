// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

export default function BarChart({ data }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 mt-4 mb-4">
      <div className="px-0 py-5 sm:p-6 z-0">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}
