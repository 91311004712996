import useSWR from "swr";
import fetcher from "../lib/fetcher";
import queryString from "query-string";

import { Link } from "react-router-dom";

import AngebotLayout from "../components/Layout/AngebotLayout";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import Vorvertrag from "../components/Vorvertrag";
import Freihopfen from "../components/Freihopfen";
import Poolhopfen from "../components/Poolhopfen";

export default function Angebot(props) {
  const params = queryString.parse(props.location.search);

  const { data, error } = useSWR(
    `/api/contracts/offer/${params.vertragsart}/${params.jahr}/${params.sorte}/${params.variante}`,
    fetcher
  );

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <AngebotLayout>
      <Link
        className="mb-6 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        to="/angebote"
      >
        Zurück
      </Link>
      {data.Vertragsart === 0 && <Vorvertrag {...data} />}
      {data.Vertragsart === 1 && <Freihopfen {...data} />}
      {data.Vertragsart === 3 && <Poolhopfen {...data} />}
    </AngebotLayout>
  );
}
