import {currency} from "./currency";
import {getHopType} from "./getHopType";
import {getPriceList} from "./getPriceList";

export function getFixedPrice(offer) {
  const type = getHopType(offer);
  const priceList = getPriceList(offer);

  const filteredPrice =
    type === " kg Rohhopfen"
      ? priceList.filter((variety) => variety["Preis kg"] > 0)
      : priceList.filter((variety) => variety["Preis kg Alpha"] > 0);

  return filteredPrice[0] ? currency(
    type === " kg Rohhopfen"
      ? filteredPrice[0]["Preis kg"]
      : filteredPrice[0]["Preis kg Alpha"]
  ) : '';

}
