import useSWR from "swr";
import fetcher from "../lib/fetcher";

export default function useSystem() {
  const { data, error } = useSWR("/api/infos/system", fetcher);

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";
  return data;
}
