import axios from "axios";
import { showNotification } from "../actions/notificationActions";
import { store } from "../store";

axios.interceptors.response.use(
  function (response) {
    store.dispatch(showNotification(response.data));
    return response;
  },
  function (error) {
    if (error.response.data.status === 401) {
      window.location.href = "/login";
    } else {
      store.dispatch(showNotification(error.response.data));
      return Promise.reject(error.response);
    }
  }
);
