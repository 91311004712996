import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Spinner from "../components/Spinner";
import Error from "../components/Error";
import Card from "../components/Shop/Card";
import Layout from "../components/Layout/Layout";

function SalesmanCard() {
  const { data, error } = useSWR("/api/infos/sales", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const { salesman } = data;
  const url = process.env.REACT_APP_API || 'https://api.pflanzerportal.hopsteiner.de';

  return (
    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <div className="flex items-center">
            <div className=" flex-shrink-0">
              <picture>
                <source
                  type="image/jpg"
                  srcSet={`${url}/public/salesman/${salesman.attachmentFileId}.jpg`}
                />
                <img
                  className="mx-auto h-36 w-36 rounded-full xl:w-56 xl:h-56"
                  src={`${url}/public/salesman/${salesman.attachmentFileId}.jpg`}
                  alt={salesman.name}
                />
              </picture>
            </div>
            <div className="ml-12">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {salesman.name}
              </h3>
              <p className="text-sm text-gray-500">Einkäufer</p>
              <p>
                <b></b>
                <br />
                <br />
                <b>Telefon:</b>
                {" " + salesman.phoneNumber} <br />
                <b>Fax:</b> {" " + salesman.faxNumber} <br />
                <b>Mobil:</b>
                {" " + salesman.cellNumber} <br />
                <b>E-Mail:</b>{" "}
                <a
                  className="hover:text-primary"
                  href={`mailto:${salesman.email}`}
                >
                  {salesman.email}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function KontaktEinkauf() {
  const { data, error } = useSWR("/api/infos/kontakt", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const kontakt = data;

  return (
    <Layout>
      <Card title="Kontaktieren Sie uns">
        <SalesmanCard kontakt={kontakt} />

        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          <div className="mt-16 ml-16 sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Einkaufsbüro Mainburg
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <p>
                <b>{kontakt.companyName}</b>
                <br />
                {kontakt.address1}, {kontakt.zipCode} {kontakt.city}
                <br /> <br /> <br />
                <b>Telefon:</b> {kontakt.phone}
                <br />
                <b>Fax:</b> {kontakt.fax}
                <br />
                <b>E-Mail:</b>{" "}
                <a
                  className="hover:text-primary"
                  href={`mailto:${kontakt.email}`}
                >
                  {kontakt.email}
                </a>
              </p>
            </dd>
          </div>
          <div className="m-16 ml-16 sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Einkaufsbüro Tettnang
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <p>
                <b>{kontakt.secondOfficeDescription}</b> <br />
                {kontakt.secondOffice1}
                <br />
                {kontakt.secondOffice2 + ", " + kontakt.secondOffice3}
                <br />
                <br /> <b>Telefon:</b> {kontakt.secondOffice4}
                <br /> <b>Fax:</b> {kontakt.secondOffice5}
                <br />
                <b>E-Mail:</b>{" "}
                <a
                  className="hover:text-primary"
                  href={`mailto:${kontakt.secondOffice6}`}
                >
                  {kontakt.secondOffice6}
                </a>
              </p>
            </dd>
          </div>
        </dl>
      </Card>
    </Layout>
  );
}
