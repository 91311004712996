import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateSystem } from "../../actions/adminActions";

export default function SystemForm({ defaultValues }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { register, control,  handleSubmit } = useForm({ defaultValues: defaultValues });
  const onSubmit = (values) => dispatch(updateSystem(values, history));

  return (
    <FormProvider control={control}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  System
                </h3>
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <div className="mt-6 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Firmenname
                    </label>
                    <input
                      {...register("companyName")}
                      type="text"
                      id="companyName"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="address1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adresse
                    </label>
                    <input
                      type="text"
                      {...register("address1")}
                      id="address1"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="address2"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adresse 2
                    </label>
                    <input
                      type="text"
                      {...register("address2")}
                      id="address2"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Stadt
                    </label>
                    <input
                      {...register("city")}
                      type="text"
                      id="city"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bundesland
                    </label>
                    <input
                      type="text"
                      {...register("state")}
                      id="state"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2 lg:col-span-1">
                    <label
                      htmlFor="zipCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      PLZ
                    </label>
                    <input
                      type="text"
                      {...register("zipCode")}
                      id="zipCode"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-2 lg:col-span-1">
                    <label
                      htmlFor="countryCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Land
                    </label>
                    <input
                      type="text"
                      {...register("countryCode")}
                      id="countryCode"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 mt-6">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telefon
                    </label>
                    <input
                      {...register("phone")}
                      type="text"
                      id="phone"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="fax"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Fax
                    </label>
                    <input
                      {...register("fax")}
                      type="text"
                      id="fax"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 mb-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Mail
                    </label>
                    <input
                      {...register("email")}
                      type="email"
                      id="email"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Weiteres Einkaufsbüro
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="secondOfficeDescription"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Überschrift weiteres Einkaufsbüro
                    </label>

                    <input
                      type="text"
                      {...register("secondOfficeDescription")}
                      id="secondOfficeDescription"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="secondOffice1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adresse
                    </label>
                    <input
                      type="text"
                      {...register("secondOffice1")}
                      id="secondOffice1"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <input
                      type="text"
                      {...register("secondOffice2")}
                      id="secondOffice2"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />

                    <input
                      type="text"
                      {...register("secondOffice3")}
                      id="secondOffice3"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="secondOffice5"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telefon
                    </label>
                    <input
                      type="text"
                      {...register("secondOffice4")}
                      id="secondOffice4"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="secondOffice5"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Fax
                    </label>
                    <input
                      type="text"
                      {...register("secondOffice5")}
                      id="secondOffice5"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 mb-3">
                    <label
                      htmlFor="secondOffice6"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Mail
                    </label>
                    <input
                      type="text"
                      {...register("secondOffice6")}
                      id="secondOffice6"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Weitere Informationen
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label
                      htmlFor="cropYear"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Erntejahr
                    </label>
                    <input
                      {...register("cropYear")}
                      type="text"
                      id="cropYear"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 mb-3">
                    <label
                      htmlFor="cropYearForEstimates"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Erntejahr für Flächenschätzung
                    </label>
                    <input
                      type="text"
                      {...register("cropYearForEstimates")}
                      id="cropYearForEstimates"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 mb-3">
                    <label
                      htmlFor="cropYearForEstimates"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Änderungsemail Pflanzer
                    </label>
                    <input
                      type="text"
                      {...register("growerChangeEmails")}
                      id="cropYearForEstimates"
                      className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="mt-8 mb-4 relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      {...register("allowGrowerAreaEstimate")}
                      type="checkbox"
                      className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <span id="candidates-description" className="text-gray-500">
                      Flächenschätzung aktivieren{" "}
                    </span>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      {...register("allowGrowerCropEstimate")}
                      type="checkbox"
                      className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <span id="candidates-description" className="text-gray-500">
                      Ernteschätzung aktivieren
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/admin"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Abbrechen
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
