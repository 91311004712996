// filter for duplicate documents with same key but different format (e.g. *.jpg & .tif)
import {isEmpty} from "./isEmpty";

export default function filterDocumentsForSmbDuplicates(arr) {
  const uniqueObjects = [];
  const duplicateObjects = {};

  if (arr.length > 0) {
    // Iterate through the array
    arr.forEach(obj => {
      if (!isEmpty(obj)) {
        if (obj.prefix !== 'SMB') {
          // Keep normal objects as is
          uniqueObjects.push(obj);
        } else {
          // Handle duplicate objects
          const keyValue = obj['prefix'];
          if (!duplicateObjects[keyValue] || duplicateObjects[keyValue]['created_at'] < obj['created_at']) {
            duplicateObjects[keyValue] = obj;
          }
        }
      }
    });
  }

  // Combine normal objects and the latest duplicates
  return [...uniqueObjects, ...Object.values(duplicateObjects)];
}
