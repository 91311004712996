import useSystem from "../../hooks/useSystem";
import { formatAVHLV } from "../../lib/formatAVHLV";

export default function Vertragsbestandteile() {
  const system = useSystem();

  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          I. Vertragsbestandteile
        </h3>
      </div>
      <hr />
      <div className="border-t border-primary px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dd className="mt-1 text-sm text-gray-900">
              <b></b>
              <p>
                Diesem Vertrag liegen in der nachgenannten Reihen- und Rangfolge
                folgende Bestimmungen zu Grunde:
              </p>
              <ul className="p-6 list-disc">
                <li>
                  Die Bestimmungen dieses Vertrages einschließlich darin
                  getroffener Zusatzvereinbarungen
                </li>
                <li>Die Vertragsangebotsbestimmungen</li>
                <li>
                  Die „Allgemeinen Vereinbarungen zum Hopfenlieferungsvertrag“ -{" "}
                  {formatAVHLV(system.AVHLV)}
                </li>
                <li>
                  Die gesetzlichen Bestimmungen, insbesondere die Bestimmungen
                  des BGB
                </li>
                <li>
                  Die gesetzlichen Bestimmungen zur amtlichen Bezeichnung von
                  Hopfen
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
