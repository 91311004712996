import { useState } from "react";

import Authbar from "./Authbar";
import Sidebar from "./Sidebar";

import { userNavigation, wetterlinks } from "../../utils/navigation";

function Layout({ title, children, tabs, button }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100">
      <Authbar navigation={userNavigation} />
      <div className="py-10">
        <div className="max-w-full mx-auto sm:px-6 lg:max-w-full lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden lg:hidden xl:block lg:col-span-2 xl:col-span-2">
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              navigation={userNavigation}
              subnavigation={wetterlinks}
              subtitle="Wetterlinks"
            />
          </div>
          <main className="lg:col-span-12 xl:col-span-9">{children}</main>
        </div>
      </div>
    </div>
  );
}

export default Layout;
