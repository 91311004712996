import {useForm, FormProvider} from "react-hook-form";
import {useDispatch} from "react-redux";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import AdminLayout from "../../components/Layout/AdminLayout";
import FileInput from "../../components/Input/FileInput";

import {systemUpload} from "../../actions/adminActions";
import {getDocument} from "../../actions/documentActions";

export default function Upload(props) {
  const methods = useForm();
  const dispatch = useDispatch();

  const { data, error, mutate } = useSWR("/api/admin/system", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const fetchDocument = (e, documentType) => {
    e.preventDefault();
    dispatch(getDocument('SYS', documentType));
  }

  const columns = [
    {
      Header: "Dateiname",
      accessor: "dateiname",
      Cell: (props) => props.value
    },
    {
      Header: "Datei",
      accessor: "datei",
      Cell: (props) => (
        <button
          className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
          onClick={(e) => fetchDocument(e, props.row.original.name)}
        >
          {props.value}
        </button>
      ),
    },
    {
      Header: "Datei bearbeiten",
      accessor: "name",
      Cell: (props) => (
        <FileInput
          label={props.cell.row.original.dateiname + " "}
          name={props.value}
          file={{
            fileName: data.data[props.value].replace("/public/data/", ""),
          }}
          accept="application/pdf"
        />
      ),
    },
  ];

  const system = [
    {
      name: "terms",
      dateiname: "Teilnahmebedingungen",
      datei: data.data.terms.replace("/public/data/", ""),
    },
    {
      name: "salesImage",
      dateiname: "Kontakt Gruppenbild",
      datei: data.data.salesImage.replace("./public/data/", ""),
    },
    {
      name: "AVHLV",
      dateiname: "Aktuelle AVHLV",
      datei: data.data.AVHLV.replace("/public/data/", ""),
    },
    {
      name: "privacy",
      dateiname: "Datenschutzbestimmungen",
      datei: data.data.privacy.replace("/public/data/", ""),
    },
    {
      name: "offer",
      dateiname: "Vertragsangebotsbestimmungen",
      datei: data.data.offer.replace("/public/data/", ""),
    },  {
      name: "sprayNorm",
      dateiname: "Übersicht Pflanzenschutzmittelnormen aktuelle Ernte",
      datei: data.data.sprayNorm.replace("/public/data/", ""),
    },
  ];

  const onSubmit = (values) => {
    const formData = new FormData();

    if (values.terms) {
      formData.append("terms", values.terms[0]);
    }

    if (values.salesImage) {
      formData.append("salesImage", values.salesImage[0]);
    }

    if (values.AVHLV) {
      formData.append("avhlv", values.AVHLV[0]);
    }

    if (values.privacy) {
      formData.append("privacy", values.privacy[0]);
    }

    if (values.offer) {
      formData.append("offer", values.offer[0]);
    }

    if (values.sprayNorm) {
      formData.append("sprayNorm", values.sprayNorm[0]);
    }

    dispatch(systemUpload(formData, props.history));
    mutate();
  };

  return (
    <AdminLayout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Table title="Upload" columns={columns} data={system} />
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Speichern
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </AdminLayout>
  );
}
