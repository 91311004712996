/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {ScaleIcon} from "@heroicons/react/outline";
import {mutate} from "swr";

import {useHistory} from "react-router-dom";
import useSystem from "../hooks/useSystem";

import {useForm} from "react-hook-form-old";
import {useDispatch} from "react-redux";
import {addGrowerEstimate} from "../actions/accountActions";
import {getDocument} from "../actions/documentActions";


const ModalContainer = ({ handleClose, show }) => {
  const cancelButtonRef = useRef(null);

  const history = useHistory();
  const system = useSystem();

  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    dispatch(addGrowerEstimate(formData, system.cropYearForEstimates, history));
    handleClose();
    mutate("/api/estimates/crop");
    mutate("/api/estimates/area");
  };

  const fetchTerms = (e) => {
    e.preventDefault();
    dispatch(getDocument('SYS', 'terms'));
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={show}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                    <ScaleIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="font-display uppercase">
                          Schätzungen abgeben - <b>Erntejahr:</b>{" "}
                          {system.cropYearForEstimates}
                        </h3>
                        <hr />
                        {system.allowGrowerAreaEstimate ? (
                          <div className="mt-4">
                            <label
                              htmlFor="totalArea"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Gesamtfläche (ha)
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="totalArea"
                                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                                ref={register}
                              />
                            </div>
                          </div>
                        ) : null}
                        {system.allowGrowerCropEstimate ? (
                          <div className="mt-4">
                            <label
                              htmlFor="totalArea"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Gesamtertrag (Ztr.)
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="totalYield"
                                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                                ref={register}
                              />
                            </div>
                          </div>
                        ) : null}
                        <br />
                        <label className="mt-4 mb-2">
                          <input
                            type="checkbox"
                            name="consent"
                            className="text-primary focus:ring-primary"
                            ref={register({ required: true })}
                          />
                          <span className="text-sm">
                            {" "}
                            Ja, ich habe die{" "}
                            <button
                              className="text-primary cursor-pointer hover:underline"
                              onClick={(e) => fetchTerms(e)}
                              rel="noopener noreferrer"
                            >
                              Teilnahmebedingungen
                            </button>
                            {" "}
                            gelesen
                          </span>
                        </label>
                        <p className="mt-2 text-sm text-red-600" id="agb-error">
                          {errors.consent && "Bitte zustimmen!"}
                        </p>
                        <br />
                        <button
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary sm:mt-0 sm:col-start-1 sm:text-sm"
                          type="submit"
                        >
                          Abgeben
                        </button>
                      </form>
                    </Dialog.Title>
                  </div>
                </div>
                <div className="w-full px-2 py-3 sm:px-12 sm:flex">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-32 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                    ref={cancelButtonRef}
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default function Modal({ icon, modalText, modalAction, modalButton }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <ModalContainer
        show={show}
        handleClose={() => setShow(false)}
        handleConfirm={modalAction}
        text={modalText}
        buttonText={modalButton}
      />
      <button
        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
        onClick={() => setShow(true)}
      >
        Schätzungen abgeben
      </button>
    </>
  );
}
