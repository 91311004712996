import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../actions/authActions";
import { Link } from "react-router-dom";

import hop from "../img/hop.png";

export default function RegisterForm(props) {
  const { register, handleSubmit, formState: errors } = useForm();

  const error = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(checkToken("register", props.match.params.token, props.history));
  // }, [props, dispatch]);

  const onSubmit = (data) =>
    dispatch(registerUser(props.match.params.token, data, props.history));

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          src={hop}
          className="mx-auto h-12 w-auto"
          alt="Hopsteiner Pflanzerportal"
        />
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Registrieren
        </h1>
        <p className="mt-4 text-sm text-gray-600">
          Bitte nur zur Erstanmeldung mit den übersandten Daten benutzen. Falls
          Sie Hilfe bei der Erstanmeldung benötigen, wenden Sie sich bitte an{" "}
          <a
            className="hover:text-primary"
            href="mailto:pflanzerportal@hopsteiner.de"
          >
            pflanzerportal@hopsteiner.de
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="vorname"
                className="block text-sm font-medium text-gray-700"
              >
                Vorname
              </label>
              <div className="mt-1">
                <input
                  {...register("vorname", { required: true })}
                  id="vorname"
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="errors-vorname">
                {error && error.vorname}
              </p>
              {errors.vorname && (
                <span className="text-red-600">Bitte ausfüllen!</span>
              )}
            </div>
            <div>
              <label
                htmlFor="nachname"
                className="block text-sm font-medium text-gray-700"
              >
                Nachname
              </label>
              <div className="mt-1">
                <input
                  {...register("nachname", { required: true })}
                  id="nachname"
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="errors-nachname">
                {error && error.nachname}
              </p>
              {errors.nachname && (
                <span className="text-red-600">Bitte ausfüllen!</span>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-Mail Adresse
              </label>
              <div className="mt-1">
                <input
                  {...register("email", { required: true })}
                  id="email"
                  type="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <span className="red">{error.email}</span>
              {errors.email && (
                <span className="text-red-600">Bitte ausfüllen!</span>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Passwort wählen (mindestens 8 Zeichen)
              </label>
              <div className="mt-1">
                <input
                  {...register("password", { required: true })}
                  id="password"
                  type="password"
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
                    error.password
                      ? "ring-red-500 border-red-500"
                      : "focus:ring-primary focus:border-primary"
                  }`}
                />
              </div>
              <span className="text-red-600">{error.password}</span>
              {errors.password && (
                <span className="text-red-600">Bitte ausfüllen!</span>
              )}
            </div>
            <div>
              <label
                htmlFor="password2"
                className="block text-sm font-medium text-gray-700"
              >
                Passwort wiederholen
              </label>
              <div className="mt-1">
                <input
                  {...register("password2", { required: true })}
                  id="password2"
                  type="password"
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
                    error.password2
                      ? "ring-red-500 border-red-500"
                      : "focus:ring-primary focus:border-primary"
                  }`}
                />
              </div>
              <span className="text-red-600">{error.password2}</span>
              <br />
              <br />
              {error.password2 && (
                <span className="text-sm text-gray-600">
                  Das Passwort muss min. 8 Zeichen lang sein, eine Nummer, ein
                  Symbol, einen Groß- und einen Kleinbuchstaben enthalten.
                </span>
              )}
              {errors.password2 && (
                <span className="text-red-600">Bitte ausfüllen!</span>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Registrieren
              </button>
              <div className="mt-2 w-full flex justify-center py-2 px-4 rounded-md text-sm font-medium text-primary bg-white hover:bg-white hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <Link to="/login">Zurück zum Login</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
