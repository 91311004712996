import React from "react";
import { getRange } from "../../lib/getRange";

export default function Lieferzeitraum({
  angebot,
  register,
  watch,
  errors,
  trigger,
}) {
  const min = angebot.Mindestlaufzeit ? angebot.Mindestlaufzeit : 0;
  const early = min ? min : angebot.Startjahr;
  const latest = angebot["Beginn spätestens"]
    ? angebot["Beginn spätestens"]
    : angebot.Endjahr;

  const ende = watch("ende");

  const validateDate = (beginn) => {
    return ende >= beginn;
  };

  const startOptions = getRange(angebot.Startjahr, latest).map((year, idx) => {
    return (
      <option key={idx} value={year}>
        {year.toString()}
      </option>
    );
  });

  const endOptions = getRange(early, angebot.Endjahr).map((year, idx) => {
    return (
      <option key={idx} value={year.toString()}>
        {year.toString()}
      </option>
    );
  });

  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Lieferzeitraum
        </h3>
      </div>
      <div className="border-t border-primary " />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="start"
                >
                  Vertragsbeginn:
                </label>{" "}
                <select
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  name="beginn"
                  ref={register({
                    required: true,
                    validate: validateDate,
                  })}
                  onChange={() => trigger("beginn")}
                >
                  {startOptions}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="ende"
                >
                  Vertragsende:
                </label>
                <select
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  name="ende"
                  onChange={() => trigger("beginn")}
                  ref={register({
                    required: true,
                  })}
                >
                  {endOptions}
                </select>
                {errors.beginn && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.beginn?.type === "required" && "Bitte ausfüllen"}
                    {errors.beginn?.type === "validate" &&
                      "Bitte passendes Enddatum auswählen!"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
