import {useFormContext} from "react-hook-form";
import useSystem from "../../hooks/useSystem";
import {formatAVHLV} from "../../lib/formatAVHLV";
import {useDispatch} from "react-redux";
import {getDocument} from "../../actions/documentActions";

export default function Zusammenfassung() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const system = useSystem();
  const dispatch = useDispatch()

  function showPDF(e, type) {
    e.preventDefault();
    dispatch(getDocument('SYS', type))
  }

  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">AGB</h3>
      </div>
      <hr />
      <div className="border-t border-primary px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dd className="mt-1 text-sm text-gray-900">
              <div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      aria-describedby="agb-description"
                      {...register("agb", { required: "error message" })}
                      type="checkbox"
                      className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <span id="comments-description" className="text-gray-500">
                      Mit der Abgabe des Vertragsangebots erklären Sie sich mit
                      unseren{" "}
                      <button
                        onClick={(event) => showPDF(event, 'privacy')}
                        className="text-primary cursor-pointer hover:underline"
                      >
                        Datenschutzerklärung
                      </button>
                      {", "}
                      <button
                        onClick={(event) => showPDF(event, 'offer')}
                        className="text-primary cursor-pointer hover:underline"
                        rel="noopener noreferrer"
                      >
                        den Vertragsangebotsbestimmungen
                      </button>
                      {" "}
                      sowie der{" "}
                      <button
                        onClick={(event) => showPDF(event, 'AVHLV')}
                        className="text-primary cursor-pointer hover:underline"
                        rel="noopener noreferrer"
                      >
                        {formatAVHLV(system.AVHLV)}
                      </button>
                      {" "}
                      einverstanden.
                    </span>
                  </div>
                </div>

                {errors.agb && (
                  <p className="mt-2 text-sm text-red-600" id="agb-error">
                    Bitte akzeptieren Sie die AGBs!
                  </p>
                )}
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
