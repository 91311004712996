import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import EmailForm from "../../components/Forms/EmailForm";

export default function EmailEdit(props) {
  const { data, error } = useSWR(
    `/api/mails/${props.match.params.id}`,
    fetcher
  );

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    name: data.data.name,
    subject: data.data.subject,
    message: data.data.message,
  };

  return (
    <AdminLayout title="Email">
      <EmailForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
