import Card from "../Shop/Card";
import {useSelector} from "react-redux";

export default function ContractApproval({ angebot, check, data, errors, register, watch }) {
  const grower = useSelector((state) => state.grower);

  const areaApproval = grower.growerArea === 'DES';
  const varietyApproval = angebot && angebot.hop['Nicht MEHOP meldepflichtig'];

  const mehopValue = watch && watch('mehop')

  return (
    <Card title={check ? "VI. Vertragsgenehmigung" : "Vertragsgenehmigung"}>
      <p>Verträge, an denen Mitglieder der HVG e.G. als Erzeugerorganisation beteiligt sind, stehen gemäß der hier geltenden Anlage V der AVHLV unter Genehmigungsvorbehalt und bedürfen nach dem dort beschriebenen Verfahren zur Wirksamkeit der Zustimmung der HVG e.G.</p>
      <br />
      <p>Zu diesem Zweck erklären die Vertragsparteien verpflichtend:</p>
      <br />
      <div className="relative p-2 cursor-pointer focus:outline-none">
        <label>
          {check || areaApproval || varietyApproval ? <input
            className="text-primary"
            name="mehop"
            type="radio"
            value={true}
            checked={data?.mehop === 'true'}
            disabled
          /> : <input
            className="text-primary"
            name="mehop"
            type="radio"
            value={true}
            defaultChecked={!areaApproval}
            ref={register({ required: true })}
          />}
          <span className="ml-4">Der Verkäufer ist Mitglied der HVG Wolnzach. HOPSTEINER besorgt anstelle des Verkäufers die Antragstellung zur Erteilung der zur Vertragswirksamkeit erforderlichen Genehmigung.
          </span>
        </label>
      </div>
      <div className="relative p-2 cursor-pointer focus:outline-none">
        <label>
          {check ? <input
            className="text-primary"
            name="mehop"
            type="radio"
            value={false}
            checked={data?.mehop === 'false'}
            disabled
          /> : <input
            className="text-primary"
            name="mehop"
            type="radio"
            value={false}
            defaultChecked={areaApproval || varietyApproval}
            ref={register({ required: true })}
          />}
          <span className="ml-4">Der Verkäufer ist kein Mitglied der HVG Wolnzach und / oder die Vertragssorte ist nicht genehmigungspflichtig und / oder es handelt sich um eine Vertragsumschreibung.</span>

        </label>
      </div>
      <div className="relative p-2 cursor-pointer focus:outline-none">
        <label className="text-gray-500">
          {check || areaApproval ? <input
            className="text-primary"
            name="buyerReports"
            type="checkbox"
            checked={data?.mehop === 'true'}
            readOnly={true}
            disabled
          /> : <input
            className="text-primary"
            name="buyerReports"
            type="checkbox"
            checked={mehopValue === 'true'}
            ref={register()}
            readOnly={true}
          />}
          <span className="ml-4">Der Käufer ist Teilnehmer am Meldeverfahren von Rödl (MSH) und besorgt nach den Bestimmungen der Anlage V der AVHLV anstelle des Verkäufers die Antragstellung zur Erteilung der zur Vertragswirksamkeit erforderlichen Genehmigung</span>
        </label>
      </div>
      <p className="mt-2  text-red-600" id="mehop-error">
        {errors && errors.mehop && "Bitte eine Variante für die Vertragsgenehmigung wählen"}
      </p>
    </Card>
  );
}
