import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import EinkaufForm from "../../components/Forms/EinkaufForm";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

export default function EinkaufID(props) {
  const { data, error } = useSWR(
    `/api/salesman/${props.match.params.id}`,
    fetcher
  );

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    salesmanId: data.data.salesmanId,
    name: data.data.name,
    phoneNumber: data.data.phoneNumber,
    cellNumber: data.data.cellNumber,
    faxNumber: data.data.faxNumber,
    email: data.data.email,
    isPurchaser: data.data.isPurchaser,
    accountId: data.data.accountId,
  };

  return (
    <AdminLayout>
      <EinkaufForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
