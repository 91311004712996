import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import LinkForm from "../../components/Forms/LinkForm";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

export default function LinkEdit(props) {
  const { data, error } = useSWR(
    `/api/links/${props.match.params.id}`,
    fetcher
  );

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    name: data.data.name,
    description: data.data.description,
    url: data.data.url,
    sort: data.data.sort,
  };

  return (
    <AdminLayout>
      <LinkForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
