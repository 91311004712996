import HopImage from "./HopImage";

import { getRange } from "../../lib/getRange";
import { getPrices } from "../../lib/getPrices";
import { getFixedPrice } from "../../lib/getFixedPrice";
import { getHopType } from "../../lib/getHopType";
import { currency } from "../../lib/currency";
import { formatContract } from "../../lib/formatContract";

export default function HopCard({ angebot, cropYear, button, user }) {
  const mindestlaufzeit =
    angebot.Mindestlaufzeit === 0
      ? "keine Mindestlaufzeit"
      : angebot.Mindestlaufzeit;

  const duration = getRange(angebot.Startjahr, angebot.Endjahr);
  const prices = getPrices(angebot, duration);
  const type = getHopType(angebot);

  const url = `angebot?vertragsart=${angebot.Vertragsart}&jahr=${angebot.Startjahr}&sorte=${angebot.Sorte}&variante=${angebot.Variante}`;

  return (
    <li className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <HopImage src={angebot.hop.Beschreibung} />
              </div>
              <div className="ml-4">
                <h3 className="text-2xl font-display uppercase leading-6 font-medium text-gray-900">
                  {angebot.hop.Beschreibung}
                </h3>
                {angebot.Vertragsart === 0 && (
                  <h5 className="mt-2 text-sm text-gray-500">
                    Ab {angebot.Startjahr} - Bis {angebot.Endjahr}
                  </h5>
                )}
                {angebot.Vertragsart !== 0 && (
                  <h5 className="mt-2 text-sm text-gray-500">
                    Erntejahr {cropYear}
                  </h5>
                )}
              </div>
            </div>
          </div>

          {button && user.role !== "salesman" && (
            <div className="ml-4 mt-2 flex-shrink-0">
              <a href={url}>
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Angebot abgeben
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Vertragsart</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {formatContract(angebot.Vertragsart)}
            </dd>
          </div>
          {angebot.Vertragsart === 0 && (
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Laufzeit bis mindestens:
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {mindestlaufzeit}
              </dd>
            </div>
          )}
          {angebot.Vertragsart === 0 && angebot["Beginn spätestens"] !== 0 && (
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Beginn spätestens:
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {angebot["Beginn spätestens"]} {angebot["Beginn spätestens"] !== angebot["Endjahr"] && `(Keine Mengensteigerung nach ${angebot["Beginn spätestens"]})`}
              </dd>
            </div>
          )}

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Lieferart</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {type === " kg Alpha" ? "kg Alpha" : "kg Rohhopfen"}
            </dd>
          </div>

          {angebot.Vertragsart === 1 && (
            <div className="mb-3 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {type === " kg Alpha"
                  ? "Festpreis € / kg Alpha"
                  : "Grundpreis € / kg"}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {getFixedPrice(angebot)}
              </dd>
              <dd className="mt-1 text-sm text-gray-400">
                zzgl. gesetzlicher Mehrwertsteuer{" "}
              </dd>
            </div>
          )}

          {angebot.Vertragsart === 3 && (
            <div className="mb-3 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {type === " kg Alpha"
                  ? "Anzahlungspreis € / kg Alpha"
                  : "Anzahlungspreis € / kg"}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {getFixedPrice(angebot)}
              </dd>
               <dd className="mt-1 text-sm text-gray-400">
                zzgl. gesetzlicher Mehrwertsteuer{" "}
              </dd>
            </div>
          )}

          {angebot.Vertragsart === 0 && (
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Preisliste
                </dt>

                <div className="flex flex-col mt-6 mb-6">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="">
                          <tr>
                            {duration.map((year, idx) => (
                              <th
                                key={idx}
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {year}{" "}
                              </th>
                            ))}
                          </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                          <tr>
                            {prices.map((preis, idx) => (
                              <td
                                key={idx}
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {currency(preis)}
                              </td>
                            ))}
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <dd className="mt-1 text-sm text-gray-400">
                  {type === " kg Alpha"
                    ? "Festpreis € / kg Alpha"
                    : "Grundpreis € / kg"}
                  <br />
                  zzgl. gesetzlicher Mehrwertsteuer{" "}
                </dd>
              </div>
            </div>
          )}
        </dl>
      </div>
    </li>
  );
}
