import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form-old";
import { getHopType } from "../../lib/getHopType";

import useUVM from "../../hooks/useUVM";

import HopCard from "../Shop/HopCard";
import Kaufpreisberechnung from "./Kaufpreisberechnung";

import Lieferart from "./Lieferart";
import Lieferzeitraum from "./Lieferzeitraum";
import Liefermenge from "./Liefermenge";
import Lieferverpflichtungen from "./Lieferverpflichtungen";

import Bezahlung from "../Angebote/Bezahlung";
import ContractApproval from "../Angebote/ContractApproval";
import Zusatzvereinbarungen from "../Angebote/Zusatzvereinbarungen";

import { saveOffer } from "../../actions/formActions";

export default function Vorvertrag(data) {
  const angebot = data;
  const history = useHistory();
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state);

  const initialValue = form.data === undefined ? {} : form.data;

  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      alphapreis: initialValue.alphapreis,
      beginn: initialValue.beginn ? initialValue.beginn : angebot.Startjahr,
      bezahlung: initialValue.bezahlung,
      ende: initialValue.ende
        ? initialValue.ende
        : angebot.Mindestlaufzeit
        ? angebot.Mindestlaufzeit
        : angebot.Startjahr,
      ha: initialValue.ha,
      liefermenge: initialValue.liefermenge,
      firma: initialValue.firma,
      menge: initialValue.menge,
      verpflichtung: initialValue.verpflichtung,
      verkaufsart: initialValue.verkaufsart,
      quality: initialValue.quality,
      other: initialValue.other,
      mehop: initialValue.mehop,
      buyerReports: initialValue.buyerReports
    },
  });

  const type = getHopType(angebot);
  const startUvm = useUVM(angebot.Sorte, type)
  const raw = type === " kg Rohhopfen" ? true : false;

  const onSubmit = (data) => {
    const formValues = {
      data: data,
      raw: raw,
      startUvm: startUvm,
      angebot: angebot,
    };

    dispatch(saveOffer(formValues));
    history.push("/abschluss");
  };

  return (
    <form className="container" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-display text-3xl uppercase border-b border-primary mb-5">
        Vertragsangebot
      </h1>
      <ul>
        <HopCard angebot={angebot} />
      </ul>
      <br />
      <Lieferart type={type} register={register} />
      <Lieferzeitraum
        angebot={angebot}
        register={register}
        watch={watch}
        errors={errors}
        trigger={trigger}
      />

      <Lieferverpflichtungen
        startUvm={startUvm}
        angebot={angebot}
        getValues={getValues}
        register={register}
        watch={watch}
        control={control}
        type={type}
        errors={errors}
      />

      <Liefermenge
        startUvm={startUvm}
        angebot={angebot}
        getValues={getValues}
        setValue={setValue}
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        type={type}
      />
      <br />
      <Kaufpreisberechnung
        title="Kaufpreisberechnung"
        angebot={angebot}
        register={register}
        errors={errors}
        raw={raw}
      />
      <br />
      <Bezahlung type={type} register={register} errors={errors} />
      <ContractApproval angebot={angebot} register={register} errors={errors} watch={watch}  />
      <Zusatzvereinbarungen title="Zusatzvereinbarungen" angebot={angebot} />
      <div className="pt-5">
        <div className="flex justify-end">
          <a
            href="/angebote"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          >
            Zurück
          </a>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          >
            Ihr Vertragsangebot prüfen
          </button>
        </div>
      </div>
    </form>
  );
}
