import { ExclamationIcon } from "@heroicons/react/solid";

export function handleWarning(liefermenge, max) {
  const show = liefermenge?.map((number, idx) => {
    return (
      liefermenge &&
      max !== undefined &&
      Number(liefermenge[idx]) > Number(max[idx]).toFixed(0)
    );
  });

  const check = show && show.includes(true);

  return (
    check && (
      <div className="mt-5 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Erscheint der Hinweis „Max. Liefermenge überschritten.“, dann ist
              die maximal mögliche Vertragsmenge auf Basis der unbedenklichen
              Vertragsmenge (UVM) laut AVHLV überschritten. Dennoch haben Sie
              die Möglichkeit Ihr Angebot abzusenden. In diesem Fall kontaktiert
              Sie Ihr Einkäufer, um Ihre individuelle UVM festzustellen.
              Alternativ geben Sie bei „Ihre gewünschte Vertragsmenge“ nicht
              mehr ein als die angegebene max. Liefermenge.
            </p>
          </div>
        </div>
      </div>
    )
  );
}
