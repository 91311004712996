import useSWR from "swr";
import fetcher from "../lib/fetcher";
import queryString from "query-string";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import ChartMenu from "../components/Charts/ChartMenu";

import { getQualityDetails } from "../lib/getQualityDetails";

import useHop from "../hooks/useHop";

export default function QualityDetails(props) {
  const params = queryString.parse(props.location.search);
  const values = getQualityDetails(params.wert);

  const { data, error } = useSWR(
    `/api/quality/${params.wert}/${params.sorte}/${params.jahr}`,
    fetcher
  );

  const hop = useHop(params.sorte);

  const columns = [
    { Header: "Sorte", accessor: "hop.Beschreibung" },
    {
      Header: "Jahr",
      accessor: "Jahr",
    },
    {
      Header: "Menge kg",
      accessor: "Gewicht in KG",
    },
    {
      Header: values.label1,
      accessor: values.value1,
      Cell: (props) => props.value,
    },
    {
      Header: values.label2,
      accessor: values.value2,
      Cell: (props) => props.value,
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout title="Verträge">
      <h1 className="font-display uppercase text-3xl ">
        Sorte: {hop && hop.Beschreibung}
      </h1>
      <h2 className="font-display uppercase text-2xl text-primary">
        {values.headline}
      </h2>
      <br />
      <ChartMenu sorte={params.sorte} wert={params.wert} />
      <div className="chart-table">
        <Table columns={columns} data={data} csv={true} pdf={true} />
      </div>
    </Layout>
  );
}
