import { Link } from "react-router-dom";

/* This example requires Tailwind CSS v2.0+ */
export default function Custom404() {
  return (
    <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-primary sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Seite nicht gefunden
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Die Seite die Sie suchen existiert leider nicht!
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <span className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
                <Link to="/">Zurück zur Startseite</Link>
              </span>
              <span className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary bg-white hover:bg-secondary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
                <Link to="/kontakt">Kontaktieren Sie Uns!</Link>
              </span>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
