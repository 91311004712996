import useSWR from "swr";
import fetcher from "../lib/fetcher";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import { date } from "../lib/date";
import { getFile } from "../actions/fileActions";

import header from "../img/hops-passion-header.jpg";

export default function Home() {
  const { data, error } = useSWR("/api/infos/public", fetcher);
  const dispatch = useDispatch();

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <div className="font-lato min-h-screen bg-white">
      <div className="relative pb-24 sm:pb-32">
        <div className="absolute inset-0">
          <div className="w-full">
            <img
              className="w-full pt-24"
              src={header}
              alt="Hopsteiner Pflanzerportal"
            />
          </div>
        </div>
        <Navbar />

        <div className="relative mt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:mt-32 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="headline font-display uppercase mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
            <span className="block">Hopsteiner</span>
            <span className="pb-3 block text-white sm:pb-5">
              Pflanzerportal
            </span>
          </h1>

          <div className="mt-10 sm:mt-12">
            <div className="sm:flex">
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <div className="block w-full py-3 px-4 rounded-md shadow bg-primary text-white font-medium hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900">
                  <Link to="/login">Anmelden</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main>
        {/* Feature section with grid */}
        <div className="relative bg-white py-16 sm:py-24 lg:py-16">
          <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 className="text-base font-semibold tracking-wider text-primary uppercase">
              Das Hopsteiner Pflanzerportal
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Verwalten Sie Ihre Ernte digital
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              Unser Pflanzerportal gibt Ihnen die Möglichkeit Ihre Verträge
              digital und unmittelbar abzuschließen, Ihre Partiedaten jederzeit
              mobil einzusehen und an unseren Schätzungen teilzunehmen.
            </p>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"></div>
            </div>
          </div>
        </div>

        {/* Blog section */}
        <div className="relative bg-warm-gray-50 py-16 sm:py-24 lg:py-16">
          <div className="relative">
            <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-base font-semibold tracking-wider text-primary uppercase">
                Mehr erfahren
              </h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Neuigkeiten
              </p>
              <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                Hier finden Sie aktuelle Informationen rund um das Thema
                <br /> Hopfenanbau und Hopfenmarkt.
              </p>
            </div>
            <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              {data.map((post) => (
                <div
                  key={post.newsId}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      {post.fileId !== 0 ? (
                        <button
                          className="block mt-2"
                          onClick={() => dispatch(getFile(post.fileId))}
                        >
                          <p className="text-xl text-left font-semibold text-gray-900 hover:text-primary">
                            {post.message}
                          </p>
                        </button>
                      ) : (
                        <a
                          href={post.link}
                          target="_blank"
                          rel="noreferrer"
                          className="block mt-2"
                        >
                          <p className="text-xl font-semibold text-gray-900 hover:text-primary">
                            {post.message}
                          </p>
                        </a>
                      )}
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="ml-3">
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime={post.datetime}>
                            {date(post.date)}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
