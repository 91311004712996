const initalState = {
  account: {},
  grower: {},
  secondGrower: [],
  system: {},
  partiedaten: [],
  contracts: [],
  delivery: [],
  crop: [],
  area: [],
  estimate: [],
  quality: [],
  details: [],
  loading: false,
};

export default function accountReducer(state = initalState, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_ACCOUNT":
      return {
        ...state,
        account: action.payload.data,
        loading: false,
      };
    case "GET_GROWER_ACCOUNT":
      return {
        ...state,
        grower: action.payload.data,
        loading: false,
      };

    case "GET_SECOND_GROWER":
      return {
        ...state,
        secondGrower: action.payload.data,
        loading: false,
      };

    case "GET_PARTIEDATEN":
      return {
        ...state,
        partiedaten: action.payload.data,
        loading: false,
      };
    case "GET_CONTRACTS":
      return {
        ...state,
        contracts: action.payload.data,
        loading: false,
      };

    case "GET_DELIVERY":
      return {
        ...state,
        delivery: action.payload.data,
        loading: false,
      };
    case "GET_QUALITY":
      return {
        ...state,
        quality: action.payload.data,
        loading: false,
      };
    case "GET_QUALITY_DETAILS":
      return {
        ...state,
        details: action.payload.data,
        loading: false,
      };
    case "GET_AREA_ESTIMATE":
      return {
        ...state,
        area: action.payload.data,
        loading: false,
      };
    case "GET_CROP_ESTIMATE":
      return {
        ...state,
        crop: action.payload.data,
        loading: false,
      };
    case "GET_GROWER_ESTIMATE":
      return {
        ...state,
        estimate: action.payload.data,
        loading: false,
      };
    case "GET_SYSTEM":
      return { ...state, system: action.payload.data, loading: false };
    default:
      return state;
  }
}
