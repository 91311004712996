const initalState = {
  alphas: [],
  alpha: {},
  contracts: [],
  estimates: [],
  estimate: {},
  konten: [],
  konto: {},
  forms: [],
  form: {},
  emails: [],
  email: {},
  sorten: [],
  areas: [],
  system: {},
  salesmen: [],
  salesman: {},
  links: [],
  link: {},
  news: [],
  newsitem: {},
  loading: true,
};

export default function adminReducer(state = initalState, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_ADMIN_ALPHA":
      return {
        ...state,
        alpha: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_ALPHAS":
      return {
        ...state,
        alpha: {},
        alphas: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_KONTO":
      return {
        ...state,
        konto: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_KONTEN":
      return {
        ...state,
        konten: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_FORM":
      return {
        ...state,
        form: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_FORMS":
      return {
        ...state,
        forms: action.payload.data,
        loading: false,
      };

    case "GET_ADMIN_NEWSITEM":
      return {
        ...state,
        newsitem: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_NEWS":
      return {
        ...state,
        news: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_ESTIMATE":
      return {
        ...state,
        estimate: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_ESTIMATES":
      return {
        ...state,
        estimate: {},
        estimates: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_EMAIL":
      return {
        ...state,
        email: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_EMAILS":
      return {
        ...state,
        emails: action.payload.data,
        email: {},
        loading: false,
      };
    case "GET_ADMIN_LINK":
      return {
        ...state,
        link: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_LINKS":
      return {
        ...state,
        links: action.payload.data,
        link: {},
        loading: false,
      };

    case "GET_ADMIN_SORTEN":
      return {
        ...state,
        sorten: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_AREAS":
      return {
        ...state,
        areas: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_SYSTEM":
      return {
        ...state,
        system: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_SALESMAN":
      return {
        ...state,
        salesman: action.payload.data,
        loading: false,
      };
    case "GET_ADMIN_SALESMEN":
      return {
        ...state,
        salesmen: action.payload.data,
        loading: false,
      };

    case "GET_ADMIN_OFFERS":
      return {
        ...state,
        contracts: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
