import Authbar from "./Authbar";
import { userNavigation } from "../../utils/navigation";

export default function AngebotLayout({ title, children, sidebar }) {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Authbar navigation={userNavigation} />
      <div className="py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-full lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
            {sidebar}
          </div>
          <main className="lg:col-span-12 xl:col-span-9">
            <h1 className="sr-only">{title}</h1>

            {children}
          </main>
        </div>
      </div>
    </div>
  );
}
