export default function Lieferart({ register, errors, type }) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Lieferart
        </h3>
      </div>
      <div className="border-t border-primary px-4  sm:px-6" />

      <hr />
      {type === " kg Rohhopfen" && (
        <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
          <label>
            <input
              className="text-primary"
              name="verkaufsart"
              type="radio"
              value="0"
              ref={register({ required: true })}
              checked={true}
              readOnly
            />
            <span className="ml-4 font-medium">Kilogramm Rohhopfen</span>
          </label>
        </div>
      )}
      {type === " kg Alpha" && (
        <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
          <label>
            <input
              className="text-primary"
              name="verkaufsart"
              type="radio"
              value="1"
              ref={register({ required: true })}
              checked={true}
              readOnly
            />
            <span className="ml-4 font-medium">Kilogramm Alpha</span>
          </label>
        </div>
      )}
    </div>
  );
}
