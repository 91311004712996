export function showRange(startYear, duration) {
  const years = [];
  const endYear = Number(startYear) + Number(duration);

  while (startYear <= endYear) {
    years.push(startYear++);
  }

  return years;
}
