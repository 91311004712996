export function lieferungenInput(arr, register, number, errors) {
  return arr.map((input, idx) => {
    return (
      <td key={idx}>
        <input
          type="number"
          className="mt-5 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          name={`menge[${number}][${idx}]`}
          placeholder="0"
          ref={register({
            min: 0,
          })}
          onChange={(e) => {
            if (e.target.value) {
              e.target.value = Number(e.target.value).toFixed(0);
            }
          }}
          onWheel={(e) => e.target.blur()}
        />
        <br />
        {errors.menge && errors.menge[number] && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors.menge[number][idx]?.type === "required" &&
              "Bitte ausfüllen"}
            {errors.menge[number][idx]?.type === "min" &&
              "Bitte positive Zahl angeben"}
          </p>
        )}
      </td>
    );
  });
}
