export const companies = [
  {
    value: "Simon H. Steiner, Hopfen, GmbH",
    label: "Simon H. Steiner, Hopfen, GmbH",
  },
  { value: "BarthHaas GmbH & Co. KG", label: "BarthHaas GmbH & Co. KG" },
  { value: "Baywa AG", label: "Baywa AG" },
  { value: "Brauerei", label: "Brauerei" },
  { value: "H. Eisemann KG", label: "H. Eisemann KG" },
  {
    value: "HVG Hopfenverwertungsgenossenschaft e.G.",
    label: "HVG Hopfenverwertungsgenossenschaft e.G.",
  },
  { value: "HVG Spalt e.G.", label: "HVG Spalt e.G." },
  { value: "IGN GmbH", label: "IGN GmbH" },
  { value: "Lupex GmbH", label: "Lupex GmbH" },
  { value: "Andere Firma", label: "Andere Firma" },
];
