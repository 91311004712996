export default function Vertragsbestandteile({title}) {
  return (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title || "VIII. Wirksamkeit des Vertrages – Geltung AVHLV"}
        </h3>
      </div>
      <hr />
      <div className="border-t border-primary px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dd className="mt-1 text-sm text-gray-900">
              <p>
                Die rechtliche Unwirksamkeit eines Teiles der vorstehenden
                Vereinbarungen oder der AVHLV ist auf die Gültigkeit der übrigen
                Vereinbarungen des Vertrages ohne Einfluss.
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
