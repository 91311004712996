import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import Input from "../Input/Input";
import { addLink, updateLink } from "../../actions/adminActions";
import { useHistory } from "react-router-dom";

export default function LinkForm(props) {
  const { defaultValues, edit } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const methods = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = (data) =>
    edit
      ? dispatch(addLink(data, history))
      : dispatch(updateLink(props.match.params.id, data, history));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Link hinzufügen" : "Link bearbeiten"}
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <Input type="number" label="Sortierung" name="sort" />
          <Input type="text" label="Name" name="name" />
          <Input type="text" label="Beschreibung" name="description" />
          <Input type="text" label="URL" name="url" />
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href="/admin/links"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
