import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import MittelwerteForm from "../../components/Forms/MittelwerteForm";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

import { formatAvg } from "../../lib/formatAvg";

export default function Mittelwerte(props) {
  const { data, error } = useSWR(
    `/api/alphas/alpha/${props.match.params.id}`,
    fetcher
  );

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const alpha = data;

  const defaultValues = {
    cropYear: alpha.data.cropYear,
    variety: alpha.data.variety,
    growingArea: alpha.data.growingArea,
    alphaContent: formatAvg(alpha.data.alphaContent),
    alphaContentSourceId: alpha.data.alphaContentSourceId,
    humidity: formatAvg(alpha.data.humidity),
    humiditySourceId: alpha.data.humiditySourceId,
    leafStemPercent: formatAvg(alpha.data.leafStemPercent),
    leafStemPercentSourceId: alpha.data.leafStemPercentSourceId,
    wastePercent: formatAvg(alpha.data.wastePercent),
    wastePercentSourceId: alpha.data.wastePercentSourceId,
    percentLeaves: formatAvg(alpha.data.percentLeaves),
    percentLeavesSourceId: alpha.data.percentLeavesSourceId,
    rating: formatAvg(alpha.data.rating),
    ratingSourceId: alpha.data.ratingSourceId,
    balance: formatAvg(alpha.data.balance),
    balanceSourceId: alpha.data.balanceSourceId,
  };

  return (
    <AdminLayout>
      <MittelwerteForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
