import { sum } from "./sum";
import { getCol } from "./getCol";

export function calcAmount(arr, number, size) {
  // Create Array for the appropriate length
  let matrix = number ? number : 0;
  return arr.map((input, idx) => {
    return (
      <td
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        key={idx}
      >
        {number ? sum(getCol(matrix, idx)) : 0}
      </td>
    );
  });
}
