import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, checkToken } from "../actions/authActions";
import hop from "../img/hop.png";

export default function Reset(props) {
  const { register, handleSubmit, errors } = useForm();
  const error = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkToken("reset", props.match.params.token, props.history));
  }, [props, dispatch]);

  // getDerivedStateFromProps(nextProps) {
  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //   }
  // }

  const onSubmit = (data) =>
    dispatch(resetPassword(props.match.params.token, data, props.history));

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          src={hop}
          className="mx-auto h-12 w-auto"
          alt="Hopsteiner Pflanzerportal"
        />
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Passwort zurücksetzen
        </h1>
        <p className="mt-4 text-sm text-gray-600">
          Wenn Sie Probleme beim Zurücksetzen Ihres Passwortes haben, dann
          senden Sie uns bitte ein E-Mail an{" "}
          <a
            className="hover:text-primary"
            href="mailto:pflanzerportal@hopsteiner.de"
          >
            pflanzerportal@hopsteiner.de
          </a>{" "}
          damit wir Sie unterstützen können.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Passwort
              </label>
              <div className="mt-1">
                <input
                  {...register("password", { required: true })}
                  id="password"
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="password">
                {error && error.password}
                {errors && errors.password}
              </p>
            </div>
            <div>
              <label
                htmlFor="password2"
                className="block text-sm font-medium text-gray-700"
              >
                Passwort wiederholen{" "}
              </label>
              <div className="mt-1">
                <input
                  {...register("password2", { required: true })}
                  id="password2"
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                />
              </div>
              <p className="mt-2 text-sm text-red-600" id="password">
                {error && error.password2}
                {errors && errors.password2}
              </p>{" "}
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                Passwort zurücksetzen
              </button>
              <div className="mt-2 w-full flex justify-center py-2 px-4 rounded-md text-sm font-medium text-primary bg-white hover:bg-white hover:text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <Link to="/login">Zurück zum Login</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
