import { useForm } from "react-hook-form-old";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useSystem from "../../hooks/useSystem";
import useLieferung from "../../hooks/useLieferung";

import HopCard from "../Shop/HopCard";

import Freihopfen from "./Freihopfen";
import FreihopfenSchätzmenge from "./FreihopfenSchätzmenge";
import FreihopfenMenge from "./FreihopfenMenge";
import FreihopfenÜberlieferung from "./FreihopfenÜberlieferung";

import Kaufpreisberechnung from "./Kaufpreisberechnung";
import Bezahlung from "../Angebote/Bezahlung";
import Zusatzvereinbarungen from "../Angebote/Zusatzvereinbarungen";

import { saveOffer } from "../../actions/formActions";
import { getHopType } from "../../lib/getHopType";

export default function Vertragsangebot(props) {
  const angebot = props;

  const history = useHistory();
  const system = useSystem();
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state);

  const lieferung = useLieferung(angebot.Sorte);
  const initialValue = form.data === undefined ? {} : form.data;

  const { register, watch, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      bezahlung: initialValue.bezahlung,
      freihopfen: initialValue.freihopfen,
      amount: initialValue.amount,
      estimate: initialValue.estimate,
      partie: initialValue.partie,
      überlieferung: initialValue.überlieferung,
      weight: initialValue.weight,
    },
  });

  const freihopfen = watch().freihopfen;

  const onSubmit = (data) => {
    const formValues = {
      data: data,
      angebot: angebot,
    };
    dispatch(saveOffer(formValues));
    history.push("/abschluss");
  };

  const type = getHopType(angebot);
  const raw = type === " kg Rohhopfen" ? true : false;
  const art = "Freihopfen";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h1 className="text-2xl uppercase font-display leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Vertragsangebot
          </h1>
          <br />
          <ul>
            <HopCard
              cropYear={system.cropYear}
              angebot={angebot}
              button={false}
            />
          </ul>
          <br />
          <Freihopfen
            angebot={angebot}
            register={register}
            errors={errors}
            art={art}
            lieferung={lieferung}
            setValue={setValue}
          />
          {freihopfen === "0" && (
            <FreihopfenSchätzmenge register={register} art={art} type={type} />
          )}
          {freihopfen === "1" && (
            <FreihopfenMenge
              register={register}
              watch={watch}
              errors={errors}
              art={art}
              type={type}
              lieferung={lieferung}
              control={control}
            />
          )}
          {freihopfen === "2" ? (
            <FreihopfenÜberlieferung
              register={register}
              art={art}
              type={type}
              lieferung={lieferung}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <br />
      <Kaufpreisberechnung
        title={
          raw
            ? "Kaufpreisberechnung"
            : "Ermittlung des Alphawertes und Kaufpreisberechnung"
        }
        angebot={angebot}
        type={type}
      />
      <Bezahlung type={type} register={register} errors={errors} />
      <Zusatzvereinbarungen title="Zusatzvereinbarungen" angebot={angebot} />
      <div className="pt-5">
        <div className="flex justify-end">
          <a
            href="/angebote"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          >
            Zurück
          </a>
          {freihopfen === "2" && lieferung === 0 ? (
            ""
          ) : (
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Vertragsangebot prüfen
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
