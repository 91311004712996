import { combineReducers } from "redux";

import authReducer from "./authReducer";
import notificationsReducer from "./notificationsReducer";
import errorReducer from "./errorReducer";
import shopReducer from "./shopReducer";
import accountReducer from "./accountReducer";
import adminReducer from "./adminReducer";
import formReducer from "./formReducer";
import growerReducer from "./growerReducer";

export default combineReducers({
  accounts: accountReducer,
  admin: adminReducer,
  auth: authReducer,
  errors: errorReducer,
  notifications: notificationsReducer,
  shop: shopReducer,
  grower: growerReducer,
  form: formReducer,
});
