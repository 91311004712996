import useSWR from "swr";
import fetcher from "../lib/fetcher";

export default function useUVM(sorte, alpha) {
  const type = alpha === " kg Alpha" ? 1 : 0;

  const { data, error } = useSWR(
    `/api/contracts/uvm/${sorte}/${type}`,
    fetcher
  );

  if (error) return "An error has occurred.";
  if (!data) return "Loading...";

  return data.data;
}
