import useSWR from "swr";
import fetcher from "../lib/fetcher";

import Layout from "../components/Layout/Layout";
import Table from "../components/Table/Table";
import Spinner from "../components/Spinner";
import Error from "../components/Error";

import { LinkIcon } from "@heroicons/react/outline";

const columns = [
  {
    Header: "Link",
    accessor: "name",
  },
  {
    accessor: "url",
    Cell: function editLink(props) {
      return (
        <a target="_blank" rel="noreferrer" href={props.value}>
          <LinkIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </a>
      );
    },
  },
];

export default function Links() {
  const { data, error } = useSWR("/api/infos/links", fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <Layout>
      <Table title="Links" data={data} columns={columns} />
    </Layout>
  );
}
