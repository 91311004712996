import { Disclosure } from "@headlessui/react";
import { classNames } from "../../lib/classNames";

export default function Filter({ title, current, children }) {
  return (
    <Disclosure as="div" key={title} className="space-y-1">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              current
                ? "bg-gray-100 text-gray-900"
                : "bg-gray-100 text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group w-full flex items-center pr-2 py-2 text-left text-sm uppercase font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            )}
          >
            <svg
              className={classNames(
                open ? "text-gray-400 rotate-90" : "text-gray-300",
                "mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
              )}
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
            {title}
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            <ul>{children}</ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
